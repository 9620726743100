import { useFragment, useLazyLoadQuery, useMutation } from 'react-relay/hooks';
import { graphql } from 'babel-plugin-relay/macro';
import { RouteComponentProps } from 'react-router-dom';
import { LabelledTextArea, LabelledTextField } from '../FormElements/TextInput';
import React, { useContext, useState } from 'react';
import { FormSubmitButton } from '../FormElements/Buttons';
import { useForm } from 'react-hook-form';
import { FormHeading } from '../FormElements/FormHeading';
import { Form, FormRow } from '../FormElements/Form';
// import { CourseCreateInput, CoursePageCreateCourseMutation } from '../../__generated__/CoursePageCreateCourseMutation.graphql';
import { CoursePageQuery } from '../../__generated__/CoursePageQuery.graphql';
import { CoursePage_course$key } from '../../__generated__/CoursePage_course.graphql';
import {
  CourseCreateInput,
  CoursePageCreateCourseMutation,
  CoursePageCreateCourseMutationResponse,
} from '../../__generated__/CoursePageCreateCourseMutation.graphql';
import { ConnectionHandler, PayloadError } from 'relay-runtime';
import {
  AbsintheValidationMessages,
  asyncValidateFormValues,
  ValidationMessage,
} from '../FormElements/ValidationRules';
import { MyLink } from '../FormElements/Link';
import {
  Table,
  TableBody,
  TableColumn,
  TableColumnLink,
  TableHead,
  TableHeadColumn,
  TableRow,
} from '../TableComponents';
import { AppContext } from '../../App';
import { Page, PageDescription, PageHeader, PageHeading, Section } from '../StructuringElements';
import { FormState } from '../Types';

//FUNCTIONS

export const CourseEditPage = (props: RouteComponentProps) => {
  return (
    <Page>
      <PageHeader>
        <PageHeading>Kurse bearbeiten</PageHeading>
        <PageDescription>
          Hier können Sie Kurse hinzufügen. Unter der "Bearbeiten" Funktion haben Sie Möglichkeit,
          Videos zu existierenden Kursen hinzuzufügen.
        </PageDescription>
      </PageHeader>
      <Section>
        <CourseList editable />
      </Section>
      <Section>
        <CreateCourse />
      </Section>
    </Page>
  );
};

export const CoursePage = (props: RouteComponentProps) => {
  return (
    <Page>
      <PageHeader>
        <PageHeading>Deine Kurse</PageHeading>
        <PageDescription>
          Eine Übersicht über Kurse an denen du teilnimmst. In den jeweiligen Kursen findest du die
          Videos.
        </PageDescription>
      </PageHeader>

      <CourseList />
    </Page>
  );
};

type CourseListProps = {
  editable?: boolean;
};

const CourseList = (props: CourseListProps) => {
  const { editable } = props;
  const data = useLazyLoadQuery<CoursePageQuery>(
    graphql`
      query CoursePageQuery {
        me {
          courses(first: 999) @connection(key: "CoursePage_courses") {
            edges {
              ...CoursePage_course
              role
              node {
                id
              }
            }
          }
        }
      }
    `,
    {},
    { fetchPolicy: 'store-or-network' }
  );

  const courses = data.me.courses;

  return (
    <Table>
      <TableHead>
        <TableHeadColumn>Kursname</TableHeadColumn>
        {editable ? (
          <TableHeadColumn>
            <span className="sr-only">Bearbeiten</span>
          </TableHeadColumn>
        ) : null}
      </TableHead>
      <TableBody>
        {courses
          ? courses.edges.map((course) => {
              return <CourseListItem course={course} key={course.node.id} editable={editable} />;
            })
          : null}
      </TableBody>
    </Table>
  );
};

type CourseListItemProps = {
  course: CoursePage_course$key;
  editable?: boolean;
};

const CourseListItem = (props: CourseListItemProps) => {
  const { editable } = props;
  const course = useFragment(
    graphql`
      fragment CoursePage_course on UserCoursesEdge {
        role
        node {
          id
          name
        }
      }
    `,
    props.course
  );
  // I only use editable if it is the courseadmin page, so
  // if it is a courseadmin page and the course is not editable, just not show it
  if (editable && course.role !== 'admin') {
    return <></>;
  } else {
    return (
      <TableRow className="hover:bg-gray-100">
        <TableColumnLink to={'/courses/' + course.node.id}>{course.node.name}</TableColumnLink>
        {editable ? (
          <TableColumn>
            <MyLink to={'/courses_edit/' + course.node.id}>Bearbeiten</MyLink>
          </TableColumn>
        ) : null}
      </TableRow>
    );
  }
};

function CreateCourse() {
  const userId = useContext(AppContext).userId;
  //CourseCreateInput
  const [formMessage, setFormMessage] = useState('');
  const [formState, setFormState] = useState(FormState.Idle);
  const { register, handleSubmit, setError, errors, reset } = useForm<CourseCreateInput>();
  const [commit] = useMutation<CoursePageCreateCourseMutation>(graphql`
    mutation CoursePageCreateCourseMutation($input: CourseCreateInput!) {
      courseCreate(input: $input) {
        result {
          id
          name
          description
        }
        successful
        messages {
          code
          field
          message
        }
      }
    }
  `);

  const onSubmit = (data: CourseCreateInput) => {
    if (formState === FormState.Submitting) {
      return;
    }
    setFormState(FormState.Submitting);
    commit({
      variables: { input: data },
      updater: (store) => {
        const newCourse = store.getRootField('courseCreate').getLinkedRecord('result');
        console.log(newCourse);
        const me = store.get(userId);
        const myCourses = me && ConnectionHandler.getConnection(me, 'CoursePage_courses');
        const edge =
          myCourses &&
          me &&
          ConnectionHandler.createEdge(store, myCourses, newCourse, 'CoursesEdge');
        if (myCourses && edge) {
          edge.setValue('admin', 'role');
          ConnectionHandler.insertEdgeAfter(myCourses, edge);
        }
      },
      onCompleted: (response, errors) => {
        const ret = response.courseCreate;
        if (ret && !ret.successful && ret.messages) {
          const formValidationMessages = ret.messages as AbsintheValidationMessages<CourseCreateInput>;
          asyncValidateFormValues(formValidationMessages, setError, setFormMessage);
          setFormState(FormState.Failure);
        } else {
          setFormMessage('Der Kurs wurde erfolgreich erstellt.');
          setFormState(FormState.Success);
          reset();
        }
      },
    });
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormHeading formState={formState} formMessage={formMessage} heading="Kurs erstellen" />
      {/* <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-64 w-64"></div> */}
      <LabelledTextField
        errors={errors.name?.message}
        label="Kursname"
        name="name"
        inputRef={register({
          required: {
            value: true,
            message: ValidationMessage('required', true),
          },
          minLength: { value: 4, message: ValidationMessage('minLength', 4) },
          maxLength: { value: 63, message: ValidationMessage('maxLength', 63) },
        })}
      />
      <LabelledTextArea
        errors={errors.description?.message}
        label="Kursbeschreibung"
        name="description"
        inputRef={register({
          maxLength: {
            value: 10000,
            message: ValidationMessage('maxLength', 10000),
          },
        })}
      />
      <FormRow>
        <FormSubmitButton
          isSubmitting={formState === FormState.Submitting}
          buttonLabel="Speichern"
        />
      </FormRow>
    </Form>
  );
}
