import React, { ReactNode, RefObject } from 'react';
import { useState } from 'react';
import { ComponentProps, FC } from 'react';
import { SectionDescription } from '../StructuringElements';

interface TextFieldProps extends ComponentProps<'input'> {
  name: string;
  extraClasses?: string;
  inputRef?: RefObject<HTMLInputElement> | ((ref: HTMLInputElement) => void);
  type?: string;
}

// export const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => {
export const TextField: FC<TextFieldProps> = (props) => {
  let { extraClasses, children, inputRef, type, ...otherProps } = props;

  type = type ? type : 'text';

  extraClasses = props.extraClasses ? props.extraClasses : '';
  const className =
    extraClasses +
    ' rounded-xl shadow-md flex-1 block w-full focus:ring-blue-300 focus:border-blue-300 min-w-0 sm:text-sm border-gray-300';
  return <input type={type} className={className} {...otherProps} ref={inputRef} />;
};

interface TextAreaProps extends ComponentProps<'textarea'> {
  name: string;
  extraClasses?: string;
  subCaption?: string;
  inputRef?: RefObject<HTMLTextAreaElement> | ((ref: HTMLTextAreaElement) => void);
}

export const TextArea: FC<TextAreaProps> = (props) => {
  let { extraClasses, subCaption, children, inputRef, ...otherProps } = props;
  extraClasses = props.extraClasses ? props.extraClasses : '';

  const className =
    extraClasses +
    ' rounded-xl shadow-md flex-1 block w-full focus:ring-blue-300 focus:border-blue-300 min-w-0 sm:text-sm border-gray-300 ';
  return (
    <div className="mt-1 sm:mt-0 sm:col-span-2">
      <textarea rows={3} className={className} {...otherProps} ref={inputRef} />
      {subCaption ? <p className="mt-2 text-sm text-gray-500 rounded-full">{subCaption}</p> : ''}
    </div>
  );
};

interface ExtraPropsForLabelledInputField {
  inputField: ReactNode;
  label: string;
  for: string;
  errors?: string;
  description?: string;
}

export const LabelledInputField: FC<ExtraPropsForLabelledInputField> = (props) => {
  return (
    // <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
      <div className="px-6">
        <label htmlFor={props.for} className="block text-sm font-medium text-gray-700">
          {props.label}
        </label>
        <SectionDescription>{props.description && props.description}</SectionDescription>
      </div>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        {props.inputField}
        <p className="text-sm font-medium text-red-500 pt-1">{props.errors}</p>
      </div>
    </div>
  );
};

export interface LabelledInputFieldProps {
  label: string;
  description?: string;
  errors?: string;
}

export const LabelledTextField = (props: TextFieldProps & LabelledInputFieldProps) => {
  let { label, errors, description, ...fieldProps } = props;
  const extraClasses = errors ? 'border-red-500' : '';
  const field = <TextField extraClasses={extraClasses} {...fieldProps} />;
  const labelledField = (
    <LabelledInputField
      errors={errors}
      for={props.name}
      inputField={field}
      label={label}
      description={description}
    />
  );
  return labelledField;
};

export const LabelledTextArea = (props: TextAreaProps & LabelledInputFieldProps) => {
  let { label, errors, description, ...fieldProps } = props;
  const extraClasses = errors ? 'ring-red-500' : '';
  const field = <TextArea extraClasses={extraClasses} {...fieldProps} />;
  const labelledField = (
    <LabelledInputField
      errors={errors}
      for={props.name}
      inputField={field}
      label={label}
      description={description}
    />
  );
  return labelledField;
};

interface ReadonlyTextProps extends ComponentProps<'p'> {}

const ReadonlyText: FC<ReadonlyTextProps> = (props) => {
  const { className, children, ...otherProps } = props;
  const extraClasses = className ? className : '';

  return (
    <div className="mt-1 sm:mt-0 sm:col-span-2 font-medium text-sm">
      <p className={extraClasses} {...otherProps}>
        {children}
      </p>
    </div>
  );
};

interface LabelledFieldProps {
  label: string;
  description?: string;
}

interface ExtraPropsForLabelledField {
  field: ReactNode;
  label: string;
  description?: string;
}

const LabelledField: FC<ExtraPropsForLabelledField> = (props) => {
  return (
    // <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
      <div className="px-6">
        <label className="block text-sm font-medium text-gray-700">{props.label}</label>
        <SectionDescription>{props.description && props.description}</SectionDescription>
      </div>
      <div className="mt-1 sm:mt-0 sm:col-span-2">{props.field}</div>
    </div>
  );
};

export const LabelledReadonlyField = (props: ReadonlyTextProps & LabelledFieldProps) => {
  let { label, description, ...fieldProps } = props;
  const field = <ReadonlyText {...fieldProps} />;
  const labelledField = <LabelledField field={field} label={label} description={description} />;
  return labelledField;
};

interface FileUploadProps extends ComponentProps<'input'> {
  name: string;
  extraClasses?: string;
  inputRef?: RefObject<HTMLInputElement> | ((ref: HTMLInputElement) => void);
}

export const FileUploadField = (props: FileUploadProps) => {
  const { inputRef, name } = props;
  const [file, setFile] = useState(null as undefined | null | string);
  const classes =
    'py-2 px-5 bg-gray-100 rounded-3xl transform duration-200 text-center outline-none hover:bg-gray-200 hover:scale-110 focus:outline-none';

  const fileChange = (e: React.FormEvent<HTMLInputElement>) => {
    //from https://stackoverflow.com/a/9234894/12621652
    setFile(e.currentTarget.value.split(/(\\|\/)/g).pop());
  };

  return (
    <>
      <label htmlFor="file-upload" className={classes}>
        Video hochladen
        <input
          type="file"
          id="file-upload"
          name={name}
          ref={inputRef}
          className="sr-only"
          onChange={fileChange}
        />
      </label>
      <span className="text-sm font-medium ml-5">{file}</span>
    </>
  );
};

export const LabelledFileUploadField = (props: FileUploadProps & LabelledInputFieldProps) => {
  let { label, errors, description, ...fieldProps } = props;
  const extraClasses = errors ? 'border-red-500' : '';
  const field = <FileUploadField extraClasses={extraClasses} {...fieldProps} />;
  const labelledField = (
    <LabelledInputField
      errors={errors}
      for={props.name}
      inputField={field}
      label={label}
      description={description}
    />
  );
  return labelledField;
};
