import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormSubmitButton } from '../FormElements/Buttons';
import { Form, FormRow } from '../FormElements/Form';
import { FormHeading } from '../FormElements/FormHeading';
import { LabelledSwitchField } from '../FormElements/Switch';
import { LabelledReadonlyField, LabelledTextField } from '../FormElements/TextInput';
import * as Yup from 'yup';
import { apiUrl } from '../../App';
import { addChangesetErrorsToForm } from '../FormElements/ValidationRules';
import { FormState } from '../Types';
import { UserContext } from '../Pages/MainPage';

type ChangeEmailInput = {
  current_password: string;
  email: string;
};

const validationSchema = Yup.object().shape({
  current_password: Yup.string().required('Dieses Feld kann nicht leer bleiben.'),
  email: Yup.string().email('Ungültige E-Mail Adresse.').required('Required'),
});

export const ChangeEmail = () => {
  const [formMessage, setFormMessage] = useState('');
  const [formState, setFormState] = useState(FormState.Idle);
  const { register, handleSubmit, setError, errors } = useForm<ChangeEmailInput>({
    resolver: yupResolver(validationSchema),
  });
  const email = useContext(UserContext).email;

  const onSubmit = (data: ChangeEmailInput) => {
    if (formState === FormState.Submitting) return;
    setFormState(FormState.Submitting);
    const senddata = {
      current_password: data.current_password,
      email: data.email,
      action: 'update_email',
    };
    axios({
      method: 'put',
      url: apiUrl + '/api/users/settings',
      data: senddata,
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.errors) {
          addChangesetErrorsToForm(res.data.errors, setError as any, setFormMessage);
          setFormState(FormState.Failure);
        } else {
          setFormState(FormState.Success);
          setFormMessage(
            'Um die Änderung abzuschließen, folgen Sie dem Link in der Bestätigungsmail.'
          );
        }
      })
      .catch((error) => {
        //changeset error
        addChangesetErrorsToForm(error.response.data, setError as any, setFormMessage);
        setFormState(FormState.Failure);
      });
  };
  //Somehow it is sometimes buggy without this console.log...
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormHeading formState={formState} formMessage={formMessage} heading="E-Mail ändern" />
      <LabelledReadonlyField label="Aktuelle E-Mail">{email}</LabelledReadonlyField>
      <LabelledTextField
        errors={errors.current_password?.message}
        label="Aktuelles Passwort"
        name="current_password"
        type="password"
        inputRef={register()}
      />
      <LabelledTextField
        errors={errors.email?.message}
        label="Neue Email"
        name="email"
        type="email"
        inputRef={register()}
      />
      <FormRow>
        <FormSubmitButton
          isSubmitting={formState === FormState.Submitting}
          buttonLabel="E-Mail ändern"
        />
      </FormRow>
    </Form>
  );
};
