type ValidationRule = 'required' | 'minLength' | 'maxLength' | 'min';

export const ValidationMessage = (rule: ValidationRule, value?: any) => {
  let message: string = '';
  if (rule === 'required') message = `Dieser Eintrag kann nicht leer bleiben.`;
  else if (rule === 'minLength')
    message = `Dieser Eintrag muss mindestens ${value} Zeichen lang sein.`;
  else if (rule === 'maxLength')
    message = `Dieser Eintrag darf höchstens ${value} Zeichen lang sein.`;
  else if (rule === 'min') message = `Diese Zahl muss größer oder gleich ${value} sein.`;

  return message;
};

export type AbsintheValidationMessages<T> = ReadonlyArray<{
  readonly code: string;
  readonly field: keyof T | null;
  readonly message: string | null;
}>;

export function asyncValidateFormValues<T>(
  messages: AbsintheValidationMessages<T>,
  setError: (fieldName: keyof T, error: { type: string; message: string }) => void,
  setGlobalFormError: (error: string) => void
) {
  let globalFormError = '';
  messages.forEach((message) => {
    if (message?.field && message?.message) {
      const errorField = message.field;
      const errorMessage = message.message;
      setError(errorField, { type: message.code, message: errorMessage });
    } else if (message?.message) {
      if (message.message === 'unauthorized') {
        globalFormError += 'Sie haben nicht die nötigen Rechte für diese Aktion.';
        globalFormError += ' ';
      } else {
        globalFormError += message.message;
      }
    }
  });
  setGlobalFormError(globalFormError);
}

type Response = {
  errors?: ChangeSetErrors;
  message?: string;
};

type ChangeSetErrors = {
  [field: string]: string[];
};

// registration page kann changeset error oder normale error returnen, beide cases muss ich beachten
export function addChangesetErrorsToForm(
  resp: Response,
  setError: (fieldName: string, error: { type: string; message: string }) => void,
  setGlobalFormError: (error: string) => void
) {
  //buggy without this console.log
  //next todo, email aendern probieren. irgendwas funktioniert da noch nicht.
  // console.log("Errors are", errors);
  if (resp.errors) {
    for (const [field, messages] of Object.entries(resp.errors)) {
      let fieldMessage = '';
      for (const message of messages) {
        fieldMessage += message + ' ';
      }
      // console.log(`Setting error: ${field}:${fieldMessage}`);
      setError(field, { type: 'server', message: fieldMessage });
    }
  }
  // console.log(errors);
  if (resp.message) setGlobalFormError(resp.message);
}

function addServerErrors<T>(
  errors: { [P in keyof T]?: string[] },
  setError: (fieldName: keyof T, error: { type: string; message: string }) => void
) {
  return Object.keys(errors).forEach((key) => {
    setError(key as keyof T, {
      type: 'server',
      message: errors[key as keyof T]!.join('. '),
    });
  });
}
