/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type NewConversationCourseVideoSubscribeInput = {
    courseVideoId: string;
};
export type VideoCommentsConversationsSubscriptionVariables = {
    input: NewConversationCourseVideoSubscribeInput;
};
export type VideoCommentsConversationsSubscriptionResponse = {
    readonly newConversationCourseVideo: {
        readonly id: string;
        readonly timeInSeconds: number;
        readonly conversationMessages: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly user: {
                        readonly name: string;
                    };
                    readonly text: string | null;
                    readonly source: string | null;
                };
            }>;
        } | null;
    } | null;
};
export type VideoCommentsConversationsSubscription = {
    readonly response: VideoCommentsConversationsSubscriptionResponse;
    readonly variables: VideoCommentsConversationsSubscriptionVariables;
};



/*
subscription VideoCommentsConversationsSubscription(
  $input: NewConversationCourseVideoSubscribeInput!
) {
  newConversationCourseVideo(input: $input) {
    id
    timeInSeconds
    conversationMessages(first: 1) {
      edges {
        node {
          user {
            name
            id
          }
          text
          source
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeInSeconds",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "source",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VideoCommentsConversationsSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Conversation",
        "kind": "LinkedField",
        "name": "newConversationCourseVideo",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "ConversationMessagesConnection",
            "kind": "LinkedField",
            "name": "conversationMessages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ConversationMessagesEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ConversationMessage",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PublicUser",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "conversationMessages(first:1)"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootSubscriptionType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VideoCommentsConversationsSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Conversation",
        "kind": "LinkedField",
        "name": "newConversationCourseVideo",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "ConversationMessagesConnection",
            "kind": "LinkedField",
            "name": "conversationMessages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ConversationMessagesEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ConversationMessage",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PublicUser",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "conversationMessages(first:1)"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "511aef2db4f4ea90c470c05cc0261613",
    "id": null,
    "metadata": {},
    "name": "VideoCommentsConversationsSubscription",
    "operationKind": "subscription",
    "text": "subscription VideoCommentsConversationsSubscription(\n  $input: NewConversationCourseVideoSubscribeInput!\n) {\n  newConversationCourseVideo(input: $input) {\n    id\n    timeInSeconds\n    conversationMessages(first: 1) {\n      edges {\n        node {\n          user {\n            name\n            id\n          }\n          text\n          source\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '5461136078846667ea973b197fa96ca9';
export default node;
