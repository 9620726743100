import axios from 'axios';
import { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { apiUrl } from '../../App';
import { Spinner } from '../Spinner';
import { FormState } from '../Types';

type ConfirmEmailParams = { token: string };
interface ConfirmEmailProps extends RouteComponentProps<ConfirmEmailParams> {}

export const ConfirmEmail = (props: ConfirmEmailProps) => {
  const history = useHistory();
  const [requestState, setRequestState] = useState(FormState.Idle);
  const [serverResponse, setServerResponse] = useState('');
  const token = props.match.params.token;
  useEffect(() => {
    setRequestState(FormState.Submitting);
    axios({
      method: 'get',
      url: apiUrl + `/api/users/settings/confirm_email/${token}`,
      withCredentials: true,
    }).then((res) => {
      if (res.data.successful) {
        setRequestState(FormState.Success);
        history.push('/settings');
      } else {
        setServerResponse(res.data.message);
        setRequestState(FormState.Failure);
      }
    });
  }, []);

  if (requestState === FormState.Failure) {
    return <>{serverResponse}</>;
  } else if (requestState === FormState.Success) {
    return <>E-Mail Adresse wurde erfolgreich geändert.</>;
  } else {
    return <Spinner />;
  }
};
