import { CheckCircleIcon, CheckIcon, PlusCircleIcon } from '@heroicons/react/outline';
import { graphql } from 'babel-plugin-relay/macro';
import React from 'react';
import { useFragment, useLazyLoadQuery } from 'react-relay/hooks';
import { useHistory } from 'react-router';
import { UserConversationListQuery } from '../../__generated__/UserConversationListQuery.graphql';
import { UserConversationList_conversation$key } from '../../__generated__/UserConversationList_conversation.graphql';
import { VideoListQuery } from '../../__generated__/VideoListQuery.graphql';
import { VideoList_video$key } from '../../__generated__/VideoList_video.graphql';
import { CourseVideoList } from '../CourseVideos/CourseVideoList';
import { ButtonLink, MyLink } from '../FormElements/Link';
import { Page, PageDescription, PageHeader, PageHeading, Section } from '../StructuringElements';
import {
  Table,
  TableBody,
  TableColumn,
  TableColumnLink,
  TableHead,
  TableHeadColumn,
  TableRow,
} from '../TableComponents';
import { UserAvatar } from './DisplayConversation';

type Props = {};

export const UserConversationList = (props: Props) => {
  const data = useLazyLoadQuery<UserConversationListQuery>(
    graphql`
      query UserConversationListQuery {
        me {
          conversations(first: 999) @connection(key: "UserConversationList_conversations") {
            edges {
              ...UserConversationList_conversation
              node {
                id
                conversationMessages(first: 1) {
                  edges {
                    node {
                      insertedAt
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {},
    { fetchPolicy: 'network-only' }
  );
  //TODO NEXT: Make the query in VideoList reload on page load

  const unsortedConversations = data.me.conversations;
  const conversations = [...unsortedConversations!.edges].sort((a, b) => {
    return compareDates(
      a.node.conversationMessages!.edges[0].node.insertedAt as string,
      b.node.conversationMessages!.edges[0].node.insertedAt as string
    );
  });

  return (
    <Page>
      <Section>
        <div>
          <ul role="list" className="divide-y divide-gray-200">
            {conversations
              ? conversations.map((conversation) => (
                  <ConversationListItem conversation={conversation} key={conversation.node.id} />
                ))
              : null}
          </ul>
        </div>
      </Section>
    </Page>
  );
};

type VideoListItemProps = {
  conversation: UserConversationList_conversation$key;
};

const ConversationListItem = (props: VideoListItemProps) => {
  const conversationEdge = useFragment<UserConversationList_conversation$key>(
    graphql`
      fragment UserConversationList_conversation on UserConversationsEdge {
        node {
          id
          timeInSeconds
          conversationMessages(first: 1) {
            edges {
              node {
                text
                source
                insertedAt
                user {
                  id
                  name
                }
              }
            }
          }
        }
      }
    `,
    props.conversation
  );

  const history = useHistory();
  const message = conversationEdge.node.conversationMessages!.edges[0].node;
  const user = message.user;

  return (
    <li
      key={conversationEdge.node.id}
      onClick={(e) => history.push(`/conversations/${conversationEdge.node.id}`)}
      className="py-4 hover:bg-gray-100 cursor-pointer"
    >
      <div className="flex space-x-3">
        <UserAvatar name={user.name} />
        <div className="flex-1 space-y-1">
          <div className="flex items-center justify-between">
            <h3 className="text-sm font-medium">{user.name}</h3>
            <p className="text-sm text-gray-500 mr-3">{printTime(message.insertedAt as string)}</p>
          </div>
          <p className="text-sm text-gray-500">
            {message.text ? message.text : null}
            {message.source ? <i>Audionachricht</i> : null}
          </p>
        </div>
      </div>
    </li>
  );
};

function compareDates(date_str1: string, date_str2: string) {
  const date1 = new Date(date_str1);
  const date2 = new Date(date_str2);
  return date1 > date2 ? -1 : 1;
}

function printTime(date_str: string) {
  //TODO: Could maybe print time for messages from today and date otherwise
  const date = new Date(date_str);
  const today = new Date();
  if (date.getDate() == today.getDate() && date.getMonth() == date.getMonth()) {
    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    return `${date.getHours()}:${minutes}`;
  } else {
    return `${date.getDate()}/${date.getMonth() + 1}`;
  }
}
