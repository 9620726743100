import React, { useEffect, useState } from 'react';
import { useLazyLoadQuery, useMutation } from 'react-relay/hooks';
import {
  VideoCreateInput,
  VideoCreateMutationResponse,
} from '../../__generated__/VideoCreateMutation.graphql';
import { graphql } from 'babel-plugin-relay/macro';
import { useForm } from 'react-hook-form';
import { ConnectionHandler, PayloadError } from 'relay-runtime';
import {
  AbsintheValidationMessages,
  asyncValidateFormValues,
  ValidationMessage,
} from '../FormElements/ValidationRules';
import { Form, FormRow } from '../FormElements/Form';
import { FormHeading } from '../FormElements/FormHeading';
import {
  LabelledReadonlyField,
  LabelledTextArea,
  LabelledTextField,
} from '../FormElements/TextInput';
import { FormSubmitButton } from '../FormElements/Buttons';
import { RouteComponentProps } from 'react-router-dom';
import { FormState } from '../Types';
import { LabelledListBoxField } from '../FormElements/ListBox';
import { parseIFrameString } from '../VideoPlayers/VideoPlayerPanopto';
import {
  CourseVideoAddInput,
  CourseVideoAddMutation,
} from '../../__generated__/CourseVideoAddMutation.graphql';
import { CourseVideoAddVideoListQuery } from '../../__generated__/CourseVideoAddVideoListQuery.graphql';

interface VideoCreateProps extends RouteComponentProps<{ courseId: string }> {}

export function CourseVideoAdd(props: VideoCreateProps) {
  const courseId = props.match.params.courseId;
  //CourseCreateInput
  const [formMessage, setFormMessage] = useState('');
  const [formState, setFormState] = useState(FormState.Idle);
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    errors,
    reset,
    control,
    watch,
  } = useForm<CourseVideoAddInput>();

  const selectedVideo = watch('videoId');

  const videos = useLazyLoadQuery<CourseVideoAddVideoListQuery>(
    graphql`
      query CourseVideoAddVideoListQuery {
        me {
          videos(first: 999) @connection(key: "VideoList_videos") {
            edges {
              node {
                id
                name
                description
                state
              }
            }
          }
        }
      }
    `,
    {},
    { fetchPolicy: 'store-or-network' }
  );

  const videoListBoxOptionsWithoutDefault = videos.me.videos!.edges.map((edge) => {
    return { value: edge.node.id, label: edge.node.name };
  });
  const videoListBoxOptions = [{ value: 'none', label: 'Video auswählen' }].concat(
    videoListBoxOptionsWithoutDefault
  );

  const [commit] = useMutation<CourseVideoAddMutation>(graphql`
    mutation CourseVideoAddMutation($input: CourseVideoAddInput!) {
      courseVideoAdd(input: $input) {
        result {
          id
          name
          description
        }
        successful
        messages {
          code
          field
          message
        }
      }
    }
  `);

  const onSubmit = (data: CourseVideoAddInput) => {
    if (formState === FormState.Submitting) {
      return;
    }
    setFormState(FormState.Submitting);
    commit({
      variables: { input: data },
      updater: (store) => {
        const newEntry = store.getRootField('courseVideoAdd').getLinkedRecord('result');
        const parentRecord = store.get(courseId);
        const connection =
          parentRecord && ConnectionHandler.getConnection(parentRecord, 'VideoList_courseVideos');
        const edge =
          parentRecord &&
          connection &&
          ConnectionHandler.createEdge(store, connection, newEntry, 'CourseVideosEdge');
        if (connection && edge) {
          ConnectionHandler.insertEdgeAfter(connection, edge);
        }
      },
      onCompleted: (response, errors) => {
        const ret = response.courseVideoAdd;
        if (ret && !ret.successful && ret.messages) {
          const formValidationMessages = ret.messages as AbsintheValidationMessages<CourseVideoAddInput>;
          asyncValidateFormValues(formValidationMessages, setError, setFormMessage);
          setFormState(FormState.Failure);
        } else {
          setFormState(FormState.Success);
          setFormMessage('Das Video wurde dem Kurs hinzugefügt.');
          reset();
        }
      },
    });
  };

  const isVideoSelected = selectedVideo !== undefined && selectedVideo !== 'none';
  useEffect(() => {
    //somehow if this is not done in useEffect, then the name and description where not updated on the first time when changing the video
    if (isVideoSelected) {
      const selectedVideoData = videos.me.videos!.edges.find((e) => e.node.id == selectedVideo);
      setValue('name', selectedVideoData!.node.name);
      setValue('description', selectedVideoData!.node.description);
    }
  });
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormHeading
        formState={formState}
        formMessage={formMessage}
        heading="Video zum Kurs hinzufügen"
      />
      {/* <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-64 w-64"></div> */}
      <input type="hidden" name="courseId" value={courseId} ref={register()} />
      <LabelledListBoxField
        errors={errors.videoId?.message}
        name="videoId"
        control={control}
        options={videoListBoxOptions}
        defaultValue="none"
        label="Video"
        description='Wählen Sie ein Video aus, das Sie zu dem Kurs hinzufügen möchten. Das Video muss vorher unter dem Reiter "Videos hochladen" auf vions hochgeladen werden.'
      />
      {isVideoSelected ? (
        <>
          <LabelledReadonlyField label="Videodetails">
            Wenn Sie möchten, können Sie den Namen und die Beschreibung des Videos, die den Schülern
            angezeigt wird, ändern.
          </LabelledReadonlyField>
          <LabelledTextField
            errors={errors.name?.message}
            label="Name"
            description="Unter welchem Namen soll das Video zu sehen sein?"
            name="name"
            inputRef={register({
              required: {
                value: true,
                message: ValidationMessage('required'),
              },
              minLength: { value: 4, message: ValidationMessage('minLength', 4) },
              maxLength: { value: 63, message: ValidationMessage('maxLength', 63) },
            })}
          />
          <LabelledTextArea
            errors={errors.description?.message}
            label="Videobeschreibung"
            description="Alle weiteren Details zum Video."
            name="description"
            inputRef={register({
              maxLength: {
                value: 10000,
                message: ValidationMessage('maxLength', 10000),
              },
            })}
          />
        </>
      ) : null}

      <FormRow>
        <FormSubmitButton
          disabled={!isVideoSelected}
          isSubmitting={formState === FormState.Submitting}
          buttonLabel="Speichern"
        />
      </FormRow>
    </Form>
  );
}
