import {
  Environment,
  Network,
  RecordSource,
  Store,
  Observable,
  RequestParameters,
  Variables,
} from 'relay-runtime';
import axios from 'axios';
import { LogEvent } from 'relay-runtime';
import { apiUrl } from '../App';
import { Socket as PhoenixSocket } from 'phoenix';
import * as AbsintheSocket from '@absinthe/socket';
// @ts-ignore
import { createSubscriber } from './socket-relay/src';

const __DEV__ = process.env.NODE_ENV === 'development';

async function fetchGraphQL(text: string | null | undefined, variables: Variables) {
  const response = await axios({
    method: 'post',
    url: apiUrl + '/api/graphql',
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
    data: { query: text, variables },
  });
  return response.data;
}

// Relay passes a "params" object with the query name and text. So we define a helper function
// to call our fetchGraphQL utility with params.text.
async function fetchRelay(params: RequestParameters, variables: Variables) {
  console.log(`fetching query ${params.name} with ${JSON.stringify(variables)}`);
  return fetchGraphQL(params.text, variables);
}

const relayTransactionLogger = (event: LogEvent) => {
  // eslint-disable-next-line
  return;
};

const webSocketUrl = __DEV__ ? 'ws://localhost:4000/socket' : 'wss://backend.vions.academy/socket';

const phoenixSocket = new PhoenixSocket(webSocketUrl, {
  params: () => {
    // if (Cookies.get("token")) {
    //   return { token: Cookies.get("token") };
    // } else {
    //   return {};
    // }
    return {};
  },
});

const absintheSocket = AbsintheSocket.create(phoenixSocket);

const subscribe = createSubscriber(absintheSocket);

// Export a singleton instance of Relay Environment configured with our network function:
const env = new Environment({
  network: Network.create(fetchRelay, subscribe),
  store: new Store(new RecordSource(), { gcReleaseBufferSize: 50 }),
  log: __DEV__ ? relayTransactionLogger : null,
});

if (__DEV__) {
  // @ts-ignore
  window.relayEnvironment = env;
  // @ts-ignore
  window.debugRelayStore = () => env.getStore().getSource().toJSON();
}

export default env;
