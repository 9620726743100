import React from 'react';
import {
  Page,
  PageDescription,
  PageHeader,
  PageHeading,
  Section,
  SectionHeading,
} from '../StructuringElements';
import { ChangeEmail } from './ChangeEmail';
import { ChangePassword } from './ChangePassword';

export const UserSettingsPage = () => {
  return (
    <Page>
      <PageHeader>
        <PageHeading>Einstellungen</PageHeading>
      </PageHeader>
      <Section>
        <ChangeEmail />
      </Section>
      <Section>
        <ChangePassword />
      </Section>
    </Page>
  );
};
