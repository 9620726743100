import { useFragment, useLazyLoadQuery, useMutation } from 'react-relay/hooks';
import { RouteComponentProps } from 'react-router-dom';
import { graphql } from 'babel-plugin-relay/macro';
import React, { useState } from 'react';
import { MyLink } from '../FormElements/Link';
import {
  Table,
  TableHead,
  TableHeadColumn,
  TableBody,
  TableRow,
  TableColumn,
} from '../TableComponents';
import { CheckIcon, XIcon } from '@heroicons/react/outline';
import { useForm } from 'react-hook-form';
import { ConnectionHandler, PayloadError } from 'relay-runtime';
import {
  InvitationCodeCreateInput,
  InvitationCodesCreateMutation,
  InvitationCodesCreateMutationResponse,
} from '../../__generated__/InvitationCodesCreateMutation.graphql';
import { InvitationCodesQuery } from '../../__generated__/InvitationCodesQuery.graphql';
import { InvitationCodes_InvitationCode$key } from '../../__generated__/InvitationCodes_InvitationCode.graphql';
import { InvitationCodes_invitationCodeList$key } from '../../__generated__/InvitationCodes_invitationCodeList.graphql';
import { FormSubmitButton } from '../FormElements/Buttons';
import { Form, FormRow } from '../FormElements/Form';
import { FormHeading } from '../FormElements/FormHeading';
import { LabelledTextField } from '../FormElements/TextInput';
import {
  AbsintheValidationMessages,
  asyncValidateFormValues,
  ValidationMessage,
} from '../FormElements/ValidationRules';
import { LabelledListBoxField } from '../FormElements/ListBox';
import {
  Page,
  Section,
  SectionHeading,
  PageHeading,
  PageDescription,
} from '../StructuringElements';
import { FormState } from '../Types';

const roles = [
  { value: 'participant', label: 'Teilnehmer' },
  { value: 'teaching_staff', label: 'Lehrperson' },
  { value: 'admin', label: 'Administrator' },
];

const roleToLabel = (role: string) => {
  const fullRole = roles.find(({ value, label }) => value === role);
  return fullRole?.label;
};

export const InvitationCodesPage = (props: RouteComponentProps) => {
  const data = useLazyLoadQuery<InvitationCodesQuery>(
    graphql`
      query InvitationCodesQuery {
        me {
          ...InvitationCodes_invitationCodeList
          organisation {
            id
          }
        }
      }
    `,
    {},
    { fetchPolicy: 'store-or-network' }
  );

  const organisationId = data.me.organisation.id as string;

  return (
    <>
      <Page>
        {/* <Route path="/organisation" exact> */}
        <Section>
          <PageHeading>Einladungslinks</PageHeading>
          <PageDescription>
            Hier können Sie Einladungslinks erstellen, bearbeiten und editieren. Die Einladungslinks
            können Sie Personen geben, die sich mit diesen dann Accounts erstellen können.
          </PageDescription>
        </Section>

        <Section>
          <SectionHeading>Schon erstellte Links</SectionHeading>
          <InvitationCodesList me={data.me} />
        </Section>

        <InvitationCodeCreate organisationId={organisationId} />

        {/* </Route> */}
      </Page>
    </>
  );
};

type InvitationCodesListProps = {
  me: InvitationCodes_invitationCodeList$key;
};

const InvitationCodesList = (props: InvitationCodesListProps) => {
  const data = useFragment<InvitationCodes_invitationCodeList$key>(
    graphql`
      fragment InvitationCodes_invitationCodeList on User {
        id
        organisation {
          invitationCodes(first: 999) @connection(key: "InvitationCodes_invitationCodes") {
            edges {
              node {
                id
                creator {
                  id
                }
              }
              ...InvitationCodes_InvitationCode
            }
          }
        }
      }
    `,
    props.me
  );
  const invitationCodes = data.organisation.invitationCodes;
  //If there is only one element in the list, it is the default invitationcode that is hidden
  //(see further down)
  if (invitationCodes!.edges.length <= 1) {
    return (
      <p className="text-md text-gray-500">
        Es wurden noch keine Einladungslinks erstellt. Sie können weiter unten auf dieser Seite
        Ihren ersten Einladungslink erstellen.
      </p>
    );
  }
  return (
    <Table>
      <TableHead>
        <TableHeadColumn className="w-2">Aktiv</TableHeadColumn>
        <TableHeadColumn>Name</TableHeadColumn>
        <TableHeadColumn>Rolle</TableHeadColumn>
        <TableHeadColumn>
          <span className="sr-only">Bearbeiten</span>
        </TableHeadColumn>
      </TableHead>
      <TableBody>
        {invitationCodes
          ? invitationCodes.edges.map((invitationCode) =>
              //If invinvation code has no creator it is the initial invitation code to create the first administrator
              invitationCode.node.creator ? (
                <InvitationCodeListItem
                  invitationCode={invitationCode}
                  key={invitationCode.node.id}
                />
              ) : null
            )
          : null}
      </TableBody>
    </Table>
  );
};

type InvitationCodeListItemProps = {
  invitationCode: InvitationCodes_InvitationCode$key;
};

const InvitationCodeListItem = (props: InvitationCodeListItemProps) => {
  const data = useFragment<InvitationCodes_InvitationCode$key>(
    graphql`
      fragment InvitationCodes_InvitationCode on OrganisationInvitationCodesEdge {
        node {
          id
          invitationCode
          role
          isActive
          name
        }
      }
    `,
    props.invitationCode
  );

  const invitationCode = data.node;

  return (
    <TableRow className="hover:bg-gray-100">
      <TableColumn>
        {invitationCode.isActive ? <CheckIcon className="h-6" /> : <XIcon className="h-6" />}
      </TableColumn>
      <TableColumn>{invitationCode.name}</TableColumn>
      <TableColumn>{roleToLabel(invitationCode.role)}</TableColumn>
      <TableColumn>
        <MyLink to={'/invitation_codes/edit/' + invitationCode.id}>Details</MyLink>
      </TableColumn>
    </TableRow>
  );
};

type InvitationCodeCreateProps = {
  organisationId: string;
};

function InvitationCodeCreate(props: InvitationCodeCreateProps) {
  const organisationId = props.organisationId;
  //CourseCreateInput
  const [formMessage, setFormMessage] = useState('');
  const [formState, setFormState] = useState(FormState.Idle);
  const {
    register,
    handleSubmit,
    setError,
    errors,
    reset,
    control,
  } = useForm<InvitationCodeCreateInput>({
    defaultValues: { role: 'participant' },
  });
  const [commit] = useMutation<InvitationCodesCreateMutation>(graphql`
    mutation InvitationCodesCreateMutation($input: InvitationCodeCreateInput!) {
      invitationCodeCreate(input: $input) {
        result {
          id
          name
          isActive
          role
          invitationCode
          # needed because I check for the creator if I want to know if I need to render the thing (since the first invitation code for initial admin has no creator)
          creator {
            id
          }
        }
        successful
        messages {
          code
          field
          message
        }
      }
    }
  `);

  const onSubmit = (data: InvitationCodeCreateInput) => {
    if (formState === FormState.Submitting) {
      return;
    }
    setFormState(FormState.Submitting);
    commit({
      variables: { input: data },
      updater: (store) => {
        const rootField = store.getRootField('invitationCodeCreate');
        if (!rootField.getValue('successful')) return;
        const newEntry = rootField.getLinkedRecord('result');
        const parentRecord = store.get(organisationId);
        const connection = ConnectionHandler.getConnection(
          parentRecord!,
          'InvitationCodes_invitationCodes'
        );
        const edge = ConnectionHandler.createEdge(
          store,
          connection!,
          newEntry,
          'OrganisationInvitationCodesEdge'
        );
        ConnectionHandler.insertEdgeAfter(connection!, edge);
      },
      onCompleted: (
        response: InvitationCodesCreateMutationResponse,
        errors: PayloadError[] | null
      ) => {
        const ret = response.invitationCodeCreate;
        if (ret && !ret.successful && ret.messages) {
          const formValidationMessages = ret.messages as AbsintheValidationMessages<InvitationCodeCreateInput>;
          asyncValidateFormValues(formValidationMessages, setError, setFormMessage);
          setFormState(FormState.Failure);
        } else {
          setFormState(FormState.Success);
          setFormMessage('Der Einladungslink wurde erstellt.');
          reset();
        }
      },
      onError: (e) => {
        console.log('Mutation error handler called with');
        console.log(e);
      },
    });
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormHeading
        formState={formState}
        formMessage={formMessage}
        heading="Neuen Einladungslink erstellen"
      />
      {/* <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-64 w-64"></div> */}
      <LabelledTextField
        errors={errors.name?.message}
        label="Name"
        description="Ein kurzer Name, damit Sie Link später zuordnen können."
        name="name"
        placeholder="z.B. Klasse 10a"
        inputRef={register({
          required: {
            value: true,
            message: ValidationMessage('required', true),
          },
          minLength: { value: 4, message: ValidationMessage('minLength', 4) },
          maxLength: { value: 63, message: ValidationMessage('maxLength', 63) },
        })}
      />
      <LabelledListBoxField
        errors={errors.role?.message}
        name="role"
        control={control}
        options={[
          { value: 'participant', label: 'Teilnehmer' },
          { value: 'teaching_staff', label: 'Lehrperson' },
          { value: 'admin', label: 'Administrator' },
        ]}
        defaultValue="participant"
        label="Accountberechtigung"
        description="Welche Rechte sollen die Accounts haben, die über diesen Link erstellt werden?"
      />

      <LabelledTextField
        errors={errors.maximalAccounts?.message}
        label="Maximal erstellbare Accounts"
        name="maximalAccounts"
        description="Wie oft kann dieser Einladungslink verwenet werden?"
        placeholder="z.B. 30"
        inputRef={register({
          required: {
            value: true,
            message: 'Wie oft soll der Code genutzt werden können?',
          },
          valueAsNumber: true,
          maxLength: {
            value: 10000,
            message: ValidationMessage('maxLength', 10000),
          },
          min: {
            value: 1,
            message: ValidationMessage('min', 1),
          },
        })}
        type="number"
      />
      <FormRow>
        <FormSubmitButton
          isSubmitting={formState === FormState.Submitting}
          buttonLabel="Speichern"
        />
      </FormRow>
    </Form>
  );
}
