import React from 'react';
import { Page, PageDescription, PageHeading } from '../StructuringElements';

export const NotFoundPage = () => (
  <Page>
    <PageHeading>Seite nicht gefunden</PageHeading>
    <PageDescription>
      Da ist wohl was schiefgelaufen. Unter dieser Adresse haben wir keine Seite gespeichert.
    </PageDescription>
  </Page>
);
