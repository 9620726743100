import {
  ConnectionHandler,
  requestSubscription,
  useFragment,
  useLazyLoadQuery,
  useSubscription,
} from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { DisplayConversationQuery } from '../../__generated__/DisplayConversationQuery.graphql';
import { DisplayConversation_message$key } from '../../__generated__/DisplayConversation_message.graphql';
import { MessageComposer } from './MessageComposer';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AppContext } from '../../App';
import { UserContext } from '../Pages/MainPage';
import type { Environment } from 'react-relay';
import {
  DisplayConversationNewMessageSubscription,
  DisplayConversationNewMessageSubscriptionResponse,
  DisplayConversationNewMessageSubscriptionVariables,
  NewMessageConversationSubscribeInput,
} from '../../__generated__/DisplayConversationNewMessageSubscription.graphql';
import { GraphQLSubscriptionConfig, RecordSourceSelectorProxy } from 'relay-runtime';
import { printTime } from '../Helpers';
import { BlueLink } from '../FormElements/Link';

type UserAvatarProps = {
  name: string;
  className?: string;
};

export const UserAvatar = (props: UserAvatarProps) => {
  const { name, className } = props;

  const name_list = name.split(' ');
  const initials = `${name_list[0][0]}${name_list[name_list.length - 1][0]}`.toUpperCase();

  return (
    <span
      className={`${className} inline-flex items-center justify-center h-6 w-6 rounded-full bg-gray-500`}
      title={name}
    >
      <span className="text-xs font-medium leading-none text-white">{initials}</span>
    </span>
  );
};

interface DisplayConversationProps extends RouteComponentProps<{ id: string }> {}

const subscriptionGraphql = graphql`
  subscription DisplayConversationNewMessageSubscription(
    $input: NewMessageConversationSubscribeInput!
  ) {
    newMessageConversation(input: $input) {
      ...DisplayConversation_message
    }
  }
`;

export const DisplayConversation = (props: DisplayConversationProps) => {
  const { id } = props.match.params;
  const [messageRef, setMessageRef] = useState(null as HTMLDivElement | null);

  const config = useMemo(() => {
    return {
      variables: { input: { conversationId: id } },
      subscription: subscriptionGraphql,
      updater: (store) => {
        const conversationRecord = store.get(id);

        // Get connection record
        const connectionRecord = ConnectionHandler.getConnection(
          conversationRecord!,
          'DisplayConversation_conversationMessages'
        );

        // Get the payload returned from the server
        const payload = store.getRootField('newMessageConversation');

        // Get the edge inside the payload
        // Build edge for adding to the connection
        // const newEdge = ConnectionHandler.buildConnectionEdge(
        //   store,
        //   connectionRecord!,
        //   payload,
        // );
        const newEdge = ConnectionHandler.createEdge(
          store,
          connectionRecord!,
          payload,
          'ConversationMessagesEdge'
        );

        // Add edge to the end of the connection
        newEdge && ConnectionHandler.insertEdgeBefore(connectionRecord!, newEdge);
      },
      onCompleted: () => {} /* Subscription established */,
      onError: (error) => {
        console.error('Got error while subscribing, ', error);
      } /* Subscription errored */,
      onNext: (response) => {} /* Subscription payload received */,
    } as GraphQLSubscriptionConfig<DisplayConversationNewMessageSubscription>;
  }, [id]);
  useSubscription<DisplayConversationNewMessageSubscription>(config);

  const scrollToBottom = (node: HTMLDivElement | null) => {
    console.log('scrollToBottomCalled');
    if (messageRef !== null) {
      console.log('messageRef nonzero');
      messageRef.scrollTop = messageRef.scrollHeight;
    }
    if (node !== null) {
      console.log('node nonzero');
      node.scrollTop = node.scrollHeight;
    }
  };

  const ref = useCallback((node) => {
    setMessageRef(node);
    scrollToBottom(node);
  }, []);

  const data = useLazyLoadQuery<DisplayConversationQuery>(
    graphql`
      query DisplayConversationQuery($id: ID!) {
        conversationById(id: $id) {
          id
          timeInSeconds
          courseVideo {
            name
            id
            course {
              id
            }
          }
          ...VoiceMessageComposerConversation_UploadVoiceCommentForm
          conversationMessages(first: 999)
            @connection(key: "DisplayConversation_conversationMessages") {
            edges {
              node {
                id
                ...DisplayConversation_message
              }
            }
          }
        }
      }
    `,
    { id: id },
    { fetchPolicy: 'store-or-network' }
  );
  if (data.conversationById === null) {
    return (
      <>
        Wir konnten die Unterhaltung nicht finden. Entweder wurde sie gelöscht oder der Link enthält
        einen
      </>
    );
  }
  const courseVideo = data.conversationById.courseVideo;
  return (
    <>
      <div className="flex flex-col flex-1 justify-between overflow-y-hidden">
        <div
          id="messages"
          ref={ref}
          className="space-y-reverse flex-col-reverse flex flex-1 space-y-4 p-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch"
        >
          {data.conversationById.conversationMessages?.edges.map((edge) => (
            <DisplayMessage message={edge.node} key={edge.node.id} />
          ))}
          <div className="mb-auto">
            Das Gespräch bezieht sich auf die Stelle{' '}
            <i>{printTime(data.conversationById.timeInSeconds)}</i> des Videos{' '}
            <BlueLink
              className="text-blue-200"
              to={`/course_videos/${courseVideo.course.id}/${courseVideo.id}`}
            >
              {courseVideo.name}
            </BlueLink>
            .
          </div>
        </div>
        <MessageComposer
          scrollToBottom={() => scrollToBottom(null)}
          conversationId={id}
          conversation={data.conversationById}
        />
      </div>
    </>
  );
};

//this is copy and paste fromt the graphql query generation
type Message = {
  readonly text: string | null;
  readonly source: string | null;
  readonly user: {
    readonly id: string;
    readonly name: string;
  };
};

type DisplayMessageProps = {
  message: DisplayConversation_message$key;
};

const DisplayMessage = (props: DisplayMessageProps) => {
  const { publicUserId } = useContext(UserContext);
  const message = useFragment<DisplayConversation_message$key>(
    graphql`
      fragment DisplayConversation_message on ConversationMessage {
        text
        source
        user {
          id
          name
        }
      }
    `,
    props.message
  );
  if (publicUserId === message.user.id) {
    return (
      <div className="chat-message">
        <div className="flex items-end justify-end">
          <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-1 items-end">
            <div>
              <span className="whitespace-pre-wrap px-4 py-2 rounded-br-none rounded-md inline-block bg-blue-600 text-white ">
                {message.text ? message.text : null}
                {message.source ? (
                  <audio preload="none" controls={true} src={message.source as any} />
                ) : null}
              </span>
            </div>
          </div>
          <UserAvatar className="order-2" name={message.user.name} />
        </div>
      </div>
    );
  } else {
    return (
      <div className="chat-message">
        <div className="flex items-end">
          <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-2 items-start">
            <div>
              <span className="whitespace-pre-wrap px-4 py-2 rounded-md inline-block rounded-bl-none bg-gray-300 text-gray-600">
                {message.text ? message.text : null}
                {message.source ? (
                  <audio preload="none" controls={true} src={message.source as any} />
                ) : null}
              </span>
            </div>
          </div>
          <UserAvatar name={message.user.name} />
        </div>
      </div>
    );
  }
};
