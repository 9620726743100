import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormSubmitButton } from '../FormElements/Buttons';
import { Form, FormRow } from '../FormElements/Form';
import { FormHeading } from '../FormElements/FormHeading';
import { LabelledTextField } from '../FormElements/TextInput';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';
import { LabelledSwitchField } from '../FormElements/Switch';
import { FormState } from '../Types';
import { ButtonLink } from '../FormElements/Link';
import { useHistory } from 'react-router-dom';
import { apiUrl } from '../../App';
import { addChangesetErrorsToForm } from '../FormElements/ValidationRules';

export const LoginPage: FC = (props) => {
  return (
    <>
      <div className="h-screen bg-white overflow-hidden flex">
        <div className="flex-1 max-w-4xl mx-auto w-0 flex flex-col md:px-8 xl:px-0">
          <main className="flex-1 relative overflow-y-auto focus:outline-none" tabIndex={0}>
            <div className="py-6">
              <div className="px-4 sm:px-6 md:px-0">
                <Login />
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Ungültige E-Mail Adresse.').required('Required'),
});

type LoginInput = {
  email: string;
  password: string;
};

interface LoginProps {}

export const Login: FC<LoginProps> = (props) => {
  const [globalFormError, setGlobalFormError] = useState('');
  const [formState, setFormState] = useState(FormState.Idle);
  const { register, handleSubmit, errors, control, setError } = useForm<LoginInput>({
    resolver: yupResolver(validationSchema),
  });

  const history = useHistory();

  const forgotPasword = () => {
    history.push('/forgot_password');
  };

  const onSubmit = (data: LoginInput) => {
    if (formState === FormState.Submitting) {
      return;
    }
    setFormState(FormState.Submitting);
    axios({
      method: 'post',
      url: apiUrl + '/api/users/log_in',
      data: { user: data },
      withCredentials: true,
    })
      .then((res) => {
        if (!res.data.successful) {
          setFormState(FormState.Failure);
          setGlobalFormError(res.data.message);
        } else {
          setFormState(FormState.Success);
          window.location.href = '/';
        }
      })
      .catch((error) => {
        console.log('Got error:');
        console.log(error);
        addChangesetErrorsToForm(error.response.data, setError as any, setGlobalFormError);
        setFormState(FormState.Failure);
      });
  };
  //Somehow it is sometimes buggy without this console.log...
  // console.log(errors);
  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="pt-20">
      <FormHeading formState={formState} formMessage={globalFormError} heading="Login" />
      <LabelledTextField
        errors={errors.email?.message}
        label="E-Mail"
        name="email"
        inputRef={register({})}
      />
      <LabelledTextField
        errors={errors.password?.message}
        label="Passwort"
        name="password"
        type="password"
        inputRef={register()}
      />
      <LabelledSwitchField
        control={control}
        label="Eingeloggt bleiben?"
        name="remember_me"
        description="Eingeloggt bleiben, auch wenn Sie den Browser schließen und neu öffnen."
      />
      <FormRow className="flex">
        <span className="flex-1 text-left">
          <ButtonLink onClick={forgotPasword}>Passwort vergessen</ButtonLink>
        </span>
        <span className="flex-1">
          <FormSubmitButton
            isSubmitting={formState === FormState.Submitting}
            buttonLabel="Einloggen"
          />
        </span>
      </FormRow>
    </Form>
  );
};
