import { ComponentProps, FC } from 'react';

interface FormProps extends ComponentProps<'form'> {}

export const Form: FC<FormProps> = (props) => {
  let { children, className, ...otherProps } = props;
  let extraClasses = className ? className : '';
  let classes = extraClasses + ' mt-6 sm:mt-5 space-y-6 sm:space-y-5 divide-y divide-gray-200';

  return (
    <form className={classes} {...otherProps}>
      {children}
    </form>
  );
};

// In future maybe wrap all the form elements in this
// now just created it for having multiple buttons
export const FormRow: FC<ComponentProps<'div'>> = (props) => {
  let { className, ...otherProps } = props;
  className = className + ' sm:border-t sm:pt-5 pr-5 text-right';
  return (
    <div className={className} {...otherProps}>
      {props.children}
    </div>
  );
};
