/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CourseEdit_CourseUserList = {
    readonly courseUsers: {
        readonly edges: ReadonlyArray<{
            readonly role: string;
            readonly node: {
                readonly id: string;
                readonly name: string;
            };
        }>;
    } | null;
    readonly " $refType": "CourseEdit_CourseUserList";
};
export type CourseEdit_CourseUserList$data = CourseEdit_CourseUserList;
export type CourseEdit_CourseUserList$key = {
    readonly " $data"?: CourseEdit_CourseUserList$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"CourseEdit_CourseUserList">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "courseUsers"
        ]
      }
    ]
  },
  "name": "CourseEdit_CourseUserList",
  "selections": [
    {
      "alias": "courseUsers",
      "args": null,
      "concreteType": "CourseUsersConnection",
      "kind": "LinkedField",
      "name": "__CourseEdit_courseUsers_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CourseUsersEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "role",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Course",
  "abstractKey": null
};
(node as any).hash = 'dc77db516bf72dcb711103dfbb55d460';
export default node;
