/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type InvitationCodeUpdateInput = {
    id: string;
    isActive: boolean;
    name?: string | null | undefined;
};
export type InvitationCodeEditMutationVariables = {
    input: InvitationCodeUpdateInput;
};
export type InvitationCodeEditMutationResponse = {
    readonly invitationCodeUpdate: {
        readonly result: {
            readonly id: string;
            readonly invitationCode: string;
            readonly role: string;
            readonly isActive: boolean;
            readonly name: string | null;
            readonly maximalAccounts: number;
            readonly creator: {
                readonly name: string;
            } | null;
        } | null;
        readonly successful: boolean;
        readonly messages: ReadonlyArray<{
            readonly code: string;
            readonly field: string | null;
            readonly message: string | null;
        } | null> | null;
    } | null;
};
export type InvitationCodeEditMutation = {
    readonly response: InvitationCodeEditMutationResponse;
    readonly variables: InvitationCodeEditMutationVariables;
};



/*
mutation InvitationCodeEditMutation(
  $input: InvitationCodeUpdateInput!
) {
  invitationCodeUpdate(input: $input) {
    result {
      id
      invitationCode
      role
      isActive
      name
      maximalAccounts
      creator {
        name
        id
      }
    }
    successful
    messages {
      code
      field
      message
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invitationCode",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isActive",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "maximalAccounts",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "successful",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationMessage",
  "kind": "LinkedField",
  "name": "messages",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InvitationCodeEditMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InvitationCodeUpdatePayload",
        "kind": "LinkedField",
        "name": "invitationCodeUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InvitationCode",
            "kind": "LinkedField",
            "name": "result",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PublicUser",
                "kind": "LinkedField",
                "name": "creator",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InvitationCodeEditMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InvitationCodeUpdatePayload",
        "kind": "LinkedField",
        "name": "invitationCodeUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InvitationCode",
            "kind": "LinkedField",
            "name": "result",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PublicUser",
                "kind": "LinkedField",
                "name": "creator",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "374dd38d507552a970ffa5569719fae2",
    "id": null,
    "metadata": {},
    "name": "InvitationCodeEditMutation",
    "operationKind": "mutation",
    "text": "mutation InvitationCodeEditMutation(\n  $input: InvitationCodeUpdateInput!\n) {\n  invitationCodeUpdate(input: $input) {\n    result {\n      id\n      invitationCode\n      role\n      isActive\n      name\n      maximalAccounts\n      creator {\n        name\n        id\n      }\n    }\n    successful\n    messages {\n      code\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '9b7eb759f618d2a5056cdfb0f7f15061';
export default node;
