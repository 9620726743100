import {
  ConversationDeleteMutation,
  ConversationDeleteMutationResponse,
} from '../../__generated__/ConversationDeleteMutation.graphql';
import { graphql } from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay/hooks';
import { MutationState } from '../Types';
import React, { useState } from 'react';
import { ConnectionHandler, PayloadError } from 'relay-runtime';
import { Spinner } from '../Spinner';
import { ButtonLink } from '../FormElements/Link';

type ConversationDeleteProps = {
  id: string;
  videoId: string;
};

export const ConversationDelete = (props: ConversationDeleteProps) => {
  const { id, videoId } = props;
  const [commit, areMutationsInFlight] = useMutation<ConversationDeleteMutation>(graphql`
    mutation ConversationDeleteMutation($input: ConversationDeleteInput!) {
      conversationDelete(input: $input) {
        result {
          idDeleted
        }
        successful
        messages {
          code
          field
          message
        }
      }
    }
  `);

  const deleteConversation = () => {
    if (areMutationsInFlight) {
      return;
    }
    commit({
      variables: { input: { id } },
      onCompleted: (
        response: ConversationDeleteMutationResponse,
        errors: PayloadError[] | null
      ) => {
        const ret = response.conversationDelete;
        if (ret && !ret.successful && ret.messages) {
          console.log('Error while deleting Conversation', ret);
        }
      },
      updater: (store) => {
        // const conversationRecord = store.get(id);
        const parentRecord = store.get(videoId);
        const connection =
          parentRecord &&
          ConnectionHandler.getConnection(parentRecord, 'videoComments_courseVideoConversations');
        connection && ConnectionHandler.deleteNode(connection, id);
      },
    });
  };

  if (areMutationsInFlight) {
    return <Spinner />;
  } else {
    return <ButtonLink onClick={deleteConversation}>Löschen</ButtonLink>;
  }
};
