import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery, useMutation } from 'react-relay/hooks';
import { RouteComponentProps } from 'react-router-dom';
import { PayloadError } from 'relay-runtime';
import {
  InvitationCodeEditMutation,
  InvitationCodeEditMutationResponse,
} from '../../__generated__/InvitationCodeEditMutation.graphql';
import { InvitationCodeEditQuery } from '../../__generated__/InvitationCodeEditQuery.graphql';
import {
  AbsintheValidationMessages,
  asyncValidateFormValues,
  ValidationMessage,
} from '../FormElements/ValidationRules';
import { FormSubmitButton } from '../FormElements/Buttons';
import { Form, FormRow } from '../FormElements/Form';
import { FormHeading } from '../FormElements/FormHeading';
import { LabelledReadonlyField, LabelledTextField } from '../FormElements/TextInput';
import { LabelledSwitchField } from '../FormElements/Switch';
import { FormState } from '../Types';

type InvitationCodeEditParams = { id: string };
interface InvitationCodeEditProps extends RouteComponentProps<InvitationCodeEditParams> {}

type InvitationCodeEditFormValues = {
  id: string;
  note: string;
  isActive: boolean;
};

export const InvitationCodeEdit: FC<InvitationCodeEditProps> = (props) => {
  const [formState, setFormState] = useState(FormState.Idle);
  const [formMessage, setFormMessage] = useState('');

  const id = props.match.params.id;
  const data = useLazyLoadQuery<InvitationCodeEditQuery>(
    graphql`
      query InvitationCodeEditQuery($id: ID!) {
        invitationCodeById(id: $id) {
          id
          invitationCode
          role
          isActive
          name
          maximalAccounts
          creator {
            name
          }
        }
      }
    `,
    { id: id },
    { fetchPolicy: 'store-or-network' }
  );

  const invitationCode = data.invitationCodeById;
  let defaultValues = {};
  if (invitationCode) {
    defaultValues = invitationCode;
  }

  const {
    register,
    handleSubmit,
    setError,
    errors,
    control,
  } = useForm<InvitationCodeEditFormValues>({
    defaultValues: defaultValues,
  });

  const [commit] = useMutation<InvitationCodeEditMutation>(graphql`
    mutation InvitationCodeEditMutation($input: InvitationCodeUpdateInput!) {
      invitationCodeUpdate(input: $input) {
        result {
          id
          invitationCode
          role
          isActive
          name
          maximalAccounts
          creator {
            name
          }
        }
        successful
        messages {
          code
          field
          message
        }
      }
    }
  `);

  const onSubmit = (formvalues: InvitationCodeEditFormValues) => {
    if (formState === FormState.Submitting) {
      return;
    }
    setFormState(FormState.Submitting);
    commit({
      variables: { input: formvalues },
      onCompleted: (response, errors) => {
        const ret = response.invitationCodeUpdate;
        if (ret && !ret.successful && ret.messages) {
          const formValidationMessages = ret.messages as AbsintheValidationMessages<InvitationCodeEditFormValues>;
          asyncValidateFormValues(formValidationMessages, setError, setFormMessage);
          setFormState(FormState.Failure);
        } else {
          setFormState(FormState.Success);
          setFormMessage('Der Kurs wurde erfolgreich erstellt.');
        }
      },
    });
  };

  if (invitationCode && invitationCode.creator) {
    return (
      <>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormHeading
            formMessage={formMessage}
            heading="Einladungslink bearbeiten"
            formState={formState}
            subCaption="Hier können Sie den Einladungslink deaktivieren, damit er nichtmehr dazu genutzt werden kann, neue Accounts zu erstellen. Die Berechtigungen der erstellten
          Accounts oder die maximale Anzahl der Accounts, die mit dem Code erstellt werden können, können Sie nicht ändern. Sie können den Code jedoch deaktivieren und einen
          neuen erstellen."
          />
          <input name="id" type="hidden" ref={register()} value={id} />
          {/* TODO NEXT: Gucken ob ich das Switch field zum funktionieren bekomme, sonst vll besser checkbox... */}
          <LabelledTextField
            errors={errors.note?.message}
            label="Name"
            name="name"
            inputRef={register({
              maxLength: {
                value: 255,
                message: ValidationMessage('maxLength', 255),
              },
              minLength: {
                value: 4,
                message: ValidationMessage('minLength', 4),
              },
            })}
          />
          <LabelledSwitchField
            control={control}
            label="Ist aktiv"
            name="isActive"
            description="Sie können den Link hier deaktivieren, damit keine weiteren Accounts mehr mit ihm erstellt werden können."
          />
          <LabelledReadonlyField label="Maximal erstellbare Accounts">
            {invitationCode.maximalAccounts}
          </LabelledReadonlyField>
          <LabelledReadonlyField label="Ersteller">
            {invitationCode.creator.name}
          </LabelledReadonlyField>
          <LabelledReadonlyField
            label="Einladungslink"
            description="Geben Sie diesen Link Personen, damit diese sich ein Account erstellen können."
          >
            {window.location.origin + '/register/' + invitationCode.invitationCode}
          </LabelledReadonlyField>
          <FormRow>
            <FormSubmitButton
              isSubmitting={formState === FormState.Submitting}
              buttonLabel="Speichern"
            />
          </FormRow>
        </Form>
      </>
    );
  } else {
    return <>Wir konnten den Kurs leider nicht finden</>;
  }
};
