import React, { FC, useState, Suspense, useContext } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay/hooks';
import { RouteComponentProps } from 'react-router-dom';
import { PageDescription, PageHeading } from '../StructuringElements';
import { VideoComments } from './VideoComments';
import { AppContext } from '../../App';
import { Button } from '../FormElements/Buttons';
import { VideoControls, VideoPlayer } from '../VideoPlayers/VideoPlayer';
import { CourseVideoViewQuery } from '../../__generated__/CourseVideoViewQuery.graphql';

// courseId is for now only needed to make the breadcrumbs work and have a link to the course ready
interface VideoViewProps extends RouteComponentProps<{ courseVideoId: string; courseId: string }> {}

export const CourseVideoView: FC<VideoViewProps> = (props) => {
  const courseVideoId = props.match.params.courseVideoId;
  const [videoControls, setVideoControls] = useState(null as VideoControls | null);

  const data = useLazyLoadQuery<CourseVideoViewQuery>(
    graphql`
      query CourseVideoViewQuery($id: ID!) {
        courseVideoById(id: $id) {
          id
          name
          description
          ...VideoComments_VideoComments
          video {
            sourceType
            sourceData
            state
            stateMessage
          }
        }
      }
    `,
    { id: courseVideoId },
    { fetchPolicy: 'store-or-network' }
  );

  const { isInTheatreMode } = useContext(AppContext);
  const setIsInTheatreMode = (useContext(AppContext).setIsInTheatreMode as unknown) as (
    b: boolean
  ) => void;

  const courseVideo = data.courseVideoById;

  if (!courseVideo) {
    return <>Wir konnten das Video nicht finden</>;
  }
  const video = courseVideo.video;

  let videoElem = <></>;
  if (video.state == 'READY' && video.sourceData) {
    videoElem = (
      <VideoPlayer
        sourceData={video.sourceData}
        sourceType={video.sourceType}
        setVideoControls={setVideoControls}
      />
    );
  } else if (video.state == 'ERRORED') {
    videoElem = (
      <>Bei der Bereitstellung des Videos ist ein Fehler aufgetreten: "{video.stateMessage}"</>
    );
  } else if (video.state == 'INITIAL') {
    videoElem = <>Das Video wird noch hochgeladen.</>;
  } else if (video.state == 'CREATED') {
    videoElem = (
      <>
        Das Video wird momentan auf unseren Servern optimiert. Bitte warten Sie noch einige Minuten.
      </>
    );
  } else {
    videoElem = <>Hier ist etwas schief gelaufen.</>;
  }

  return (
    <>
      <div className="flex flex-col space-y-10">
        <span>
          <PageHeading>{courseVideo.name}</PageHeading>
          <PageDescription>{courseVideo.description}</PageDescription>
        </span>
        <span>
          <span>Videogröße:</span>
          <Button className="ml-5" onClick={() => setIsInTheatreMode(!isInTheatreMode)}>
            {isInTheatreMode ? 'Groß' : 'Klein'}
          </Button>
        </span>
        <div>{videoElem}</div>
        <Suspense fallback="Kommentare werden geladen">
          {videoControls ? (
            <VideoComments
              videoId={courseVideoId}
              videoControls={videoControls}
              courseVideo={courseVideo}
            />
          ) : null}
        </Suspense>
      </div>
    </>
  );
};
