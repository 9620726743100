import React from 'react';
import { Switch } from '@headlessui/react';
import { LabelledInputField, LabelledInputFieldProps } from './TextInput';
import { Control, Controller } from 'react-hook-form';

type TailwindSwitchProps = {
  // switchValue: boolean,
  // setSwitchValue: (setTo: boolean) => void,
  name: string;
  control: Control;
};

export function SwitchField(props: TailwindSwitchProps) {
  // const [switchValue, setSwitchValue] = useState(false);
  const { name, control } = props;

  return (
    <div className="flex">
      {/* <input name={name} ref={inputRef} type="hidden" value={switchValue === true ? 1 : 0} /> */}
      <span className="sr-only">Beitrittscode aktivieren</span>
      <Controller
        control={control}
        name={name}
        render={({ onChange, value }, _) => (
          <Switch
            as="button"
            checked={value}
            onChange={onChange}
            className={`${
              value ? 'bg-blue-400' : 'bg-gray-200'
            } relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:shadow-outline`}
          >
            {({ checked }) => (
              <span
                className={`${
                  checked ? 'translate-x-5' : 'translate-x-0'
                } inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full`}
              />
            )}
          </Switch>
        )}
      />
    </div>
  );
}

export const LabelledSwitchField = (props: TailwindSwitchProps & LabelledInputFieldProps) => {
  let { label, errors, description, ...fieldProps } = props;
  const field = <SwitchField {...fieldProps} />;
  const labelledField = (
    <LabelledInputField
      for={props.name}
      inputField={field}
      label={label}
      description={description}
    />
  );
  return labelledField;
};
