import axios from 'axios';
import { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { apiUrl } from '../../App';
import { Spinner } from '../Spinner';
import { FormState } from '../Types';

type Params = { token: string };
interface Props extends RouteComponentProps<Params> {}

export const ConfirmUser = (props: Props) => {
  const history = useHistory();
  const [requestState, setRequestState] = useState(FormState.Idle);
  const [serverResponse, setServerResponse] = useState('');
  const token = props.match.params.token;
  useEffect(() => {
    setRequestState(FormState.Submitting);
    axios({
      method: 'get',
      url: apiUrl + `/api/users/confirm/${token}`,
      withCredentials: true,
    }).then((res) => {
      if (res.data.successful) {
        setRequestState(FormState.Success);
      } else if (res.data.message && res.data.message === 'already_confirmed') {
        history.push('/');
      } else {
        setServerResponse(res.data.message);
        setRequestState(FormState.Failure);
      }
    });
  }, []);

  if (requestState === FormState.Failure) {
    return <>{serverResponse}</>;
  } else if (requestState === FormState.Success) {
    return <>Sie haben Ihre E-Mail Adresse bestätigt.</>;
  } else {
    return <Spinner />;
  }
};
