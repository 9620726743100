import { graphql } from 'babel-plugin-relay/macro';
import React from 'react';
import { useFragment } from 'react-relay/hooks';
import { CourseVideoList_video$key } from '../../__generated__/CourseVideoList_video.graphql';
import { CourseVideoList_videoList$key } from '../../__generated__/CourseVideoList_videoList.graphql';
import { MyLink } from '../FormElements/Link';
import {
  Table,
  TableBody,
  TableColumn,
  TableColumnLink,
  TableHead,
  TableHeadColumn,
  TableRow,
} from '../TableComponents';

type CourseVideoListProps = {
  course: CourseVideoList_videoList$key;
  editOption?: boolean;
};

export const CourseVideoList = (props: CourseVideoListProps) => {
  const data = useFragment<CourseVideoList_videoList$key>(
    graphql`
      fragment CourseVideoList_videoList on Course {
        id
        courseVideos(first: 999) @connection(key: "VideoList_courseVideos") {
          edges {
            ...CourseVideoList_video
            node {
              id
            }
          }
        }
      }
    `,
    props.course
  );

  const courseVideos = data.courseVideos;
  return (
    <Table>
      <TableHead>
        <TableHeadColumn className="">Video</TableHeadColumn>
        {props.editOption ? (
          <TableHeadColumn>
            <span className="sr-only">Details</span>
          </TableHeadColumn>
        ) : null}
      </TableHead>
      <TableBody>
        {courseVideos
          ? courseVideos.edges.map((courseVideo) => (
              <CourseVideoListItem
                courseVideo={courseVideo}
                key={courseVideo.node.id}
                editOption={props.editOption}
                courseId={data.id}
              />
            ))
          : null}
      </TableBody>
    </Table>
  );
};

type CourseVideoListItemProps = {
  courseVideo: CourseVideoList_video$key;
  editOption?: boolean;
  courseId: string;
};

const CourseVideoListItem = (props: CourseVideoListItemProps) => {
  const data = useFragment<CourseVideoList_video$key>(
    graphql`
      fragment CourseVideoList_video on CourseVideosEdge {
        node {
          id
          name
        }
      }
    `,
    props.courseVideo
  );

  const couseVideo = data.node;

  return (
    <TableRow className="hover:bg-gray-100">
      <TableColumnLink to={`/course_videos/${props.courseId}/${couseVideo.id}`}>
        {couseVideo.name}
      </TableColumnLink>
      {props.editOption ? (
        <TableColumn>
          <MyLink to={`/courses_edit/video_edit/${props.courseId}/${couseVideo.id}`}>
            Bearbeiten
          </MyLink>
        </TableColumn>
      ) : null}
    </TableRow>
  );
};
