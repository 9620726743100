/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CourseVideoUpdateInput = {
    description: string;
    id: string;
    name: string;
};
export type CourseVideoEditMutationVariables = {
    input: CourseVideoUpdateInput;
};
export type CourseVideoEditMutationResponse = {
    readonly courseVideoUpdate: {
        readonly result: {
            readonly id: string;
            readonly name: string;
            readonly description: string | null;
        } | null;
        readonly successful: boolean;
        readonly messages: ReadonlyArray<{
            readonly code: string;
            readonly field: string | null;
            readonly message: string | null;
        } | null> | null;
    } | null;
};
export type CourseVideoEditMutation = {
    readonly response: CourseVideoEditMutationResponse;
    readonly variables: CourseVideoEditMutationVariables;
};



/*
mutation CourseVideoEditMutation(
  $input: CourseVideoUpdateInput!
) {
  courseVideoUpdate(input: $input) {
    result {
      id
      name
      description
    }
    successful
    messages {
      code
      field
      message
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CourseVideoUpdatePayload",
    "kind": "LinkedField",
    "name": "courseVideoUpdate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CourseVideo",
        "kind": "LinkedField",
        "name": "result",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "successful",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationMessage",
        "kind": "LinkedField",
        "name": "messages",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CourseVideoEditMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CourseVideoEditMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "efba2f00b89f27743199dbac48b361bb",
    "id": null,
    "metadata": {},
    "name": "CourseVideoEditMutation",
    "operationKind": "mutation",
    "text": "mutation CourseVideoEditMutation(\n  $input: CourseVideoUpdateInput!\n) {\n  courseVideoUpdate(input: $input) {\n    result {\n      id\n      name\n      description\n    }\n    successful\n    messages {\n      code\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '6992bee0163f3b756c1496e0dd3b1c38';
export default node;
