/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type VideoCreate_OrganisationVideoTypesQueryVariables = {};
export type VideoCreate_OrganisationVideoTypesQueryResponse = {
    readonly me: {
        readonly organisation: {
            readonly videoHosterSettings: {
                readonly mux: boolean | null;
                readonly panopto: boolean | null;
                readonly youtube: boolean | null;
                readonly file: boolean | null;
            };
        };
    };
};
export type VideoCreate_OrganisationVideoTypesQuery = {
    readonly response: VideoCreate_OrganisationVideoTypesQueryResponse;
    readonly variables: VideoCreate_OrganisationVideoTypesQueryVariables;
};



/*
query VideoCreate_OrganisationVideoTypesQuery {
  me {
    organisation {
      videoHosterSettings {
        mux
        panopto
        youtube
        file
      }
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "VideoHosterSettings",
  "kind": "LinkedField",
  "name": "videoHosterSettings",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mux",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "panopto",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "youtube",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "file",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "VideoCreate_OrganisationVideoTypesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organisation",
            "kind": "LinkedField",
            "name": "organisation",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "VideoCreate_OrganisationVideoTypesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organisation",
            "kind": "LinkedField",
            "name": "organisation",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "483560666919259d458f2f4511bd8d36",
    "id": null,
    "metadata": {},
    "name": "VideoCreate_OrganisationVideoTypesQuery",
    "operationKind": "query",
    "text": "query VideoCreate_OrganisationVideoTypesQuery {\n  me {\n    organisation {\n      videoHosterSettings {\n        mux\n        panopto\n        youtube\n        file\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '6429e8b9d4b6fad6c2b8dafa29d83b07';
export default node;
