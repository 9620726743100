import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery, useMutation } from 'react-relay/hooks';
import { RouteComponentProps } from 'react-router-dom';
import { PayloadError } from 'relay-runtime';
import {
  AbsintheValidationMessages,
  asyncValidateFormValues,
  ValidationMessage,
} from '../FormElements/ValidationRules';
import { FormSubmitButton } from '../FormElements/Buttons';
import { Form, FormRow } from '../FormElements/Form';
import { FormHeading } from '../FormElements/FormHeading';
import { LabelledTextArea, LabelledTextField } from '../FormElements/TextInput';
import { VideoEditQuery } from '../../__generated__/VideoEditQuery.graphql';
import {
  VideoEditMutation,
  VideoEditMutationResponse,
  VideoUpdateInput,
} from '../../__generated__/VideoEditMutation.graphql';
import { VideoDelete } from './VideoDelete';
import { FormState } from '../Types';
import { LabelledListBoxField } from '../FormElements/ListBox';
import { Page, PageDescription, PageHeader, PageHeading } from '../StructuringElements';
import { CheckCircleIcon } from '@heroicons/react/outline';
import { LabelledSwitchField } from '../FormElements/Switch';

interface VideoEditProps extends RouteComponentProps<{ videoId: string }> {}

export const VideoEdit: FC<VideoEditProps> = (props) => {
  const [formState, setFormState] = useState(FormState.Idle);
  const [formMessage, setFormMessage] = useState('');
  const [videoDeleted, setVideoDeleted] = useState(false);

  const id = props.match.params.videoId;
  const data = useLazyLoadQuery<VideoEditQuery>(
    graphql`
      query VideoEditQuery($id: ID!) {
        videoById(id: $id) {
          id
          name
          description
          public
        }
      }
    `,
    { id: id },
    { fetchPolicy: 'store-or-network' }
  );

  const video = data.videoById;
  const defaultValues = video ? video : {};

  const { register, handleSubmit, setError, errors, control } = useForm<VideoUpdateInput>({
    defaultValues: defaultValues,
  });

  const [commit] = useMutation<VideoEditMutation>(graphql`
    mutation VideoEditMutation($input: VideoUpdateInput!) {
      videoUpdate(input: $input) {
        result {
          id
          name
          description
          sourceData
          sourceType
        }
        successful
        messages {
          code
          field
          message
        }
      }
    }
  `);

  const onSubmit = (formvalues: VideoUpdateInput) => {
    if (formState === FormState.Submitting) {
      return;
    }
    setFormState(FormState.Submitting);
    commit({
      variables: { input: formvalues },
      onCompleted: (response: VideoEditMutationResponse, errors: PayloadError[] | null) => {
        const ret = response.videoUpdate;
        if (ret && !ret.successful && ret.messages) {
          const formValidationMessages = ret.messages as AbsintheValidationMessages<VideoUpdateInput>;
          asyncValidateFormValues(formValidationMessages, setError, setFormMessage);
          setFormState(FormState.Failure);
        } else {
          setFormState(FormState.Success);
          setFormMessage('Die Änderungen wurden gespeichert.');
        }
      },
    });
  };

  if (videoDeleted) {
    return (
      <Page>
        <PageHeader>
          <div className="flex items-center mb-2">
            <PageHeading>Video gelöscht</PageHeading>
            <CheckCircleIcon className="w-8 ml-4" />
          </div>
          <PageDescription>Das Video wurde gelöscht.</PageDescription>
        </PageHeader>
      </Page>
    );
  } else if (video) {
    return (
      <Page>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormHeading formState={formState} formMessage={formMessage} heading="Video editieren" />
          <input type="hidden" name="id" value={id} ref={register()} />
          <LabelledTextField
            errors={errors.name?.message}
            label="Name"
            description="Unter welchem Namen soll das Video zu sehen sein?"
            name="name"
            inputRef={register({
              required: {
                value: true,
                message: ValidationMessage('required'),
              },
              minLength: { value: 4, message: ValidationMessage('minLength', 4) },
              maxLength: { value: 63, message: ValidationMessage('maxLength', 63) },
            })}
          />
          <LabelledTextArea
            errors={errors.description?.message}
            label="Videobeschreibung"
            description="Alle weiteren Details zum Video."
            name="description"
            inputRef={register({
              maxLength: {
                value: 10000,
                message: ValidationMessage('maxLength', 10000),
              },
            })}
          />
          <LabelledSwitchField
            name="public"
            label="Öffentlich"
            description="Sollen andere Lehrer Zugang zu diesem Video haben?"
            control={control}
          />
          <FormRow className="flex space-between content-between">
            <VideoDelete
              videoDeleted={() => setVideoDeleted(true)}
              videoId={id}
              setError={setError as any}
              setGlobalFormError={setFormMessage}
              formState={formState}
              setFormState={setFormState}
              className="ml-5"
            />
            <div className="ml-auto">
              <FormSubmitButton
                isSubmitting={formState === FormState.Submitting}
                buttonLabel="Speichern"
              />
            </div>
          </FormRow>
        </Form>
      </Page>
    );
  } else {
    return <>Wir können das Video nicht finden.</>;
  }
};
