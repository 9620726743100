/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CourseVideoList_video = {
    readonly node: {
        readonly id: string;
        readonly name: string;
    };
    readonly " $refType": "CourseVideoList_video";
};
export type CourseVideoList_video$data = CourseVideoList_video;
export type CourseVideoList_video$key = {
    readonly " $data"?: CourseVideoList_video$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"CourseVideoList_video">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseVideoList_video",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CourseVideo",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CourseVideosEdge",
  "abstractKey": null
};
(node as any).hash = 'ef728fa685f9699f3a1a6fb48d2f4c2f';
export default node;
