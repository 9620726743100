/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CourseUserAddInput = {
    accessCode: string;
};
export type CourseJoinCourseUserAddMutationVariables = {
    input: CourseUserAddInput;
};
export type CourseJoinCourseUserAddMutationResponse = {
    readonly courseUserAdd: {
        readonly result: {
            readonly name: string;
            readonly id: string;
        } | null;
        readonly successful: boolean;
        readonly messages: ReadonlyArray<{
            readonly code: string;
            readonly field: string | null;
            readonly message: string | null;
        } | null> | null;
    } | null;
};
export type CourseJoinCourseUserAddMutation = {
    readonly response: CourseJoinCourseUserAddMutationResponse;
    readonly variables: CourseJoinCourseUserAddMutationVariables;
};



/*
mutation CourseJoinCourseUserAddMutation(
  $input: CourseUserAddInput!
) {
  courseUserAdd(input: $input) {
    result {
      name
      id
    }
    successful
    messages {
      code
      field
      message
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CourseUserAddPayload",
    "kind": "LinkedField",
    "name": "courseUserAdd",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Course",
        "kind": "LinkedField",
        "name": "result",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "successful",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationMessage",
        "kind": "LinkedField",
        "name": "messages",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CourseJoinCourseUserAddMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CourseJoinCourseUserAddMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c31ec69fa66b0bab6753d5cdbe7a058e",
    "id": null,
    "metadata": {},
    "name": "CourseJoinCourseUserAddMutation",
    "operationKind": "mutation",
    "text": "mutation CourseJoinCourseUserAddMutation(\n  $input: CourseUserAddInput!\n) {\n  courseUserAdd(input: $input) {\n    result {\n      name\n      id\n    }\n    successful\n    messages {\n      code\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '10e03696a3247614e03e362cdd14ed8f';
export default node;
