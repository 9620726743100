/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CourseUserUpdateInput = {
    courseId: string;
    role: string;
    userId: string;
};
export type CourseUserEditMutationVariables = {
    input: CourseUserUpdateInput;
};
export type CourseUserEditMutationResponse = {
    readonly courseUserUpdate: {
        readonly successful: boolean;
        readonly result: {
            readonly role: string;
        } | null;
        readonly messages: ReadonlyArray<{
            readonly message: string | null;
        } | null> | null;
    } | null;
};
export type CourseUserEditMutation = {
    readonly response: CourseUserEditMutationResponse;
    readonly variables: CourseUserEditMutationVariables;
};



/*
mutation CourseUserEditMutation(
  $input: CourseUserUpdateInput!
) {
  courseUserUpdate(input: $input) {
    successful
    result {
      role
    }
    messages {
      message
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CourseUserUpdatePayload",
    "kind": "LinkedField",
    "name": "courseUserUpdate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "successful",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CourseUser",
        "kind": "LinkedField",
        "name": "result",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationMessage",
        "kind": "LinkedField",
        "name": "messages",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CourseUserEditMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CourseUserEditMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "238075f834323f62195f7db3261b312f",
    "id": null,
    "metadata": {},
    "name": "CourseUserEditMutation",
    "operationKind": "mutation",
    "text": "mutation CourseUserEditMutation(\n  $input: CourseUserUpdateInput!\n) {\n  courseUserUpdate(input: $input) {\n    successful\n    result {\n      role\n    }\n    messages {\n      message\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '84a8149fb94ef0bcf774b2d40413cdb2';
export default node;
