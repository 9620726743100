/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type VideoComments_VideoComments = {
    readonly courseVideoConversations: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly timeInSeconds: number;
                readonly conversationMessages: {
                    readonly edges: ReadonlyArray<{
                        readonly node: {
                            readonly text: string | null;
                            readonly source: string | null;
                            readonly user: {
                                readonly id: string;
                                readonly name: string;
                            };
                        };
                    }>;
                } | null;
            };
        }>;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"VoiceMessageComposer_UploadVoiceCommentForm">;
    readonly " $refType": "VideoComments_VideoComments";
};
export type VideoComments_VideoComments$data = VideoComments_VideoComments;
export type VideoComments_VideoComments$key = {
    readonly " $data"?: VideoComments_VideoComments$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"VideoComments_VideoComments">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "courseVideoConversations"
        ]
      }
    ]
  },
  "name": "VideoComments_VideoComments",
  "selections": [
    {
      "alias": "courseVideoConversations",
      "args": null,
      "concreteType": "CourseVideoConversationsConnection",
      "kind": "LinkedField",
      "name": "__videoComments_courseVideoConversations_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CourseVideoConversationsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Conversation",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "timeInSeconds",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "first",
                      "value": 1
                    }
                  ],
                  "concreteType": "ConversationMessagesConnection",
                  "kind": "LinkedField",
                  "name": "conversationMessages",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ConversationMessagesEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ConversationMessage",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "text",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "source",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "PublicUser",
                              "kind": "LinkedField",
                              "name": "user",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "name",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "conversationMessages(first:1)"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VoiceMessageComposer_UploadVoiceCommentForm"
    }
  ],
  "type": "CourseVideo",
  "abstractKey": null
};
})();
(node as any).hash = '08d22e7f80a812addc6e546d8df25e6d';
export default node;
