import * as d3 from 'd3';
import { NumberValue, svg } from 'd3';
import { RefObject, useEffect, useRef, useState } from 'react';
import { useFragment } from 'react-relay/hooks';
import { printTime } from '../Helpers';
import { Conversation } from '../Types';
import { ConversationStart } from './VideoComments';

// function randomVoiceComments(maxTime:number, samples:number){
//   console.log(maxTime)
//   return Array.from({length: samples}, () => Math.floor(Math.random() * maxTime));
// }

type VideoCommentsTimeLineProps = {
  conversations: Conversation[];
  videoDuration: number;
  showMessage: (c: Conversation) => void;
  jumpToMessage: (c: Conversation) => void;
};

export const VideoCommentsTimeLine = (props: VideoCommentsTimeLineProps) => {
  const { showMessage, conversations, videoDuration, jumpToMessage } = props;
  const [width, setWidth] = useState(null as number | null);

  const containerRef = useRef<SVGSVGElement>(null);
  const containerDivRef = useRef<HTMLDivElement>(null);
  const axisRef = useRef<SVGPathElement>(null);

  useEffect(() => {
    const containerDiv = containerDivRef.current as HTMLDivElement;
    const newWidth = () => {
      const newWidth = containerDiv.getBoundingClientRect().width;
      if (newWidth !== width) {
        const container = containerRef.current as SVGSVGElement;
        d3.select(container).selectAll('.icons').remove();
        setWidth(newWidth);
      }
    };

    const observer = new ResizeObserver(newWidth);
    observer.observe(containerDiv);
    return () => {
      observer.unobserve(containerDiv);
    };
  });

  useEffect(() => {
    if (width === null) return;
    const margin = 40;
    const container = containerRef.current as SVGSVGElement;
    const axis = axisRef.current as SVGGElement;
    // This is the height of the icon centers. It needs to be larger
    // than the maximal icon radius!
    const iconRadius = 15;
    const iconHoverRadius = 20;
    const heightIconCenters = iconHoverRadius;
    const axisHeight = heightIconCenters + iconHoverRadius;

    const xScale = d3
      .scaleLinear()
      .domain([0, videoDuration])
      .range([margin, width - margin]);

    const axisSVG = d3
      .axisBottom(xScale)
      .tickFormat(function (d: NumberValue) {
        return printTime(d.valueOf());
      })
      .ticks(5)
      .tickSizeInner(5)
      .tickSizeOuter(7);

    d3.select(axis)
      .call(axisSVG)
      .attr('transform', `translate(${0}, ${axisHeight})`)
      .style('font', '1em Open Sans');

    function onMouseEnter(event: any, d: any) {
      const radius = iconHoverRadius;
      d3.select(event.target)
        .transition()
        .duration(600)
        .ease(d3.easeElasticOut)
        .attr('width', 2 * radius)
        .attr('height', 2 * radius)
        .attr('x', xScale(d.timeInSeconds) - radius)
        .attr('y', heightIconCenters - radius);
    }
    function onMouseLeave(event: any, d: any) {
      const radius = iconRadius;
      d3.select(event.target)
        .transition()
        .duration(150)
        .ease(d3.easeSin)
        .attr('width', 2 * radius)
        .attr('height', 2 * radius)
        .attr('x', xScale(d.timeInSeconds) - radius)
        .attr('y', heightIconCenters - radius);
    }

    function onClick(event: any, d: Conversation) {
      showMessage(d);
    }

    const playIcon = `<svg viewBox="0 0 24 24">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
    </svg>`;

    const keyFunction = (c: any) => c.id;

    const circles = d3
      .select(container)
      .attr('viewBox', `0 0 ${width} 80`)
      .selectAll('.icons')
      .data(conversations, keyFunction);
    // .on("click", onClick)

    circles
      .enter()
      .append('svg')
      .attr('class', 'icons')
      .attr('width', iconRadius * 2)
      .attr('height', iconRadius * 2)
      .attr('stroke', 'currentColor')
      .attr('fill', 'white')
      .attr('x', (d: Conversation) => {
        return xScale(d.timeInSeconds) - iconRadius;
      })
      .attr('y', heightIconCenters - iconRadius)
      .on('mouseenter', onMouseEnter)
      .on('mouseleave', onMouseLeave)
      .html(playIcon)
      .on('click', onClick)
      .on('dblclick', (e, d) => jumpToMessage(d));
  });

  return (
    <div ref={containerDivRef}>
      <svg
        width="100%"
        height="auto"
        ref={containerRef}
        className={'fill-current text-gray-700 text-sm'}
      >
        <g ref={axisRef} />
      </svg>
    </div>
  );
};
