/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ConversationMessageCreateInput = {
    conversationId: string;
    filename?: string | null | undefined;
    text?: string | null | undefined;
};
export type MessageComposerMutationVariables = {
    input: ConversationMessageCreateInput;
};
export type MessageComposerMutationResponse = {
    readonly conversationMessageCreate: {
        readonly result: {
            readonly text: string | null;
            readonly source: string | null;
            readonly user: {
                readonly id: string;
                readonly name: string;
            };
        } | null;
        readonly successful: boolean;
        readonly messages: ReadonlyArray<{
            readonly code: string;
            readonly field: string | null;
            readonly message: string | null;
        } | null> | null;
    } | null;
};
export type MessageComposerMutation = {
    readonly response: MessageComposerMutationResponse;
    readonly variables: MessageComposerMutationVariables;
};



/*
mutation MessageComposerMutation(
  $input: ConversationMessageCreateInput!
) {
  conversationMessageCreate(input: $input) {
    result {
      text
      source
      user {
        id
        name
      }
      id
    }
    successful
    messages {
      code
      field
      message
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "source",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "PublicUser",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "successful",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationMessage",
  "kind": "LinkedField",
  "name": "messages",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MessageComposerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ConversationMessagePayload",
        "kind": "LinkedField",
        "name": "conversationMessageCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ConversationMessage",
            "kind": "LinkedField",
            "name": "result",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MessageComposerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ConversationMessagePayload",
        "kind": "LinkedField",
        "name": "conversationMessageCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ConversationMessage",
            "kind": "LinkedField",
            "name": "result",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8c8804978af7236bc658cfecd7d74d31",
    "id": null,
    "metadata": {},
    "name": "MessageComposerMutation",
    "operationKind": "mutation",
    "text": "mutation MessageComposerMutation(\n  $input: ConversationMessageCreateInput!\n) {\n  conversationMessageCreate(input: $input) {\n    result {\n      text\n      source\n      user {\n        id\n        name\n      }\n      id\n    }\n    successful\n    messages {\n      code\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '191cf49e3c9ea317bbaf73254936d763';
export default node;
