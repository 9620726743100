import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery, useMutation, useFragment } from 'react-relay/hooks';
import { RouteComponentProps } from 'react-router-dom';
import { PayloadError } from 'relay-runtime';
import {
  CourseEdit_CourseEditMutation,
  CourseEdit_CourseEditMutationResponse,
} from '../../__generated__/CourseEdit_CourseEditMutation.graphql';
import { CourseEdit_CourseQuery } from '../../__generated__/CourseEdit_CourseQuery.graphql';
import { CourseEdit_CourseUserList$key } from '../../__generated__/CourseEdit_CourseUserList.graphql';
import { FormSubmitButton, NavButton } from '../FormElements/Buttons';
import { Form, FormRow } from '../FormElements/Form';
import { FormHeading } from '../FormElements/FormHeading';
import {
  LabelledTextField,
  LabelledTextArea,
  LabelledReadonlyField,
} from '../FormElements/TextInput';
import {
  AbsintheValidationMessages,
  asyncValidateFormValues,
  ValidationMessage,
} from '../FormElements/ValidationRules';
import {
  Table,
  TableHead,
  TableHeadColumn,
  TableBody,
  TableRow,
  TableColumn,
  TableColumnLink,
} from '../TableComponents';
import { CourseVideoList } from '../CourseVideos/CourseVideoList';
import {
  Page,
  PageDescription,
  PageHeader,
  PageHeading,
  Section,
  SectionDescription,
  SectionHeading,
} from '../StructuringElements';
import { FormState } from '../Types';
import { MyLink } from '../FormElements/Link';
import { CourseDelete } from './CourseDelete';
import { CheckCircleIcon } from '@heroicons/react/outline';

type CourseEditParams = { id: string };
interface CourseEditProps extends RouteComponentProps<CourseEditParams> {}

type CourseEditFormValues = {
  name: string;
  description: string;
};

export const CourseEdit: FC<CourseEditProps> = (props) => {
  const [formState, setFormState] = useState(FormState.Idle);
  const [courseDeleted, setCourseDeleted] = useState(false);
  const [formMessage, setFormMessage] = useState('');
  const courseId = props.match.params.id;
  const data = useLazyLoadQuery<CourseEdit_CourseQuery>(
    graphql`
      query CourseEdit_CourseQuery($courseId: ID!) {
        courseById(id: $courseId) {
          name
          description
          accessCode
          ...CourseEdit_CourseUserList
          ...CourseVideoList_videoList
        }
      }
    `,
    { courseId: courseId },
    { fetchPolicy: 'store-or-network' }
  );

  const course = data.courseById;
  let defaultValues = {};
  if (course) {
    defaultValues = course;
  }

  const { register, handleSubmit, setError, errors } = useForm<CourseEditFormValues>({
    defaultValues: defaultValues,
  });

  const [commit] = useMutation<CourseEdit_CourseEditMutation>(graphql`
    mutation CourseEdit_CourseEditMutation($input: CourseUpdateInput!) {
      courseUpdate(input: $input) {
        result {
          id
          name
          description
        }
        successful
        messages {
          code
          field
          message
        }
      }
    }
  `);

  const onSubmit = (data: CourseEditFormValues) => {
    if (formState === FormState.Submitting) {
      return;
    }
    setFormState(FormState.Submitting);
    const formvalues = { id: courseId, ...data };
    commit({
      variables: { input: formvalues },
      onCompleted: (
        response: CourseEdit_CourseEditMutationResponse,
        errors: PayloadError[] | null
      ) => {
        const ret = response.courseUpdate;
        if (ret && !ret.successful && ret.messages) {
          const formValidationMessages = ret.messages as AbsintheValidationMessages<CourseEditFormValues>;
          asyncValidateFormValues(formValidationMessages, setError, setFormMessage);
          setFormState(FormState.Failure);
        } else {
          setFormState(FormState.Success);
          setFormMessage('Die Änderungen wurden gespeichert.');
        }
      },
    });
  };
  if (courseDeleted) {
    return (
      <Page>
        <PageHeader>
          <div className="flex items-center mb-2">
            <PageHeading>Kurs gelöscht</PageHeading>
            <CheckCircleIcon className="w-8 ml-4" />
          </div>
          <PageDescription>Der Kurs wurde gelöscht.</PageDescription>
        </PageHeader>
      </Page>
    );
  } else if (course) {
    return (
      <Page>
        <PageHeader>
          <PageHeading>Kurs bearbeiten</PageHeading>
          <PageDescription>
            Hier können Sie Videos zu einem Kurs hinzufügen oder die Kursdetails bearbeiten.
          </PageDescription>
        </PageHeader>
        <Section>
          <SectionHeading>Kursvideos</SectionHeading>
          <SectionDescription>
            Die Videos zu denen die Kursteilnehmer zugang haben.
          </SectionDescription>
          <div>
            <CourseVideoList course={course} editOption />
            <div className="flex">
              <div className="ml-auto mt-5 mr-5">
                <NavButton to={'/courses_edit/video_add/' + courseId}>Video hinzufügen</NavButton>
              </div>
            </div>
          </div>
        </Section>
        <Section>
          <SectionHeading>Kursteilnehmer</SectionHeading>
          <SectionDescription>
            Eine Liste der Personen, die in den Kurs eingetragen sind.
          </SectionDescription>
          <CourseUserList userList={course} courseId={courseId} />
        </Section>
        <Section>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormHeading
              formMessage={formMessage}
              heading="Kursdetails bearbeiten"
              formState={formState}
            />
            <LabelledTextField
              errors={errors.name?.message}
              label="Kursname"
              name="name"
              inputRef={register({
                required: {
                  value: true,
                  message: ValidationMessage('required', true),
                },
                minLength: {
                  value: 4,
                  message: ValidationMessage('minLength', 4),
                },
                maxLength: {
                  value: 63,
                  message: ValidationMessage('maxLength', 63),
                },
              })}
            />
            <LabelledTextArea
              errors={errors.description?.message}
              label="Kursbeschreibung"
              name="description"
              inputRef={register({
                maxLength: {
                  value: 10000,
                  message: ValidationMessage('maxLength', 10000),
                },
              })}
            />
            <LabelledReadonlyField label="Beitrittslink">
              {window.location.origin + '/courses/join/' + course.accessCode}
            </LabelledReadonlyField>
            <FormRow className="flex space-between content-between">
              <CourseDelete
                courseDeleted={() => setCourseDeleted(true)}
                courseId={courseId}
                setError={setError as any}
                setGlobalFormError={setFormMessage}
              />
              <div className="ml-auto">
                <FormSubmitButton
                  isSubmitting={formState === FormState.Submitting}
                  buttonLabel="Speichern"
                />
              </div>
            </FormRow>
          </Form>
        </Section>
      </Page>
    );
  } else {
    return <>Wir können den Kurs nicht finden.</>;
  }
};

interface CourseUserListProps {
  courseId: string;
  userList: CourseEdit_CourseUserList$key;
}

const CourseUserList: FC<CourseUserListProps> = (props) => {
  const data = useFragment<CourseEdit_CourseUserList$key>(
    graphql`
      fragment CourseEdit_CourseUserList on Course {
        courseUsers(first: 999) @connection(key: "CourseEdit_courseUsers") {
          edges {
            role
            node {
              id
              name
            }
          }
        }
      }
    `,
    props.userList
  );
  const userList = data.courseUsers;
  return (
    <Table>
      <TableHead>
        <TableHeadColumn>Name</TableHeadColumn>
        <TableHeadColumn>Rolle</TableHeadColumn>
        <TableHeadColumn>Nutzer entfernen</TableHeadColumn>
      </TableHead>
      <TableBody>
        {userList
          ? userList.edges.map((user) => (
              <TableRow key={user.node.id}>
                <TableColumn>{user.node.name}</TableColumn>
                <TableColumn>{user.role === 'admin' ? 'Gruppenadministrator' : ''}</TableColumn>
                <TableColumn>
                  {/* TODO NEXT */}
                  <MyLink
                    to={`/courses_edit/user_edit/${props.courseId}/${user.node.id}/${user.role}`}
                  >
                    Bearbeiten
                  </MyLink>
                </TableColumn>
              </TableRow>
            ))
          : null}
      </TableBody>
    </Table>
  );
};
