import { FC, useContext, useState } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import {
  AbsintheValidationMessages,
  asyncValidateFormValues,
} from '../FormElements/ValidationRules';
import {
  VideoDeleteInput,
  VideoDeleteMutation,
  VideoDeleteMutationResponse,
} from '../../__generated__/VideoDeleteMutation.graphql';
import { useMutation, useRelayEnvironment } from 'react-relay/hooks';
import { ConnectionHandler, PayloadError } from 'relay-runtime';
import { Button } from '../FormElements/Buttons';
import { useHistory } from 'react-router-dom';
import {
  CourseDeleteMutation,
  CourseDeleteMutationResponse,
} from '../../__generated__/CourseDeleteMutation.graphql';
import { AppContext } from '../../App';

type CourseDeleteProps = {
  courseId: string;
  setError: (err: string) => void;
  setGlobalFormError: (err: string) => void;
  courseDeleted: () => void;
};

export const CourseDelete: FC<CourseDeleteProps> = (props) => {
  // const environment = useRelayEnvironment().getStore();

  const [submittingState, setSubmittingState] = useState('idle');
  const userId = useContext(AppContext).userId;

  const { courseId, setError, setGlobalFormError } = props;

  const [commit] = useMutation<CourseDeleteMutation>(graphql`
    mutation CourseDeleteMutation($input: CourseDeleteInput!) {
      courseDelete(input: $input) {
        successful
        messages {
          code
          field
          message
        }
      }
    }
  `);

  const history = useHistory();

  const deleteCourse = () => {
    if (submittingState === 'submitting') {
      return;
    }
    // eslint-disable-next-line no-restricted-globals
    const r = confirm(
      'Wollen Sie den Kurs wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.'
    );
    if (!r) {
      return;
    }
    commit({
      variables: { input: { id: courseId } },
      onCompleted: (response: CourseDeleteMutationResponse, errors: PayloadError[] | null) => {
        const ret = response.courseDelete;
        if (ret && !ret.successful && ret.messages) {
          const formValidationMessages = ret.messages as AbsintheValidationMessages<VideoDeleteInput>;
          asyncValidateFormValues(formValidationMessages, setError, setGlobalFormError);
          setSubmittingState('idle');
        } else {
          setSubmittingState('success');
          props.courseDeleted();
        }
      },
      updater: (store) => {
        const parentRecord = store.get(userId);
        const connection =
          parentRecord && ConnectionHandler.getConnection(parentRecord, 'CoursePage_courses');
        connection && ConnectionHandler.deleteNode(connection, courseId);
      },
    });
  };

  return (
    <Button type={'button'} className="bg-red-400 hover:bg-red-500" onClick={deleteCourse}>
      Kurs löschen
    </Button>
  );
};
