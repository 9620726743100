import { CheckCircleIcon, CheckIcon, PlusCircleIcon } from '@heroicons/react/outline';
import { graphql } from 'babel-plugin-relay/macro';
import React from 'react';
import { useFragment, useLazyLoadQuery } from 'react-relay/hooks';
import { VideoListQuery } from '../../__generated__/VideoListQuery.graphql';
import { VideoList_video$key } from '../../__generated__/VideoList_video.graphql';
import { CourseVideoList } from '../CourseVideos/CourseVideoList';
import { ButtonLink, MyLink } from '../FormElements/Link';
import { Page, PageDescription, PageHeader, PageHeading, Section } from '../StructuringElements';
import {
  Table,
  TableBody,
  TableColumn,
  TableColumnLink,
  TableHead,
  TableHeadColumn,
  TableRow,
} from '../TableComponents';

type VideoListProps = {};

export const VideoList = (props: VideoListProps) => {
  const data = useLazyLoadQuery<VideoListQuery>(
    graphql`
      query VideoListQuery {
        me {
          videos(first: 999) @connection(key: "VideoList_videos") {
            edges {
              ...VideoList_video
              node {
                id
              }
            }
          }
        }
      }
    `,
    {},
    { fetchPolicy: 'store-or-network' }
  );
  //TODO NEXT: Make the query in VideoList reload on page load

  const videos = data.me.videos;
  return (
    <Page>
      <PageHeader>
        <PageHeading>Videoübersicht</PageHeading>
        <PageDescription>
          Sie sehen hier eine Übersicht aller Videos, die auf Vions hochgeladen wurden. Sie können
          Videos, die Sie hochgeladen haben unter "Bearbeiten" löschen und bearbeiten. Außerdem
          haben Sie die Möglichkeit neue Videos hochzuladen.
        </PageDescription>
      </PageHeader>
      <Section>
        <Table>
          <TableHead>
            <TableHeadColumn className="">Video</TableHeadColumn>
            <TableHeadColumn className="w-40">Status</TableHeadColumn>
            <TableHeadColumn className="w-40">
              <span className="sr-only">Bearbeiten</span>
            </TableHeadColumn>
          </TableHead>
          <TableBody>
            {videos
              ? videos.edges.map((video) => <VideoListItem video={video} key={video.node.id} />)
              : null}
          </TableBody>
        </Table>
        <div className="flex">
          <MyLink
            to="/videos/create"
            className="text-blue-600 flex items-center ml-auto mr-auto mt-5"
          >
            <PlusCircleIcon height="1em" className="mr-2" />
            Video hinzufügen
          </MyLink>
        </div>
      </Section>
    </Page>
  );
};

type VideoListItemProps = {
  video: VideoList_video$key;
};

const VideoListItem = (props: VideoListItemProps) => {
  const videoEdge = useFragment<VideoList_video$key>(
    graphql`
      fragment VideoList_video on UserVideosEdge {
        editable
        node {
          id
          name
          description
          state
        }
      }
    `,
    props.video
  );

  const state = videoEdge.node.state;
  let stateElem = <></>;
  if (state === 'CREATED') {
    stateElem = <>Optimieren</>;
  } else if (state === 'INITIAL') {
    stateElem = <>Hochladen</>;
  } else if (state === 'ERRORED') {
    stateElem = <>Fehler</>;
  } else if (state === 'READY') {
    stateElem = <></>;
  }

  return (
    <TableRow className="hover:bg-gray-100">
      <TableColumnLink className="truncate" to={`/videos/view/${videoEdge.node.id}`}>
        {videoEdge.node.name}
      </TableColumnLink>
      <TableColumn>{stateElem}</TableColumn>
      <TableColumn>
        {videoEdge.editable ? (
          <MyLink to={`/videos/edit/${videoEdge.node.id}`}>Bearbeiten</MyLink>
        ) : null}
      </TableColumn>
    </TableRow>
  );
};
