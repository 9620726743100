import { MicrophoneIcon } from '@heroicons/react/solid';
import React, { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import * as Recorder from 'opus-recorder';
import { graphql } from 'babel-plugin-relay/macro';

/* eslint import/no-webpack-loader-syntax: off */
// @ts-ignore
import encoderPath from '!!file-loader!opus-recorder/dist/encoderWorker.min.js';
import { useFragment } from 'react-relay/hooks';
import axios from 'axios';
import { AppContext } from '../../App';
import { Spinner } from '../Spinner';
import { VoiceMessageComposer_UploadVoiceCommentForm$key } from '../../__generated__/VoiceMessageComposer_UploadVoiceCommentForm.graphql';
import { VideoControls } from '../VideoPlayers/VideoPlayer';

type MessageCreateProps = {
  lineHeight: number;
  newVoiceMessage: (messageUrl: string, time: number) => void;
  videoControls: VideoControls;
  videoId: string;
  courseVideo: VoiceMessageComposer_UploadVoiceCommentForm$key;
};

enum Action {
  UserInitiatesRecording = 'USER INITIATES RECORDING',
  RecordingStarted = 'RECORDING STARTED',
  UserStopsRecording = 'USER STOPS RECORDING',
  RecordingStopped = 'RECORDING STOPPED',
  DataAvailable = 'DATA AVAILABLE',
}
const recorder = new Recorder({ encoderPath });

export const VoiceMessageComposerNewConversation = (props: MessageCreateProps) => {
  const userId = useContext(AppContext).userId;
  useEffect(() => {
    if (!Recorder.isRecordingSupported()) {
      alert(
        'Ihr Browser unterstützt keine Spracheingabe. Wenn Sie die Spracheingabefunktion nutzen wollen, installieren Sie bitte eine aktuelle Version eines Webbrowsers, zum Beispiel Firefox.'
      );
    }
  }, []);
  const { lineHeight, newVoiceMessage, videoControls, courseVideo } = props;

  const data = useFragment<VoiceMessageComposer_UploadVoiceCommentForm$key>(
    graphql`
      fragment VoiceMessageComposer_UploadVoiceCommentForm on CourseVideo {
        temporaryUploadForm {
          base64
        }
      }
    `,
    courseVideo
  );

  const [userInitiatedRecording, setUserInitiatedRecording] = useState(false);
  const [userStoppedRecording, setUserStoppedRecording] = useState(false);
  const [recorderStarted, setRecorderStarted] = useState(false);
  const [recorderStopped, setRecorderStopped] = useState(false);
  const [dataReceived, setDataReceived] = useState(false);
  const [saveData, setSaveData] = useState(true);
  const [recordingStartedAtTime, setRecordingStartedAtTime] = useState(-1);
  const [isVideoPlayingWhenStartedRecording, setIsVideoPlayingWhenStartedRecording] = useState(
    true
  );
  const [isUploading, setIsUploading] = useState(false);

  const setIdleState = () => {
    setUserInitiatedRecording(false);
    setUserStoppedRecording(false);
    setRecorderStarted(false);
    setRecorderStopped(false);
    setDataReceived(false);
    setSaveData(true);
    setRecordingStartedAtTime(-1);
    setIsVideoPlayingWhenStartedRecording(true);
    setIsUploading(false);
  };

  const applyAction = (a: Action) => {
    // console.log("Applying action ", a);
    if (a === Action.UserInitiatesRecording) {
      if (!userInitiatedRecording) {
        const videoTime = videoControls.getPosition();
        setIsVideoPlayingWhenStartedRecording(videoControls.isPlaying());
        videoControls.pauseVideo();
        setUserInitiatedRecording(true);
        setRecordingStartedAtTime(videoTime);
        recorder.start().then(
          () => {
            applyAction(Action.RecordingStarted);
          },
          (e: any) => {
            console.log('Error while starting recording ', e);
          }
        );
      }
    } else if (a === Action.RecordingStarted) {
      if (userStoppedRecording) {
        setSaveData(false);
        setRecorderStarted(true);
        //just in case this was not called by action.userstopsrecording
        recorder.stop().then(
          () => {
            applyAction(Action.RecordingStopped);
          },
          (e: any) => {
            console.log('Error while stopping recording ', e);
          }
        );
      } else {
        setRecorderStarted(true);
      }
    } else if (a === Action.UserStopsRecording) {
      if (userInitiatedRecording) {
        setUserStoppedRecording(true);
        setTimeout(() => {
          recorder.stop().then(() => {
            applyAction(Action.RecordingStopped);
          });
          if (isVideoPlayingWhenStartedRecording) {
            videoControls.playVideo();
          }
        }, 350);
      }
    } else if (a === Action.RecordingStopped) {
      setIdleState();
    }
  };

  const stopRecording = (e: MouseEvent) => {
    applyAction(Action.UserStopsRecording);
  };

  const buttonDefaultSize = lineHeight;

  useEffect(() => {
    document.addEventListener('mouseup', stopRecording);

    return function cleanup() {
      document.removeEventListener('mouseup', stopRecording);
    };
  });

  if (!data.temporaryUploadForm) {
    console.log(data);
    return <>Keine Erlaubnis zum hochladen bekommen.</>;
  }
  async function uploadVoiceMessage(blob: any) {
    let form = JSON.parse(atob(data.temporaryUploadForm?.base64 as any));

    const formData = new FormData();
    //actually not important, just need the filename to be unique
    //userId_datetime_randomnumber.ogg
    const fileName = `${userId}_${Date.now()}_${Math.floor(Math.random() * 9999999)}.ogg`;
    const file = new File([blob], fileName);
    // formData.append("ke1y", fileName);
    for (const formfield in form['form_body']) {
      formData.append(formfield, form['form_body'][formfield]);
    }
    formData.append('file', file);

    await axios({
      method: 'post',
      url: form.form_action,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });
    return fileName;
  }

  async function dataAvailable(typedArray: any) {
    setIsUploading(true);
    const dataBlob = new Blob([typedArray], { type: 'audio/ogg' });
    const fileName = await uploadVoiceMessage(dataBlob);
    setIsUploading(false);
    newVoiceMessage(fileName, recordingStartedAtTime);
  }

  recorder.ondataavailable = dataAvailable;

  const pulsating = {
    animate: { scale: [0.8, 1.6] },
    transition: { duration: 1, repeatType: 'reverse', repeat: Infinity },
  };
  const defaultButtonState = {
    animate: { scale: 1 },
    transition: { duration: 1 },
  };

  let recorderButtonState = {} as any;

  if (userInitiatedRecording) {
    if (userStoppedRecording) {
      recorderButtonState = defaultButtonState;
    } else {
      recorderButtonState = pulsating;
    }
  } else {
    recorderButtonState = defaultButtonState;
  }
  if (isUploading) {
    return <Spinner className="ml-4 h-6 w-6" />;
  } else {
    return (
      <motion.div
        onMouseDown={() => applyAction(Action.UserInitiatesRecording)}
        id="voiceRecorderButton"
        className="rounded-full bg-blue-200 p-2 flex items-center justify-center mr-5 ml-5 mt-1"
        style={{ height: buttonDefaultSize, width: buttonDefaultSize }}
        // animate={{scale: [1.2, 1.6]}}
        animate={recorderButtonState.animate}
        transition={recorderButtonState.transition}
      >
        <MicrophoneIcon style={{ stroke: '#0000', fill: 'black' }} />
      </motion.div>
    );
  }
};
