/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type InvitationCodes_invitationCodeList = {
    readonly id: string;
    readonly organisation: {
        readonly invitationCodes: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly creator: {
                        readonly id: string;
                    } | null;
                };
                readonly " $fragmentRefs": FragmentRefs<"InvitationCodes_InvitationCode">;
            }>;
        } | null;
    };
    readonly " $refType": "InvitationCodes_invitationCodeList";
};
export type InvitationCodes_invitationCodeList$data = InvitationCodes_invitationCodeList;
export type InvitationCodes_invitationCodeList$key = {
    readonly " $data"?: InvitationCodes_invitationCodeList$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"InvitationCodes_invitationCodeList">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "organisation",
          "invitationCodes"
        ]
      }
    ]
  },
  "name": "InvitationCodes_invitationCodeList",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Organisation",
      "kind": "LinkedField",
      "name": "organisation",
      "plural": false,
      "selections": [
        {
          "alias": "invitationCodes",
          "args": null,
          "concreteType": "OrganisationInvitationCodesConnection",
          "kind": "LinkedField",
          "name": "__InvitationCodes_invitationCodes_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrganisationInvitationCodesEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "InvitationCode",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PublicUser",
                      "kind": "LinkedField",
                      "name": "creator",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "InvitationCodes_InvitationCode"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();
(node as any).hash = '23aa0fe5dd5ee5caaccab36500f7361d';
export default node;
