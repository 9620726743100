import React, { useContext, useState } from 'react';
import { useLazyLoadQuery, useMutation } from 'react-relay/hooks';
import {
  VideoCreateInput,
  VideoCreateMutation,
  VideoCreateMutationResponse,
} from '../../__generated__/VideoCreateMutation.graphql';
import { graphql } from 'babel-plugin-relay/macro';
import { useForm } from 'react-hook-form';
import { ConnectionHandler, PayloadError } from 'relay-runtime';
import {
  AbsintheValidationMessages,
  asyncValidateFormValues,
  ValidationMessage,
} from '../FormElements/ValidationRules';
import { Form, FormRow } from '../FormElements/Form';
import { FormHeading } from '../FormElements/FormHeading';
import {
  LabelledFileUploadField,
  LabelledTextArea,
  LabelledTextField,
} from '../FormElements/TextInput';
import { FormSubmitButton } from '../FormElements/Buttons';
import { RouteComponentProps } from 'react-router-dom';
import { FormState } from '../Types';
import { LabelledListBoxField } from '../FormElements/ListBox';
import { parseIFrameString } from '../VideoPlayers/VideoPlayerPanopto';
import {
  VideoCreateMuxMutation,
  VideoCreateMuxMutationResponse,
} from '../../__generated__/VideoCreateMuxMutation.graphql';
import { AppContext } from '../../App';
import { LabelledSwitchField } from '../FormElements/Switch';
import axios from 'axios';
import { Page } from '../StructuringElements';
import { parseYoutubeLink } from '../VideoPlayers/VideoPlayerYoutube';
import { VideoCreate_OrganisationVideoTypesQuery } from '../../__generated__/VideoCreate_OrganisationVideoTypesQuery.graphql';

export type VideoCreateFormInput = {
  description?: string | null;
  name: string;
  public: boolean;
  //this needs to be any since it can be either string or file
  sourceData: any;
  sourceType: string;
};

interface VideoCreateProps extends RouteComponentProps<{}> {}

export function VideoCreate(props: VideoCreateProps) {
  //CourseCreateInput
  const [formState, setFormState] = useState(FormState.Idle);
  const [formMessage, setFormMessage] = useState(undefined as undefined | string);
  const {
    register,
    handleSubmit,
    setError,
    errors,
    reset,
    control,
    watch,
  } = useForm<VideoCreateFormInput>({
    defaultValues: { public: true },
  });
  const videoSourceType = watch('sourceType');
  const { userId } = useContext(AppContext);

  const videoHosterSettings = useLazyLoadQuery<VideoCreate_OrganisationVideoTypesQuery>(
    graphql`
      query VideoCreate_OrganisationVideoTypesQuery {
        me {
          organisation {
            videoHosterSettings {
              mux
              youtube
              file
              panopto
            }
          }
        }
      }
    `,
    {},
    { fetchPolicy: 'store-or-network' }
  ).me.organisation.videoHosterSettings;

  const [commitCreate] = useMutation<VideoCreateMutation>(graphql`
    mutation VideoCreateMutation($input: VideoCreateInput!) {
      videoCreate(input: $input) {
        result {
          id
          name
          description
          state
        }
        successful
        messages {
          code
          field
          message
        }
      }
    }
  `);
  // TODO NEXT: VideoCreateMUX Mutation needs to return a video with the url

  const [commitCreateMux] = useMutation<VideoCreateMuxMutation>(graphql`
    mutation VideoCreateMuxMutation($input: VideoCreateMuxInput!) {
      videoCreateMux(input: $input) {
        result {
          video {
            id
            name
            description
            state
          }
          uploadUrl
        }
        successful
        messages {
          code
          field
          message
        }
      }
    }
  `);

  const onSubmit = async (formData: VideoCreateFormInput) => {
    if (formState === FormState.Submitting) {
      return;
    }
    setFormState(FormState.Submitting);

    if (formData.sourceType !== 'mux') {
      if (formData.sourceType === 'panopto') {
        const parsed = parseIFrameString(formData.sourceData);
        if (parsed === null) {
          setError('sourceData', {
            type: 'Parsing',
            message:
              'Die eingegbenen Daten sind ungültig. Bitte verwenden Sie ein Panoptovideo oder wählen Sie eine andere Videoart aus.',
          });
          setFormState(FormState.Failure);
          return;
        }
      } else if (formData.sourceType === 'youtube') {
        const parsed = parseYoutubeLink(formData.sourceData);
        if (parsed === null) {
          setError('sourceData', {
            type: 'Parsing',
            message:
              'Der eingegebene Link ist ungültig. Bitte verwenden Sie einen Link zu einem Youtubevideo oder wählen Sie eine andere Videoart aus.',
          });
          setFormState(FormState.Failure);
          return;
        }
      }
      const newInput = { ...formData, sourceData: formData.sourceData.trim() };
      commitCreate({
        variables: { input: newInput },
        updater: (store) => {
          const newEntry = store.getRootField('videoCreate').getLinkedRecord('result');
          const parentRecord = store.get(userId);
          const connection =
            parentRecord && ConnectionHandler.getConnection(parentRecord, 'VideoList_videos');
          const edge =
            parentRecord &&
            connection &&
            ConnectionHandler.createEdge(store, connection, newEntry, 'UserVideosEdge');
          if (connection && edge) {
            ConnectionHandler.insertEdgeAfter(connection, edge);
          }
        },
        onCompleted: (response, errors) => {
          const ret = response.videoCreate;
          if (ret && !ret.successful && ret.messages) {
            const formValidationMessages = ret.messages as AbsintheValidationMessages<VideoCreateInput>;
            asyncValidateFormValues(formValidationMessages, setError, setFormMessage);
            setFormState(FormState.Failure);
          } else {
            setFormMessage('Das Video wurde hinzugefügt.');
            setFormState(FormState.Success);
            reset();
          }
        },
      });
    } else if (formData.sourceType === 'mux') {
      setFormMessage('Videoupload wird vorbereitet...');
      const input = {
        name: formData.name,
        description: formData.description,
        public: formData.public,
      };
      commitCreateMux({
        variables: { input: input },
        onCompleted: (response, errors) => {
          const ret = response.videoCreateMux;
          if (ret && !ret.successful && ret.messages) {
            const formValidationMessages = ret.messages as AbsintheValidationMessages<VideoCreateInput>;
            asyncValidateFormValues(formValidationMessages, setError, setFormMessage);
            setFormState(FormState.Failure);
          } else {
            setFormMessage('Video wird hochgeladen...');
            const file = formData.sourceData[0];
            const uploadUrl = ret!.result!.uploadUrl;
            try {
              axios
                .request({
                  method: 'PUT',
                  url: uploadUrl,
                  data: file,
                  headers: { 'content-type': file.type },
                  onUploadProgress: (p) =>
                    setFormMessage(
                      `Videouploadfortschritt: ${((p.loaded / p.total) * 100).toFixed(1)}%`
                    ),
                })
                .then((res) => {
                  console.log('Mux responded with ', res);
                  setFormState(FormState.Success);
                  setFormMessage('Das Hochladen des Videos ist nun abgeschlossen.');
                  reset();
                });
              setFormMessage(`Videouploadfortschritt: 0%`);
            } catch (error) {
              console.error('Error happened while uploading, ', error);
              setFormState(FormState.Failure);
              setFormMessage('Beim Hochladen des Videos ist ein Fehler aufgetreten.');
              setError('sourceData', {
                type: 'mux',
                message: 'An error happened while uploading the video: ' + error,
              });
            }
          }
        },
        updater: (store) => {
          const newEntry = store
            .getRootField('videoCreateMux')
            .getLinkedRecord('result')
            .getLinkedRecord('video');
          const parentRecord = store.get(userId);
          const connection =
            parentRecord && ConnectionHandler.getConnection(parentRecord, 'VideoList_videos');
          const edge =
            parentRecord &&
            connection &&
            ConnectionHandler.createEdge(store, connection, newEntry, 'UserVideosEdge');
          if (connection && edge) {
            ConnectionHandler.insertEdgeAfter(connection, edge);
          }
        },
      });
    }
  };

  const hosterOptions = Object.keys(videoHosterSettings)
    .filter(
      //@ts-ignore
      (val) => videoHosterSettings[val] === true
    )
    .map(hosterToFieldOption) as any[];

  let defaultValue = '';
  console.log(videoHosterSettings);
  console.log(hosterOptions);
  if ('mux' in hosterOptions) {
    defaultValue = 'mux';
  } else {
    defaultValue = hosterOptions[0].value;
  }

  return (
    <Page>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormHeading
          formState={formState}
          formMessage={formMessage}
          heading="Video zu vions hinzufügen"
          subCaption="Sie können hier ein Video zu Vions hinzufügen. Falls Sie das Video schon auf einer anderen Website hochgeladen haben, wählen Sie bei Video Typ MP4-Datei aus. Andernfalls, falls Sie eine Videodatei haben, können Sie auch Videos direkt hier bei Vions hochladen. Nachher können Sie das Video unter 'Kurse bearbeiten' zu einem Kurs hinzufügen."
        />
        {/* <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-64 w-64"></div> */}
        <LabelledTextField
          errors={errors.name?.message}
          label="Name"
          description="Unter welchem Namen soll das Video zu sehen sein?"
          name="name"
          inputRef={register({
            required: {
              value: true,
              message: ValidationMessage('required'),
            },
            minLength: { value: 4, message: ValidationMessage('minLength', 4) },
            maxLength: { value: 63, message: ValidationMessage('maxLength', 63) },
          })}
        />

        <LabelledTextArea
          errors={errors.description?.message}
          label="Videobeschreibung"
          description="Alle weiteren Details zum Video."
          name="description"
          inputRef={register({
            maxLength: {
              value: 10000,
              message: ValidationMessage('maxLength', 10000),
            },
          })}
        />
        <LabelledSwitchField
          name="public"
          label="Öffentlich"
          description="Sollen andere Lehrer Zugang zu diesem Video haben?"
          control={control}
        />
        <LabelledListBoxField
          errors={errors.sourceType?.message}
          name="sourceType"
          control={control}
          options={hosterOptions}
          defaultValue={defaultValue}
          label="Video Typ"
          description="Auf welche Weise ist das Video gespeichert?"
        />
        {videoSourceType === 'mux' || videoSourceType === undefined ? (
          <LabelledFileUploadField
            errors={errors.sourceData?.message}
            type="file"
            label="File"
            description="Der Ablageort des Videos."
            name="sourceData"
            inputRef={register({
              required: {
                value: true,
                message: ValidationMessage('required'),
              },
              maxLength: { value: 1000, message: ValidationMessage('maxLength', 1000) },
            })}
          />
        ) : (
          <LabelledTextField
            errors={errors.sourceData?.message}
            label="Video"
            description="Der Link des Videos."
            name="sourceData"
            inputRef={register({
              required: {
                value: true,
                message: ValidationMessage('required'),
              },
              maxLength: { value: 1000, message: ValidationMessage('maxLength', 1000) },
            })}
          />
        )}
        <FormRow>
          <FormSubmitButton
            isSubmitting={formState === FormState.Submitting}
            buttonLabel="Speichern"
          />
        </FormRow>
      </Form>
    </Page>
  );
}

function hosterToFieldOption(hoster: string) {
  if (hoster === 'youtube') {
    return { value: 'youtube', label: 'Youtube' };
  } else if (hoster === 'mux') {
    return { value: 'mux', label: 'Auf vions hochladen' };
  } else if (hoster === 'panopto') {
    return { value: 'panopto', label: 'Panopto' };
  } else if (hoster === 'file') {
    return { value: 'file', label: 'Sonstiger Link' };
  } else {
    console.error(`Got hoster '${hoster}, which is unknown.'`);
  }
}
