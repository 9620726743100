import React, { ReactNode } from 'react';
import { Spinner } from '../Spinner';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/outline';
import { SectionDescription, SectionHeading } from '../StructuringElements';
import { FormState } from '../Types';

type FormHeadingProps = {
  heading: string;
  subCaption?: string;
  formMessage?: string;
  formState: FormState;
};

export const FormHeading = ({ formMessage, heading, subCaption, formState }: FormHeadingProps) => {
  let icon: ReactNode;
  if (formState === FormState.Success) {
    icon = <CheckCircleIcon className="ml-4 h-6" />;
  } else if (formState === FormState.Failure) {
    icon = <XCircleIcon className="ml-4 h-6" />;
  } else if (formState === FormState.Submitting) {
    icon = <Spinner className="ml-4 h-6 w-6" />;
  } else {
    icon = null;
  }
  let formMessageClass = 'mt-1 max-w-2xl text-sm ';
  if (formState === FormState.Failure) {
    formMessageClass += 'text-red-500';
  } else if (formState === FormState.Success) {
    formMessageClass += 'text-green-500';
  } else if (formState === FormState.Submitting) {
    formMessageClass += 'text-yellow-500';
  } else {
    formMessageClass += 'text-gray-500';
  }

  return (
    <div className="mb-6">
      <div className="flex items-center mb-3">
        <SectionHeading>{heading}</SectionHeading>
        {icon}
      </div>
      <div className="">
        {formMessage ? <p className={formMessageClass}>{formMessage}</p> : null}
        <SectionDescription className="">{subCaption}</SectionDescription>
      </div>
    </div>
  );
};
