import { ReactComponent } from '*.svg';
import React, { ReactNode } from 'react';
import { string } from 'yup/lib/locale';
import { ButtonLink } from '../FormElements/Link';
import { printTime } from '../Helpers';
import {
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableHeadColumn,
  TableRow,
} from '../TableComponents';
import { Conversation } from '../Types';
import { ConversationDelete } from './ConversationDelete';
import { VideoCommentsTimeLine } from './VideoCommentsTimeLine';

type VideoCommentsTimeLineTableProps = {
  conversations: Conversation[];
  videoDuration: number;
  showMessage: (c: Conversation) => void;
  jumpToMessage: (c: Conversation) => void;
};

export const VideoCommentsTimeLineTable = (props: VideoCommentsTimeLineTableProps) => {
  const { showMessage, conversations, videoDuration, jumpToMessage } = props;
  // let conversationByUserId = {} as ConversationsIndexedByUser;
  let conversationsByUserId = new Map<
    string,
    { username: string; conversations: Conversation[] }
  >();
  conversations.forEach((conversation) => {
    const conv = conversation.conversationMessages.edges[0].node;
    const userId = conv.user.id;
    const username = conv.user.name;
    let conversationsByUser = conversationsByUserId.get(userId);
    if (conversationsByUser === undefined) {
      conversationsByUser = { username: username, conversations: [] };
    }
    conversationsByUser.conversations.push(conversation);
    conversationsByUserId.set(userId, conversationsByUser);
  });
  const sortedConversations = Array.from(conversationsByUserId.entries()).sort((a, b) =>
    a[1].username > b[1].username ? 1 : -1
  );

  // let tableRows = [] as ReactNode[];

  // sortedConversations
  // conversationsByUserId.forEach((convs, userId, m) => {
  //   const row = <ConversationTimeLineRow conversations={convs} key={userId} showMessage={showMessage} videoDuration={videoDuration}/>
  //   tableRows.push(row);
  // })

  return (
    <Table>
      <TableHead>
        <TableHeadColumn className="w-1/6">Nutzername</TableHeadColumn>
        <TableHeadColumn className="text-center">Kommentare</TableHeadColumn>
      </TableHead>
      <TableBody>
        {sortedConversations.map(([userId, { username, conversations }]) => (
          <ConversationTimeLineRow
            jumpToMessage={jumpToMessage}
            username={username}
            conversations={conversations}
            key={userId}
            showMessage={showMessage}
            videoDuration={videoDuration}
          />
        ))}
      </TableBody>
    </Table>
  );
};

type ConversationTimeLineRowProps = {
  conversations: Conversation[];
  videoDuration: number;
  showMessage: (c: Conversation) => void;
  username: string;
  jumpToMessage: (c: Conversation) => void;
};

const ConversationTimeLineRow = (props: ConversationTimeLineRowProps) => {
  const { username } = props;
  return (
    <TableRow className="hover:bg-gray-100">
      <TableColumn>{username}</TableColumn>
      <TableColumn>
        <VideoCommentsTimeLine {...props} />
      </TableColumn>
    </TableRow>
  );
};
