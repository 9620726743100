import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormSubmitButton } from '../FormElements/Buttons';
import { Form, FormRow } from '../FormElements/Form';
import { FormHeading } from '../FormElements/FormHeading';
import { LabelledTextField } from '../FormElements/TextInput';
import * as Yup from 'yup';
import { apiUrl } from '../../App';
import { FormState } from '../Types';

type ChangePasswordInput = {
  current_password: string;
  password: string;
  password_repeated: string;
};

const validationSchema = Yup.object().shape({
  current_password: Yup.string().required('Dieses Feld kann nicht leer bleiben.'),
  password: Yup.string()
    .min(6, 'Das Passwort muss mindestens ${min} Zeichen lang sein.')
    .max(63, 'Das Passwort kann maximal ${max} Zeichen lang sein')
    .required('Dieses Feld kann nicht leer bleiben.'),
  password_repeated: Yup.string()
    .oneOf([Yup.ref('password')], 'Die Passwörter stimmen nicht überein.')
    .required('Dieses Feld kann nicht leer bleiben.'),
});

export const ChangePassword = () => {
  const [formMessage, setFormMessage] = useState('');
  const [formState, setFormState] = useState(FormState.Idle);
  const { register, handleSubmit, errors } = useForm<ChangePasswordInput>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: ChangePasswordInput) => {
    if (formState === FormState.Submitting) {
      return;
    }
    setFormState(FormState.Submitting);
    const senddata = {
      current_password: data.current_password,
      password: data.password,
      action: 'update_password',
    };
    axios({
      method: 'put',
      url: apiUrl + '/api/users/settings',
      data: senddata,
      withCredentials: true,
    })
      .then((res) => {
        if (!res.data.successful) {
          setFormState(FormState.Failure);
          setFormMessage(res.data.message);
        } else {
          setFormState(FormState.Success);
          window.location.href = '/';
        }
      })
      .catch((e) => {
        setFormState(FormState.Failure);
        console.log('Error happened while submitting form', e);
      });
  };
  //Somehow it is sometimes buggy without this console.log...
  console.log(errors);
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormHeading
        formState={formState}
        formMessage={formMessage}
        heading="Passwort ändern"
        subCaption="Nach dem Ändern Ihres Passwortes müssen Sie sich neu einloggen."
      />
      <LabelledTextField
        errors={errors.current_password?.message}
        label="Aktuelles Passwort"
        name="current_password"
        type="password"
        inputRef={register()}
      />
      <LabelledTextField
        errors={errors.password?.message}
        label="Neues Passwort"
        name="password"
        type="password"
        inputRef={register()}
      />
      <LabelledTextField
        errors={errors.password_repeated?.message}
        label="Neues Passwort wiederholen"
        name="password_repeated"
        type="password"
        inputRef={register()}
      />
      <FormRow>
        <FormSubmitButton
          isSubmitting={formState === FormState.Submitting}
          buttonLabel="Passwort ändern"
        />
      </FormRow>
    </Form>
  );
};
