import axios from 'axios';
import { useEffect } from 'react';
import {
  Page,
  PageDescription,
  PageHeader,
  PageHeading,
  Section,
  SectionDescription,
  SectionHeading,
} from '../StructuringElements';

export const HomePage = () => {
  return (
    <Page>
      <PageHeader>
        <PageHeading>Vions Academy</PageHeading>
        <PageDescription>Willkommen bei vions.academy!</PageDescription>
      </PageHeader>
      <Section>
        <SectionHeading>Kontakt</SectionHeading>
        <PageDescription>
          Falls Sie Ideen zur Verbesserung der Website oder einfach in Kontakt treten möchten,
          freuen wir uns über Rückmeldungen an
        </PageDescription>
        <div className="text-gray-600 text-center">hallo@vions.academy</div>
      </Section>
      {/* <video>
        <source src="https://seafile.rlp.net/f/d2924b3dda864ad2a93e/?raw=1" />
      </video> */}
    </Page>
  );
};
