import { FC } from 'react';
import { useLazyLoadQuery } from 'react-relay/hooks';
import { RouteComponentProps } from 'react-router-dom';
import { CourseView_CourseQuery } from '../../__generated__/CourseView_CourseQuery.graphql';
import { graphql } from 'babel-plugin-relay/macro';
import { Page, PageDescription, PageHeader, PageHeading, Section } from '../StructuringElements';
import { CourseVideoList } from '../CourseVideos/CourseVideoList';
import React from 'react';

type CourseViewParams = { id: string };
interface CourseEditProps extends RouteComponentProps<CourseViewParams> {}

export const CourseView: FC<CourseEditProps> = (props) => {
  const courseId = props.match.params.id;
  const data = useLazyLoadQuery<CourseView_CourseQuery>(
    graphql`
      query CourseView_CourseQuery($courseId: ID!) {
        courseById(id: $courseId) {
          name
          description
          ...CourseVideoList_videoList
        }
      }
    `,
    { courseId: courseId },
    { fetchPolicy: 'store-or-network' }
  );

  const course = data.courseById;

  if (!course) {
    return <>{'Kurs nicht gefunden.'}</>;
  }

  return (
    <Page>
      <PageHeader>
        <PageHeading>{course.name}</PageHeading>
        <PageDescription>{course.description}</PageDescription>
      </PageHeader>
      <Section>
        <CourseVideoList course={course} />
      </Section>
    </Page>
  );
};
