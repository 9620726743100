import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonLink, MyLink } from '../FormElements/Link';
import { printTime } from '../Helpers';
import { Conversation } from '../Types';

type DisplayFirstMessageProps = {
  conversation: Conversation;
  videoJumpToPosition: (time: number) => void;
};

export const DisplayFirstMessage = (props: DisplayFirstMessageProps) => {
  const message = props.conversation.conversationMessages!.edges[0].node;
  const [isTruncated, setIsTruncated] = useState(true);
  const [isOverflow, setIsOverflown] = useState(false);
  const [divRef, setDivRef] = useState(null as HTMLDivElement | null);
  const history = useHistory();

  const checkForOverflow = () => {
    if (divRef === null) {
      return;
    }
    const node = divRef;
    setIsOverflown(node.scrollHeight > node.clientHeight || node.scrollWidth > node.clientWidth);
  };

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setDivRef(node);
    }
  }, []);

  useEffect(() => {
    checkForOverflow();
  }, [divRef, message]);

  const timeInSeconds = props.conversation.timeInSeconds;
  const time = printTime(timeInSeconds);
  const username = message.user.name;
  const text = message.text;
  const audio = message.source;
  return (
    <>
      {/* This example requires Tailwind CSS v2.0+ */}
      <ul className="ml-5 mr-5 grid grid-cols-1 gap-6">
        <li className="col-span-1 bg-gray-50 rounded-lg shadow-lg divide-y divide-gray-200">
          <div className="w-full h-40 flex items-center justify-between p-6 space-x-6">
            <div className={`flex-1 ${isTruncated ? 'truncate' : null}`}>
              <div className="flex items-center space-x-3">
                <h3 className={`text-gray-900 text-sm font-medium truncate`}>Von: {username}</h3>
              </div>
              <div className="flex items-center space-x-3">
                <h4 className={`text-gray-700 text-sm font-medium`}>
                  Zeit:{' '}
                  <ButtonLink
                    className="text-sm"
                    onClick={() => props.videoJumpToPosition(timeInSeconds)}
                  >
                    {time}
                  </ButtonLink>
                </h4>
              </div>

              {text ? (
                <div>
                  <p
                    ref={measuredRef}
                    className={`mt-1 text-gray-500 text-sm ${isTruncated ? 'truncate' : null}`}
                  >
                    {text}
                  </p>
                </div>
              ) : null}
              {audio ? <audio autoPlay className="mt-1" controls={true} src={audio} /> : null}
              {isOverflow || !isTruncated ? (
                <ButtonLink onClick={() => setIsTruncated(!isTruncated)}>
                  {isTruncated ? 'Mehr anzeigen' : 'Weniger Anzeigen'}
                </ButtonLink>
              ) : null}
              <div className="flex items-right">
                <div className="ml-auto">
                  <ButtonLink
                    onClick={() => history.push(`/conversations/${props.conversation.id}`)}
                  >
                    Antworten
                  </ButtonLink>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </>
  );
};
