/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type VoiceMessageComposer_UploadVoiceCommentForm = {
    readonly temporaryUploadForm: {
        readonly base64: string;
    } | null;
    readonly " $refType": "VoiceMessageComposer_UploadVoiceCommentForm";
};
export type VoiceMessageComposer_UploadVoiceCommentForm$data = VoiceMessageComposer_UploadVoiceCommentForm;
export type VoiceMessageComposer_UploadVoiceCommentForm$key = {
    readonly " $data"?: VoiceMessageComposer_UploadVoiceCommentForm$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"VoiceMessageComposer_UploadVoiceCommentForm">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VoiceMessageComposer_UploadVoiceCommentForm",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TemporaryUploadForm",
      "kind": "LinkedField",
      "name": "temporaryUploadForm",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "base64",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CourseVideo",
  "abstractKey": null
};
(node as any).hash = '8fa540d842fa80ea91acba6d0f31902d';
export default node;
