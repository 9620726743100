import React, { FC } from 'react';
import { VideoPlayerHTML } from '../VideoPlayers/VideoPlayerHTML';
import { VideoPlayerPanopto } from '../VideoPlayers/VideoPlayerPanopto';
import VideoJSPlayer from './VideoJSPlayer';
import { VideoPlayerYoutube } from './VideoPlayerYoutube';

export interface VideoControls {
  getPosition: () => number;
  setPosition: (to: number) => void;
  playVideo: () => void;
  pauseVideo: () => void;
  isPlaying: () => boolean;
  focusVideo: () => void;
  getVideoDuration: () => number;
}

// courseId is for now only needed to make the breadcrumbs work and have a link to the course ready
type Props = {
  sourceType: string;
  sourceData: string;
  setVideoControls: (vc: VideoControls) => void;
};

export const VideoPlayer: FC<Props> = (props) => {
  const { sourceType, sourceData, setVideoControls } = props;
  let videoPlayer = <></>;
  if (sourceType !== 'panopto') {
    videoPlayer = (
      <VideoPlayerYoutube
        sourceType={sourceType}
        sourceData={sourceData}
        setVideoControls={setVideoControls}
      />
    );
  } else if (sourceType === 'panopto') {
    videoPlayer = (
      <VideoPlayerPanopto sourceData={sourceData} setVideoControls={setVideoControls} />
    );
  } else {
    return <>Fehler: Videotyp wurde nicht erkannt</>;
  }
  return videoPlayer;
};
