import React, { useEffect, useState } from 'react';
import { RelayEnvironmentProvider } from 'react-relay/hooks';
import RelayEnvironment from './lib/createEnvironment';
import { MainPage } from './components/Pages/MainPage';
import './index.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { RegistrationPage } from './components/Pages/RegistrationPage';
import axios from 'axios';
import { LoginPage } from './components/Pages/LoginPage';
import { Loading } from './components/Loading';
import { ConfirmUser } from './components/UserSettings/ConfirmUser';
import { ForgotPasswordPage } from './components/UserSettings/ForgotPassword';
import { PasswordResetPage } from './components/UserSettings/PasswordReset';
import { OrganisationRegistrationPage } from './components/Pages/OrganisationRegistrationPage';

const { Suspense } = React;

const __DEV__ = process.env.NODE_ENV === 'development';

//IF THIS IS CHANGED ALSO CHANGE webSocketURL
export const apiUrl = __DEV__ ? 'http://localhost:4000' : 'https://backend.vions.academy';

export const AppContext = React.createContext({
  userId: 'not logged in',
  panoptoLoaded: false,
  isInTheatreMode: false,
  setIsInTheatreMode: null,
});

function App(props) {
  // const data = useLazyLoadQuery(AppUserQuery, {});

  return <MainPage />;
}

// The above component needs to know how to access the Relay environment, and we
// need to specify a fallback in case it suspends:
// - <RelayEnvironmentProvider> tells child components how to talk to the current
//   Relay Environment instance
// - <Suspense> specifies a fallback in case a child suspends.
function AppRoot(props) {
  const [me, setMe] = useState('loading');
  const [userId, setUserId] = useState(null);
  const [isInTheatreMode, setIsInTheatreMode] = useState(false);

  useEffect(() => {
    axios({
      method: 'get',
      url: apiUrl + '/api/users/me',
      withCredentials: true,
    })
      .then((res) => {
        if (!res.data.successful) {
          setMe('unauthorized');
          setUserId(null);
        } else {
          setMe('logged_in');
          setUserId(res.data.userId);
        }
      })
      .catch(console.log);
  }, []);

  if (me === 'loading') {
    return <Loading />;
  }

  return (
    <RelayEnvironmentProvider environment={RelayEnvironment}>
      <BrowserRouter>
        <Suspense fallback={'Vions lädt...'}>
          {me === 'logged_in' ? (
            <AppContext.Provider
              value={{
                userId: userId,
                isInTheatreMode: isInTheatreMode,
                setIsInTheatreMode: setIsInTheatreMode,
              }}
            >
              <App />
            </AppContext.Provider>
          ) : (
            <Switch>
              <Route path="/register">
                <RegistrationPage />
              </Route>
              <Route path="/register_organisation" component={OrganisationRegistrationPage} />
              <Route path="/confirm_user/:token" component={ConfirmUser} />
              <Route path="/forgot_password" component={ForgotPasswordPage} />
              <Route path="/reset_password/:token" component={PasswordResetPage} />
              <LoginPage />
            </Switch>
          )}
        </Suspense>
      </BrowserRouter>
    </RelayEnvironmentProvider>
  );
}

export default AppRoot;
