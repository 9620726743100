import React, { ComponentProps, FC } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

interface TableRowLinkProps extends ComponentProps<'tr'> {
  to: string;
}

export const TableRowLink: FC<TableRowLinkProps> = (props) => {
  const { children, ...otherProps } = props;
  const history = useHistory();
  const classes = 'hover:bg-gray-100 ' + (props.className ? props.className : '');

  return (
    <tr onClick={(e) => history.push(props.to)} className={classes}>
      {children}
    </tr>
  );
};

interface TableProps extends ComponentProps<'table'> {}

export const Table: FC<TableProps> = (props) => {
  const { children, ...otherProps } = props;

  return (
    <div className="overflow-hidden border-b border-gray-200">
      <table className="min-w-full w-full divide-y divide-gray-200 table-fixed" {...props}>
        {children}
      </table>
    </div>
  );
};

interface TableHeadProps extends ComponentProps<'thead'> {}

export const TableHead: FC<TableHeadProps> = (props) => {
  const { children, ...otherProps } = props;
  return (
    <thead className="bg-gray-50" {...otherProps}>
      <tr>{children}</tr>
    </thead>
  );
};

interface TableHeadColumnProps extends ComponentProps<'th'> {}

export const TableHeadColumn: FC<TableHeadColumnProps> = (props) => {
  const { children, className, ...otherProps } = props;
  let extraClasses = null;
  if (className) {
    extraClasses = className;
  }
  const classes =
    'px-6 h-12 text-left text-xs font-bold text-gray-500 uppercase tracking-wider ' + extraClasses;
  return (
    <th scope="col" className={classes} {...otherProps}>
      {children}
    </th>
  );
};

interface TableBodyProps extends ComponentProps<'tbody'> {}

export const TableBody: FC<TableBodyProps> = (props) => {
  const { children, className, ...otherProps } = props;
  let extraClasses = null;
  if (className) {
    extraClasses = className;
  }
  const classes = extraClasses + ' bg-white divide-y divide-gray-200';

  return (
    <tbody className={classes} {...otherProps}>
      {children}
    </tbody>
  );
};

interface TableRowProps extends ComponentProps<'tr'> {}

export const TableRow: FC<TableRowProps> = (props) => {
  const { children, className, ...otherProps } = props;
  let extraClasses = null;
  if (className) {
    extraClasses = className;
  }
  const classes = extraClasses + '';
  return (
    <tr className={classes} {...otherProps}>
      {children}
    </tr>
  );
};

interface TableColumnProps extends ComponentProps<'td'> {}

export const TableColumn: FC<TableColumnProps> = (props) => {
  const { children, className, ...otherProps } = props;
  let extraClasses = null;
  if (className) {
    extraClasses = className;
  }
  const classes = extraClasses + ' px-6 h-12 whitespace-nowrap text-sm font-medium text-gray-700';
  return (
    <td className={classes} {...otherProps}>
      {children}
    </td>
  );
};

interface TableColumnLinkProps extends TableColumnProps {
  to: string;
}

export const TableColumnLink: FC<TableColumnLinkProps> = (props) => {
  const { children, to, ...otherProps } = props;

  return (
    <TableColumn {...otherProps}>
      <NavLink to={to} className="h-full w-full block flex">
        <p className="px-6 mb-auto mt-auto">{children}</p>
      </NavLink>
    </TableColumn>
  );
};
