/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type VideoState = "CREATED" | "ERRORED" | "INITIAL" | "READY" | "%future added value";
export type VideoList_video = {
    readonly editable: boolean;
    readonly node: {
        readonly id: string;
        readonly name: string;
        readonly description: string | null;
        readonly state: VideoState;
    };
    readonly " $refType": "VideoList_video";
};
export type VideoList_video$data = VideoList_video;
export type VideoList_video$key = {
    readonly " $data"?: VideoList_video$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"VideoList_video">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VideoList_video",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "editable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Video",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "state",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserVideosEdge",
  "abstractKey": null
};
(node as any).hash = '87b137452a76d4cfdc601dd128ca0e1a';
export default node;
