import axios from 'axios';
import { motion } from 'framer-motion';
import React, { FunctionComponent, Suspense, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { apiUrl } from '../App';
import { Button } from './FormElements/Buttons';
import { Breadcrumbs } from './Navigation/Breadcrumbs';
import { DropDown } from './Navigation/DropDown';

type SearchBarHeaderProps = {
  username: string;
};

export const SearchBarHeader = (props: SearchBarHeaderProps) => {
  const history = useHistory();
  const logOut = () => {
    axios({
      method: 'post',
      url: apiUrl + '/api/users/log_out',
      withCredentials: true,
    })
      .then((res) => {
        window.location.href = '/';
        // history.push('/');
      })
      .catch(console.log);
  };
  return (
    <div className="relative z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 flex">
      <button className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden">
        <span className="sr-only">Open sidebar</span>
        {/* Heroicon name: outline/menu-alt-2 */}
        <svg
          className="h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 6h16M4 12h16M4 18h7"
          />
        </svg>
      </button>
      <Breadcrumbs />
      <div className="flex-1 flex justify-between px-4 md:px-0">
        <div className="flex-1 flex"></div>
        <div className="ml-4 flex items-center md:ml-6">
          {/* <div className="text-gray-500 font-medium text-sm"> <Button onClick={logOut}>{props.username}</Button></div>

          <button className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span className="sr-only">View notifications</span>
            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
            </svg>
          </button> */}
          <DropDown
            name="Account"
            menuItems={[
              { to: '/settings', text: 'Einstellungen' },
              { to: logOut, text: 'Ausloggen' },
            ]}
          />
        </div>
      </div>
    </div>
  );
};
