/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UserConversationList_conversation = {
    readonly node: {
        readonly id: string;
        readonly timeInSeconds: number;
        readonly conversationMessages: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly text: string | null;
                    readonly source: string | null;
                    readonly insertedAt: unknown | null;
                    readonly user: {
                        readonly id: string;
                        readonly name: string;
                    };
                };
            }>;
        } | null;
    };
    readonly " $refType": "UserConversationList_conversation";
};
export type UserConversationList_conversation$data = UserConversationList_conversation;
export type UserConversationList_conversation$key = {
    readonly " $data"?: UserConversationList_conversation$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"UserConversationList_conversation">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserConversationList_conversation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Conversation",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "timeInSeconds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 1
            }
          ],
          "concreteType": "ConversationMessagesConnection",
          "kind": "LinkedField",
          "name": "conversationMessages",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ConversationMessagesEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ConversationMessage",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "text",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "source",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "insertedAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PublicUser",
                      "kind": "LinkedField",
                      "name": "user",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "name",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "conversationMessages(first:1)"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserConversationsEdge",
  "abstractKey": null
};
})();
(node as any).hash = 'e2057193cbca99f3b764ca6be0113b38';
export default node;
