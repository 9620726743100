import React, { ComponentProps, FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Spinner } from '../Spinner';

interface ButtonProps extends ComponentProps<'button'> {
  attention?: boolean;
}

// export const OldButton : FC<ButtonProps> = (props) => {
//   const {children, className, ...otherProps} = props;
//   const classes = `py-2 px-5 bg-gray-100 rounded-3xl transform duration-200
//     text-center outline-none
//           hover:bg-gray-200 hover:scale-110
//           focus:outline-none ` + className;
//   return (
//     <button className={classes}
//           {...otherProps}>
//       {children}
//     </button>
//   )
// }

type NavButtonProps = {
  to: string;
};

export const NavButton: FC<NavButtonProps> = (props) => {
  const history = useHistory();

  const goto = () => {
    history.push(props.to);
  };

  return <Button onClick={goto}>{props.children}</Button>;
};

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  let { children, className, attention, type, ...otherProps } = props;
  //IF THIS IS CHANGED CHANGE IN PROPS.disabled too!
  let classes = `py-2 px-5 bg-gray-100 rounded-3xl transform duration-200 text-center outline-none hover:bg-gray-200 hover:scale-110 focus:outline-none `;
  if (attention) {
    classes = 'bg-blue-200 hover:bg-blue-300 ' + classes;
  }
  if (props.disabled) {
    classes = `py-2 px-5 bg-gray-100 rounded-3xl text-center outline-none text-gray-500`;
  }
  classes = className + ' ' + classes;

  type = type ? type : 'button';
  return (
    <button type={type} className={classes} ref={ref} {...otherProps}>
      {children}
    </button>
  );
});

type FormSubmitButtonProps = {
  buttonLabel: string;
  attention?: boolean;
  isSubmitting: boolean;
  submittingLabel?: string;
  disabled?: boolean;
};

export const FormSubmitButton = (props: FormSubmitButtonProps) => {
  const submittingLabel = props.submittingLabel ? props.submittingLabel : 'Lädt...';
  const buttonDisabled = props.disabled === true || props.isSubmitting;
  return (
    <Button type="submit" attention={props.attention} disabled={buttonDisabled}>
      {props.isSubmitting ? submittingLabel : props.buttonLabel}
    </Button>
  );
};

// export const AttentionButton : FC<ButtonProps> = (props) =>
//   <Button className="bg-blue-200 hover:bg-blue-300" {...props}/>

// export const AttentionButton = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
//   return <Button className="bg-blue-200 hover:bg-blue-300" {...props} />;
// })
