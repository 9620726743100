/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CoursePage_course = {
    readonly role: string;
    readonly node: {
        readonly id: string;
        readonly name: string;
    };
    readonly " $refType": "CoursePage_course";
};
export type CoursePage_course$data = CoursePage_course;
export type CoursePage_course$key = {
    readonly " $data"?: CoursePage_course$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"CoursePage_course">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CoursePage_course",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Course",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserCoursesEdge",
  "abstractKey": null
};
(node as any).hash = '96554f83284361530534f260e54e8d7d';
export default node;
