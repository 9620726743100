import React, { ComponentProps, FC } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

interface BlueLinkProps extends NavLinkProps {}

export const BlueLink: FC<BlueLinkProps> = (props) => {
  // const selectedClasses = "bg-gray-100 text-gray-900 group rounded-md py-2 px-2 flex items-center text-base font-light";
  // const nonSelectedClasses = "text-gray-600 hover:bg-gray-50 hover:text-gray-900 group rounded-md py-2 px-2 flex items-center text-base font-light";
  // const classes = props.selected ? selectedClasses : nonSelectedClasses;
  const { children, className, ...otherProps } = props;

  const classes = className + ' text-blue-500 hover:text-blue-300 hover:underline';

  return (
    <NavLink {...otherProps} className={classes}>
      {children}
    </NavLink>
  );
};

interface MyLinkProps extends NavLinkProps {}

export const MyLink: FC<MyLinkProps> = (props) => {
  // const selectedClasses = "bg-gray-100 text-gray-900 group rounded-md py-2 px-2 flex items-center text-base font-light";
  // const nonSelectedClasses = "text-gray-600 hover:bg-gray-50 hover:text-gray-900 group rounded-md py-2 px-2 flex items-center text-base font-light";
  // const classes = props.selected ? selectedClasses : nonSelectedClasses;
  const { children, className, ...otherProps } = props;

  const classes = className + ' text-gray-500 hover:text-blue-500 hover:underline';

  return (
    <NavLink {...otherProps} className={classes}>
      {children}
    </NavLink>
  );
};

interface ButtonLinkProps extends ComponentProps<'button'> {}

export const ButtonLink: FC<ButtonLinkProps> = (props) => {
  const { children, className, ...otherProps } = props;

  let classes =
    className +
    ' text-blue-500 background-transparent font-bold uppercase text-xs outline-none focus:outline-none mr-1 mb-1';

  return (
    <button
      {...otherProps}
      className={classes}
      type="button"
      style={{ transition: 'all .15s ease' }}
    >
      {children}
    </button>
  );
};
