/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type VoiceMessageComposerConversation_UploadVoiceCommentForm = {
    readonly temporaryUploadForm: {
        readonly base64: string;
    } | null;
    readonly " $refType": "VoiceMessageComposerConversation_UploadVoiceCommentForm";
};
export type VoiceMessageComposerConversation_UploadVoiceCommentForm$data = VoiceMessageComposerConversation_UploadVoiceCommentForm;
export type VoiceMessageComposerConversation_UploadVoiceCommentForm$key = {
    readonly " $data"?: VoiceMessageComposerConversation_UploadVoiceCommentForm$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"VoiceMessageComposerConversation_UploadVoiceCommentForm">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VoiceMessageComposerConversation_UploadVoiceCommentForm",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TemporaryUploadForm",
      "kind": "LinkedField",
      "name": "temporaryUploadForm",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "base64",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Conversation",
  "abstractKey": null
};
(node as any).hash = '84ead751e937b9a3c8033ae8b0fa7158';
export default node;
