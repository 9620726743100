import React from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { LabelledInputField, LabelledInputFieldProps } from './TextInput';
import { Control, Controller } from 'react-hook-form';

type ListBoxItem = {
  value: string | null;
  label: string;
};

type ListBoxProps = {
  name: string;
  control: Control;
  //this needs to be one of the values in the options array
  defaultValue: string;
  rules?: any;
  options: ListBoxItem[];
};

// interface Options {
//   [value: string] : string;
// }

type InnerListBoxProps = {
  onChange: (e: any) => void;
  // Value is key into options
  value: string;
  options: ListBoxItem[];
};

const InnerListBox = (props: InnerListBoxProps) => {
  const { onChange, options } = props;
  const selectedValue = options.find(({ value, label }) => value === props.value);
  const label = selectedValue?.label;
  return (
    <Listbox as="div" className="space-y-1" value={props.value} onChange={onChange}>
      {({ open }) => (
        <>
          <div className="relative">
            <span className="inline-block w-full rounded-md shadow-sm">
              <Listbox.Button className="cursor-default relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                <span className="block truncate">{label}</span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <svg
                    className="h-5 w-5 text-gray-400"
                    viewBox="0 0 20 20"
                    fill="none"
                    stroke="currentColor"
                  >
                    <path
                      d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Listbox.Button>
            </span>

            <Transition
              show={open}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="absolute mt-1 w-full rounded-md bg-white shadow-lg"
            >
              <Listbox.Options
                static
                className="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
              >
                {options.map(({ value, label }) => (
                  <Listbox.Option key={value} value={value}>
                    {({ selected, active }) => (
                      <div
                        className={`${
                          active ? 'text-white bg-blue-600' : 'text-gray-900'
                        } cursor-default select-none relative py-2 pl-8 pr-4`}
                      >
                        <span
                          className={`${selected ? 'font-semibold' : 'font-normal'} block truncate`}
                        >
                          {label}
                        </span>
                        {selected && (
                          <span
                            className={`${
                              active ? 'text-white' : 'text-blue-600'
                            } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                          >
                            <svg
                              className="h-5 w-5"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </span>
                        )}
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export function ListBoxField(props: ListBoxProps) {
  const { name, control, options, rules, defaultValue } = props;

  return (
    <div className="flex">
      <div className="w-full">
        <Controller
          control={control}
          name={name}
          rules={rules}
          defaultValue={defaultValue}
          render={({ onChange, value }, _) => (
            <InnerListBox onChange={onChange} value={value} options={options} />
          )}
        />
      </div>
    </div>
  );
}

export const LabelledListBoxField = (props: ListBoxProps & LabelledInputFieldProps) => {
  let { label, errors, description, ...fieldProps } = props;
  const field = <ListBoxField {...fieldProps} />;
  const labelledField = (
    <LabelledInputField
      for={props.name}
      inputField={field}
      label={label}
      description={description}
    />
  );
  return labelledField;
};
