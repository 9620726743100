import React, { ReactNode } from 'react';
import { ButtonLink } from '../FormElements/Link';
import {
  Table,
  TableHead,
  TableHeadColumn,
  TableBody,
  TableColumn,
  TableRow,
} from '../TableComponents';
import { graphql } from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay/hooks';
import { ConversationDelete } from './ConversationDelete';
import { printTime } from '../Helpers';
import { VideoComments_VideoComments } from '../../__generated__/VideoComments_VideoComments.graphql';
import { Conversation } from '../Types';

type VideoCommentsTableProps = {
  videoJumpToPosition: (to: number) => void;
  // videoComments: VideoCommentsTable_VideoCommentsList$key,
  videoId: string;
  conversations: Conversation[];
  showMessageWithFocus: (c: Conversation) => void;
};

export const VideoCommentsTable = (props: VideoCommentsTableProps) => {
  const { videoId, videoJumpToPosition, showMessageWithFocus } = props;
  const conversations = props.conversations.sort((c1, c2) => {
    return c1.timeInSeconds - c2.timeInSeconds;
  });

  return (
    <Table>
      <TableHead>
        <TableHeadColumn>Kommentar</TableHeadColumn>
        <TableHeadColumn className="w-1/12">Stelle</TableHeadColumn>
        <TableHeadColumn className="w-1/6">Nutzer</TableHeadColumn>
        <TableHeadColumn className="w-1/6">
          <span className="sr-only">Löschen</span>
        </TableHeadColumn>
      </TableHead>
      <TableBody>
        {conversations.map((conversation) => {
          return (
            <ConversationDisplayFirstMessage
              showMessageWithFocus={showMessageWithFocus}
              videoId={videoId}
              key={conversation.id}
              videoJumpToPosition={videoJumpToPosition}
              conversation={conversation}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};

type ConversationDisplayFirstMessageProps = {
  videoJumpToPosition: (to: number) => void;
  conversation: Conversation;
  videoId: string;
  showMessageWithFocus: (c: Conversation) => void;
};

const ConversationDisplayFirstMessage = (props: ConversationDisplayFirstMessageProps) => {
  const { videoJumpToPosition, conversation, videoId, showMessageWithFocus } = props;

  let column: ReactNode;
  if (conversation.conversationMessages) {
    const message = conversation.conversationMessages!.edges[0].node;
    column = (
      <TableColumn className="truncate w-1/2 whitespace-nowrap">
        {message.text ? (
          <p className="truncate" onClick={() => showMessageWithFocus(conversation)}>
            {message.text}
          </p>
        ) : (
          <span className="w-full" onClick={() => showMessageWithFocus(conversation)}>
            <audio preload="none" controls={true} src={message.source as any} />
          </span>
        )}
      </TableColumn>
    );
  } else {
    column = <TableColumn> Nachricht konnte nicht geladen werden</TableColumn>;
  }
  //NEXT TODO HIER. DIe nachrichten koennen iwie nicht geladen werden

  return (
    <TableRow className="hover:bg-gray-100">
      {column}
      <TableColumn>
        <ButtonLink
          onClick={(e) => {
            videoJumpToPosition(conversation.timeInSeconds);
          }}
        >
          {printTime(conversation.timeInSeconds)}
        </ButtonLink>
      </TableColumn>
      <TableColumn>{conversation.conversationMessages.edges[0].node.user.name}</TableColumn>
      <TableColumn>
        <ConversationDelete id={conversation.id} videoId={videoId} />
      </TableColumn>
    </TableRow>
  );
};
