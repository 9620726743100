import { FC } from 'react';

interface PageProps {}

export const Page: FC<PageProps> = (props) => {
  const { children, ...otherProps } = props;
  return <div className="space-y-20">{children}</div>;
};

interface PageHeaderProps {}

export const PageHeader: FC<PageHeaderProps> = (props) => {
  return <div className="space-y-5 pt-2">{props.children}</div>;
};

interface SectionProps {
  className?: string;
}

export const Section: FC<SectionProps> = (props) => {
  const classes = props.className + ' space-y-5 pt-2 border-top-2';
  return <div className={classes}>{props.children}</div>;
};

interface PageHeadingProps {}

export const PageHeading: FC<PageHeadingProps> = (props) => {
  return (
    <div className="border-gray-200">
      <h2 className="text-2xl leading-6 font-medium text-gray-900">{props.children}</h2>
    </div>
  );
};

interface PageDescriptionProps {}

export const PageDescription: FC<PageDescriptionProps> = (props) => {
  return <p className="mt-1 max-w-4xl text-md text-gray-500">{props.children}</p>;
};

interface SectionHeadingProps {}

export const SectionHeading: FC<SectionHeadingProps> = (props) => {
  return (
    <div className="border-gray-200">
      <h3 className="text-lg leading-6 font-medium text-gray-900">{props.children}</h3>
    </div>
  );
};

interface SectionDescriptionProps {
  className?: string;
}

export const SectionDescription: FC<SectionDescriptionProps> = (props) => {
  return (
    <p className={props.className + ' mt-1 max-w-4xl text-xs text-gray-500'}>{props.children}</p>
  );
};
