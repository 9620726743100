/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CourseEdit_CourseQueryVariables = {
    courseId: string;
};
export type CourseEdit_CourseQueryResponse = {
    readonly courseById: {
        readonly name: string;
        readonly description: string | null;
        readonly accessCode: string | null;
        readonly " $fragmentRefs": FragmentRefs<"CourseEdit_CourseUserList" | "CourseVideoList_videoList">;
    } | null;
};
export type CourseEdit_CourseQuery = {
    readonly response: CourseEdit_CourseQueryResponse;
    readonly variables: CourseEdit_CourseQueryVariables;
};



/*
query CourseEdit_CourseQuery(
  $courseId: ID!
) {
  courseById(id: $courseId) {
    name
    description
    accessCode
    ...CourseEdit_CourseUserList
    ...CourseVideoList_videoList
    id
  }
}

fragment CourseEdit_CourseUserList on Course {
  courseUsers(first: 999) {
    edges {
      role
      node {
        id
        name
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment CourseVideoList_video on CourseVideosEdge {
  node {
    id
    name
  }
}

fragment CourseVideoList_videoList on Course {
  id
  courseVideos(first: 999) {
    edges {
      ...CourseVideoList_video
      node {
        id
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "courseId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "courseId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accessCode",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 999
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  (v6/*: any*/),
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CourseEdit_CourseQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Course",
        "kind": "LinkedField",
        "name": "courseById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CourseEdit_CourseUserList"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CourseVideoList_videoList"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CourseEdit_CourseQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Course",
        "kind": "LinkedField",
        "name": "courseById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "CourseUsersConnection",
            "kind": "LinkedField",
            "name": "courseUsers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CourseUsersEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "role",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": "courseUsers(first:999)"
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "CourseEdit_courseUsers",
            "kind": "LinkedHandle",
            "name": "courseUsers"
          },
          (v6/*: any*/),
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "CourseVideosConnection",
            "kind": "LinkedField",
            "name": "courseVideos",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CourseVideosEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CourseVideo",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": "courseVideos(first:999)"
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "VideoList_courseVideos",
            "kind": "LinkedHandle",
            "name": "courseVideos"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0f72a3c2a54c963070efb32a498f9650",
    "id": null,
    "metadata": {},
    "name": "CourseEdit_CourseQuery",
    "operationKind": "query",
    "text": "query CourseEdit_CourseQuery(\n  $courseId: ID!\n) {\n  courseById(id: $courseId) {\n    name\n    description\n    accessCode\n    ...CourseEdit_CourseUserList\n    ...CourseVideoList_videoList\n    id\n  }\n}\n\nfragment CourseEdit_CourseUserList on Course {\n  courseUsers(first: 999) {\n    edges {\n      role\n      node {\n        id\n        name\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment CourseVideoList_video on CourseVideosEdge {\n  node {\n    id\n    name\n  }\n}\n\nfragment CourseVideoList_videoList on Course {\n  id\n  courseVideos(first: 999) {\n    edges {\n      ...CourseVideoList_video\n      node {\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '489f17daed0e7dbd2d2d230ba2bb0606';
export default node;
