/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CourseView_CourseQueryVariables = {
    courseId: string;
};
export type CourseView_CourseQueryResponse = {
    readonly courseById: {
        readonly name: string;
        readonly description: string | null;
        readonly " $fragmentRefs": FragmentRefs<"CourseVideoList_videoList">;
    } | null;
};
export type CourseView_CourseQuery = {
    readonly response: CourseView_CourseQueryResponse;
    readonly variables: CourseView_CourseQueryVariables;
};



/*
query CourseView_CourseQuery(
  $courseId: ID!
) {
  courseById(id: $courseId) {
    name
    description
    ...CourseVideoList_videoList
    id
  }
}

fragment CourseVideoList_video on CourseVideosEdge {
  node {
    id
    name
  }
}

fragment CourseVideoList_videoList on Course {
  id
  courseVideos(first: 999) {
    edges {
      ...CourseVideoList_video
      node {
        id
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "courseId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "courseId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 999
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CourseView_CourseQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Course",
        "kind": "LinkedField",
        "name": "courseById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CourseVideoList_videoList"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CourseView_CourseQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Course",
        "kind": "LinkedField",
        "name": "courseById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "CourseVideosConnection",
            "kind": "LinkedField",
            "name": "courseVideos",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CourseVideosEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CourseVideo",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "courseVideos(first:999)"
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "VideoList_courseVideos",
            "kind": "LinkedHandle",
            "name": "courseVideos"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5add7857a9442d3d5b78ab80be10390a",
    "id": null,
    "metadata": {},
    "name": "CourseView_CourseQuery",
    "operationKind": "query",
    "text": "query CourseView_CourseQuery(\n  $courseId: ID!\n) {\n  courseById(id: $courseId) {\n    name\n    description\n    ...CourseVideoList_videoList\n    id\n  }\n}\n\nfragment CourseVideoList_video on CourseVideosEdge {\n  node {\n    id\n    name\n  }\n}\n\nfragment CourseVideoList_videoList on Course {\n  id\n  courseVideos(first: 999) {\n    edges {\n      ...CourseVideoList_video\n      node {\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '0a983e1fcb9085765c0163902a7f582d';
export default node;
