/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type InvitationCodes_InvitationCode = {
    readonly node: {
        readonly id: string;
        readonly invitationCode: string;
        readonly role: string;
        readonly isActive: boolean;
        readonly name: string | null;
    };
    readonly " $refType": "InvitationCodes_InvitationCode";
};
export type InvitationCodes_InvitationCode$data = InvitationCodes_InvitationCode;
export type InvitationCodes_InvitationCode$key = {
    readonly " $data"?: InvitationCodes_InvitationCode$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"InvitationCodes_InvitationCode">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InvitationCodes_InvitationCode",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "InvitationCode",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invitationCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isActive",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrganisationInvitationCodesEdge",
  "abstractKey": null
};
(node as any).hash = '0c7e8138ccc19b9c87edbbea1620ba96';
export default node;
