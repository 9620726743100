import React, { useRef } from 'react';
import { TextField } from '../FormElements/TextInput';
import { VoiceMessageComposerNewConversation } from './VoiceMessageComposer';
import { Button } from '../FormElements/Buttons';
import { VoiceMessageComposer_UploadVoiceCommentForm$key } from '../../__generated__/VoiceMessageComposer_UploadVoiceCommentForm.graphql';
import { VideoControls } from '../VideoPlayers/VideoPlayer';

type ConversationComposerProps = {
  lineHeight: number;
  videoId: string;
  newVoiceMessage: (filename: string, time: number) => void;
  newTextMessage: (text: string, time: number) => void;
  videoControls: VideoControls;
  courseVideo: VoiceMessageComposer_UploadVoiceCommentForm$key;
};

export const ConversationComposer = (props: ConversationComposerProps) => {
  const {
    lineHeight,
    videoControls,
    newVoiceMessage,
    newTextMessage,
    videoId,
    courseVideo,
  } = props;

  return (
    <div className="flex gap-3 content-evenly">
      <TextMessageComposerNewConversation
        videoControls={videoControls}
        newTextMessage={newTextMessage}
      />
      <VoiceMessageComposerNewConversation
        courseVideo={courseVideo}
        lineHeight={lineHeight}
        newVoiceMessage={newVoiceMessage}
        videoControls={videoControls}
        videoId={videoId}
      />
    </div>
  );
};

type TextMessageComposerNewConversationProps = {
  newTextMessage: (message: string, time: number) => void;
  videoControls: VideoControls;
};

const TextMessageComposerNewConversation = (props: TextMessageComposerNewConversationProps) => {
  const textBoxRef = useRef<HTMLInputElement>(null);
  const sendMessage = () => {
    if (!textBoxRef.current) return;
    const text = textBoxRef.current.value;
    textBoxRef.current.value = '';
    props.newTextMessage(text, props.videoControls.getPosition());
  };

  function keyPress(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      sendMessage();
    }
  }

  return (
    <div className="flex w-full gap-3">
      <TextField
        name="text-message"
        extraClasses="flex-1"
        type="text"
        placeholder="Tippen Sie Ihre Nachricht hier ein oder nutzen sie den Sprach-Rekorder rechts."
        inputRef={textBoxRef}
        onKeyDown={keyPress}
      />
      <Button className="ml-auto" onClick={sendMessage}>
        Abschicken
      </Button>
    </div>
  );
};
