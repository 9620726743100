/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DisplayConversation_message = {
    readonly text: string | null;
    readonly source: string | null;
    readonly user: {
        readonly id: string;
        readonly name: string;
    };
    readonly " $refType": "DisplayConversation_message";
};
export type DisplayConversation_message$data = DisplayConversation_message;
export type DisplayConversation_message$key = {
    readonly " $data"?: DisplayConversation_message$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"DisplayConversation_message">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DisplayConversation_message",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "source",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PublicUser",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ConversationMessage",
  "abstractKey": null
};
(node as any).hash = '22f31fdcf0b5de3c6c25519962fa6be7';
export default node;
