/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CourseDeleteInput = {
    id: string;
};
export type CourseDeleteMutationVariables = {
    input: CourseDeleteInput;
};
export type CourseDeleteMutationResponse = {
    readonly courseDelete: {
        readonly successful: boolean;
        readonly messages: ReadonlyArray<{
            readonly code: string;
            readonly field: string | null;
            readonly message: string | null;
        } | null> | null;
    } | null;
};
export type CourseDeleteMutation = {
    readonly response: CourseDeleteMutationResponse;
    readonly variables: CourseDeleteMutationVariables;
};



/*
mutation CourseDeleteMutation(
  $input: CourseDeleteInput!
) {
  courseDelete(input: $input) {
    successful
    messages {
      code
      field
      message
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CourseDeletePayload",
    "kind": "LinkedField",
    "name": "courseDelete",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "successful",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationMessage",
        "kind": "LinkedField",
        "name": "messages",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CourseDeleteMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CourseDeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2e881f4086286920ccc214b57073e105",
    "id": null,
    "metadata": {},
    "name": "CourseDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation CourseDeleteMutation(\n  $input: CourseDeleteInput!\n) {\n  courseDelete(input: $input) {\n    successful\n    messages {\n      code\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'edd6c374e7025690a19252f7b8c7baf3';
export default node;
