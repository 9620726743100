/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type InvitationCodesQueryVariables = {};
export type InvitationCodesQueryResponse = {
    readonly me: {
        readonly organisation: {
            readonly id: string;
        };
        readonly " $fragmentRefs": FragmentRefs<"InvitationCodes_invitationCodeList">;
    };
};
export type InvitationCodesQuery = {
    readonly response: InvitationCodesQueryResponse;
    readonly variables: InvitationCodesQueryVariables;
};



/*
query InvitationCodesQuery {
  me {
    ...InvitationCodes_invitationCodeList
    organisation {
      id
    }
    id
  }
}

fragment InvitationCodes_InvitationCode on OrganisationInvitationCodesEdge {
  node {
    id
    invitationCode
    role
    isActive
    name
  }
}

fragment InvitationCodes_invitationCodeList on User {
  id
  organisation {
    invitationCodes(first: 999) {
      edges {
        node {
          id
          creator {
            id
          }
          __typename
        }
        ...InvitationCodes_InvitationCode
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 999
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "InvitationCodesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organisation",
            "kind": "LinkedField",
            "name": "organisation",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "InvitationCodes_invitationCodeList"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "InvitationCodesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organisation",
            "kind": "LinkedField",
            "name": "organisation",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "OrganisationInvitationCodesConnection",
                "kind": "LinkedField",
                "name": "invitationCodes",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganisationInvitationCodesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InvitationCode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PublicUser",
                            "kind": "LinkedField",
                            "name": "creator",
                            "plural": false,
                            "selections": (v1/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "invitationCode",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "role",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isActive",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "invitationCodes(first:999)"
              },
              {
                "alias": null,
                "args": (v2/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "InvitationCodes_invitationCodes",
                "kind": "LinkedHandle",
                "name": "invitationCodes"
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d78633ce5e8c791927c151c51a8fa7fd",
    "id": null,
    "metadata": {},
    "name": "InvitationCodesQuery",
    "operationKind": "query",
    "text": "query InvitationCodesQuery {\n  me {\n    ...InvitationCodes_invitationCodeList\n    organisation {\n      id\n    }\n    id\n  }\n}\n\nfragment InvitationCodes_InvitationCode on OrganisationInvitationCodesEdge {\n  node {\n    id\n    invitationCode\n    role\n    isActive\n    name\n  }\n}\n\nfragment InvitationCodes_invitationCodeList on User {\n  id\n  organisation {\n    invitationCodes(first: 999) {\n      edges {\n        node {\n          id\n          creator {\n            id\n          }\n          __typename\n        }\n        ...InvitationCodes_InvitationCode\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '2e5e9688811fb2ac381584b76616c520';
export default node;
