import { Link, useLocation } from 'react-router-dom';

type BreadcrumbsLink = {
  text: string;
  to: string;
};

export const Breadcrumbs = () => {
  const path = useLocation().pathname;
  let breadcrumbs = [] as BreadcrumbsLink[];
  if (/^\/courses$/.test(path)) {
    breadcrumbs = [{ to: '/courses', text: 'Kursübersicht' }];
  } else if (/^\/courses\//.test(path)) {
    breadcrumbs = [
      { to: '/courses', text: 'Kursübersicht' },
      { to: path, text: 'Kurs' },
    ];
  } else if (/^\/course_videos\//.test(path)) {
    const url_parts = path.split('/');
    const new_url_parts = [url_parts[0], 'courses', url_parts[2]];
    breadcrumbs = [
      { to: '/courses', text: 'Kursübersicht' },
      { to: new_url_parts.join('/'), text: 'Kurs' },
      { to: path, text: 'Video' },
    ];
  } else if (/^\/videos\/view\//.test(path)) {
    breadcrumbs = [
      { to: '/videos', text: 'Videoübersicht' },
      { to: path, text: 'Video' },
    ];
  } else if (/^\/videos\/create/.test(path)) {
    breadcrumbs = [
      { to: '/videos', text: 'Videoübersicht' },
      { to: path, text: 'Video hinzufügen' },
    ];
  } else if (/^\/videos\/edit/.test(path)) {
    breadcrumbs = [
      { to: '/videos', text: 'Videoübersicht' },
      { to: path, text: 'Video editieren' },
    ];
  } else if (/^\/videos/.test(path)) {
    breadcrumbs = [{ to: path, text: 'Videoübersicht' }];
  } else if (/^\/invitation_codes$/.test(path)) {
    breadcrumbs = [{ to: '/invitation_codes', text: 'Einladungslinks' }];
  } else if (/^\/invitation_codes\/edit\//.test(path)) {
    breadcrumbs = [
      { to: '/invitation_codes', text: 'Einladungslinks' },
      { to: path, text: 'Bearbeiten' },
    ];
  } else if (/^\/courses_edit$/.test(path)) {
    breadcrumbs = [{ to: '/courses_edit', text: 'Kursadministration' }];
  } else if (/^\/courses_edit\/user_edit/.test(path)) {
    const url_parts = path.split('/');
    const new_url_parts = [url_parts[0], url_parts[1], url_parts[3]];
    const course_edit_url = new_url_parts.join('/');
    breadcrumbs = [
      { to: '/courses_edit', text: 'Kursadministration' },
      { to: course_edit_url, text: 'Kurs bearbeiten' },
      { to: path, text: 'Kursmitgliedschaft bearbeiten' },
    ];
  } else if (/^\/courses_edit\/video_create/.test(path)) {
    // get course edit path
    const url_parts = path.split('/');
    const new_url_parts = [url_parts[0], url_parts[1], url_parts[3]];
    const course_edit_url = new_url_parts.join('/');
    breadcrumbs = [
      { to: '/courses_edit', text: 'Kursadministration' },
      { to: course_edit_url, text: 'Kurs bearbeiten' },
      { to: path, text: 'Video hinzufügen' },
    ];
  } else if (/^\/courses_edit\/video_edit/.test(path)) {
    // get course edit path
    const url_parts = path.split('/');
    const new_url_parts = [url_parts[0], url_parts[1], url_parts[3]];
    const course_edit_url = new_url_parts.join('/');
    breadcrumbs = [
      { to: '/courses_edit', text: 'Kursadministration' },
      { to: course_edit_url, text: 'Kurs bearbeiten' },
      { to: path, text: 'Video bearbeiten' },
    ];
  } else if (/^\/courses_edit\//.test(path)) {
    breadcrumbs = [
      { to: '/courses_edit', text: 'Kursadministration' },
      { to: path, text: 'Kurs bearbeiten' },
    ];
  } else if (/^\/settings$/.test(path)) {
    breadcrumbs = [{ to: '/settings', text: 'Einstellungen' }];
  } else if (/^\/conversations$/.test(path)) {
    breadcrumbs = [{ to: '/conversations', text: 'Unterhaltungen' }];
  } else if (/^\/conversations/.test(path)) {
    breadcrumbs = [
      { to: '/conversations', text: 'Unterhaltungsübersicht' },
      { to: path, text: 'Unterhaltung' },
    ];
  } else {
    breadcrumbs = [];
  }

  if (breadcrumbs.length > 0) {
    document.title = `${breadcrumbs[breadcrumbs.length - 1].text} | vions`;
  }

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        <li>
          <div>
            <Link to="/" className="text-gray-400 hover:text-gray-500">
              {/* Heroicon name: solid/home */}
              <svg
                className="flex-shrink-0 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
              </svg>
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {breadcrumbs.map((breadcrump) => (
          <BreadcrumbsItem key={breadcrump.to} to={breadcrump.to} text={breadcrump.text} />
        ))}
      </ol>
    </nav>
  );
};

const BreadcrumbsItem = (props: BreadcrumbsLink) => {
  return (
    <li>
      <div className="flex items-center">
        {/* Heroicon name: solid/chevron-right */}
        <svg
          className="flex-shrink-0 h-5 w-5 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          />
        </svg>
        <Link
          to={props.to}
          aria-current="page"
          className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
        >
          {props.text}
        </Link>
      </div>
    </li>
  );
};
