import React, { useMemo, useState } from 'react';
import { useFragment, useMutation, useSubscription } from 'react-relay/hooks';
import {
  ConversationCreateInput,
  VideoCommentsConversationCreateMutation,
  VideoCommentsConversationCreateMutationResponse,
} from '../../__generated__/VideoCommentsConversationCreateMutation.graphql';
import { ConversationComposer } from './ConversationComposer';
import { VideoCommentsTable } from './VideoCommentsTable';
import { graphql } from 'babel-plugin-relay/macro';
import { ConnectionHandler, GraphQLSubscriptionConfig, PayloadError } from 'relay-runtime';
import { VideoComments_VideoComments$key } from '../../__generated__/VideoComments_VideoComments.graphql';
import { Conversation } from '../Types';
import { DisplayFirstMessage } from './DisplayFirstMessage';
import { VideoCommentsTimeLineTable } from './VideoCommentsTimeLineTable';
import { Tab, Tabs } from '../Navigation/Tabs';
import { Section, SectionHeading } from '../StructuringElements';
import { VideoControls } from '../VideoPlayers/VideoPlayer';
import { VideoCommentsConversationsSubscription } from '../../__generated__/VideoCommentsConversationsSubscription.graphql';

export enum MessageType {
  Voice = 'VOICE MESSAGE',
  Text = 'TEXT MESSAGE',
}

export type VoiceMessage = {
  messageType: MessageType.Voice;
  messageUrl: string;
  // time is the time the video was at when the message was composed
};

export type TextMessage = {
  messageType: MessageType.Text;
  textMessage: string;
  // time is the time the video was at when the message was composed
};

export type ChatMessage = VoiceMessage | TextMessage;

export type ConversationStart = {
  // These are shown on the video page. We only need the first message to display it
  time: number;
  firstMessage: ChatMessage;
};

type VideoCommentsProps = {
  videoId: string;
  courseVideo: VideoComments_VideoComments$key;
  videoControls: VideoControls;
};

const subscriptionGraphql = graphql`
  subscription VideoCommentsConversationsSubscription(
    $input: NewConversationCourseVideoSubscribeInput!
  ) {
    newConversationCourseVideo(input: $input) {
      id
      timeInSeconds
      conversationMessages(first: 1) {
        edges {
          node {
            user {
              name
            }
            text
            source
          }
        }
      }
    }
  }
`;

export const VideoComments = (props: VideoCommentsProps) => {
  const { videoId, videoControls } = props;
  const [displayedFirstMessage, setDisplayedFirstMessage] = useState(null as Conversation | null);
  const [commit] = useMutation<VideoCommentsConversationCreateMutation>(graphql`
    mutation VideoCommentsConversationCreateMutation($input: ConversationCreateInput!) {
      conversationCreate(input: $input) {
        result {
          id
          timeInSeconds
          conversationMessages(first: 1) {
            edges {
              node {
                user {
                  name
                }
                text
                source
              }
            }
          }
        }
        successful
        messages {
          code
          field
          message
        }
      }
    }
  `);

  //props.courseVideo (the query thing) is DIFFERENT to this courseVideo (the fragment thing)
  const courseVideo = useFragment<VideoComments_VideoComments$key>(
    graphql`
      fragment VideoComments_VideoComments on CourseVideo {
        ...VoiceMessageComposer_UploadVoiceCommentForm
        courseVideoConversations(first: 9999)
          @connection(key: "videoComments_courseVideoConversations") {
          edges {
            node {
              id
              timeInSeconds
              conversationMessages(first: 1) {
                edges {
                  node {
                    text
                    source
                    user {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    props.courseVideo
  );

  const config = useMemo(() => {
    return {
      variables: { input: { courseVideoId: videoId } },
      subscription: subscriptionGraphql,
      updater: (store) => {
        const conversationRecord = store.get(videoId);

        // Get connection record
        const connectionRecord = ConnectionHandler.getConnection(
          conversationRecord!,
          'videoComments_courseVideoConversations'
        );

        // Get the payload returned from the server
        const payload = store.getRootField('newConversationCourseVideo');

        // Get the edge inside the payload
        // Build edge for adding to the connection
        // const newEdge = ConnectionHandler.buildConnectionEdge(
        //   store,
        //   connectionRecord!,
        //   payload,
        // );
        const newEdge = ConnectionHandler.createEdge(
          store,
          connectionRecord!,
          payload,
          'CourseVideoConversationsEdge'
        );

        // Add edge to the end of the connection
        newEdge && ConnectionHandler.insertEdgeAfter(connectionRecord!, newEdge);
      },
      onCompleted: () => {} /* Subscription established */,
      onError: (error) => {
        console.error('Got error while subscribing, ', error);
      } /* Subscription errored */,
      onNext: (response) => {
        console.log('Got from subscription ', response);
      } /* Subscription payload received */,
    } as GraphQLSubscriptionConfig<VideoCommentsConversationsSubscription>;
  }, [videoId]);
  useSubscription<VideoCommentsConversationsSubscription>(config);

  const conversations = courseVideo.courseVideoConversations!.edges.map(
    (c) => c.node
  ) as Conversation[];

  const newConversation = (input: ConversationCreateInput) => {
    // const newVideoComments = videoComments.concat(comment);
    // setVideoComments(newVideoComments);
    commit({
      variables: { input },
      onCompleted: (
        response: VideoCommentsConversationCreateMutationResponse,
        errors: PayloadError[] | null
      ) => {
        const ret = response.conversationCreate;
        if (ret && !ret.successful && ret.messages) {
          console.log('Error while uploading message to the server ', ret);
        }
      },
      // Commented out to not double the work with the subscription
      // updater: (store) => {
      //   const newEntry = store.getRootField('conversationCreate').getLinkedRecord('result');
      //   const parentRecord = store.get(videoId);
      //   const connection = parentRecord && ConnectionHandler.getConnection(parentRecord, 'videoComments_courseVideoConversations');
      //   const edge =
      //     connection &&
      //     ConnectionHandler.createEdge(store, connection, newEntry, 'CourseVideoConversationsEdge');
      //   if (connection && edge) {
      //     ConnectionHandler.insertEdgeAfter(connection, edge);
      //   }
      // },
    });
  };

  const newVoiceMesage = (filename: string, time: number) => {
    const input = {
      timeInSeconds: time,
      courseVideoId: videoId,
      filename: filename,
    };
    newConversation(input);
  };

  const newTextMessage = (message: string, time: number) => {
    const input = {
      timeInSeconds: time,
      courseVideoId: videoId,
      text: message,
    };
    newConversation(input);
  };

  const showMessage = (c: Conversation) => {
    setDisplayedFirstMessage(c);
  };

  const showMessageWithFocus = (c: Conversation) => {
    showMessage(c);
    videoControls.focusVideo();
  };

  const jumpToMessage = (c: Conversation) => {
    videoControls.setPosition(c.timeInSeconds);
  };
  const tabs = [
    {
      render: () => (
        <VideoCommentsTable
          showMessageWithFocus={showMessageWithFocus}
          conversations={conversations}
          videoId={videoId}
          videoJumpToPosition={videoControls.setPosition}
        />
      ),
      name: 'Listenansicht',
      id: 'VideoCommentsTable',
    },
    {
      render: () => (
        <VideoCommentsTimeLineTable
          jumpToMessage={jumpToMessage}
          conversations={conversations}
          videoDuration={videoControls.getVideoDuration()}
          showMessage={showMessage}
        />
      ),
      name: 'Zeitleistenansicht',
      id: 'VideoCommentsTimeLineTable',
    },
  ] as Tab[];

  return (
    <>
      <ConversationComposer
        courseVideo={courseVideo}
        lineHeight={35}
        videoControls={videoControls}
        newVoiceMessage={newVoiceMesage}
        newTextMessage={newTextMessage}
        videoId={videoId}
      />
      {displayedFirstMessage ? (
        <DisplayFirstMessage
          conversation={displayedFirstMessage}
          videoJumpToPosition={videoControls.setPosition}
        />
      ) : null}
      {/* {videoDuration === null ?
      <Spinner className="w-8"/> :
      <VideoCommentsTimeLine conversations={conversations} videoId={videoId} 
          width={300} videoJumpToPosition={videoJumpToPosition} videoDuration={videoDuration}
          showMessage={showMessage}
        />
      } */}
      <Section className="pt-10">
        <SectionHeading>Kommentarübersicht</SectionHeading>
        <Tabs defaultActiveTabId={'VideoCommentsTable'} tabs={tabs} />
      </Section>
    </>
  );
};
