/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CourseVideoList_videoList = {
    readonly id: string;
    readonly courseVideos: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
            };
            readonly " $fragmentRefs": FragmentRefs<"CourseVideoList_video">;
        }>;
    } | null;
    readonly " $refType": "CourseVideoList_videoList";
};
export type CourseVideoList_videoList$data = CourseVideoList_videoList;
export type CourseVideoList_videoList$key = {
    readonly " $data"?: CourseVideoList_videoList$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"CourseVideoList_videoList">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "courseVideos"
        ]
      }
    ]
  },
  "name": "CourseVideoList_videoList",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "courseVideos",
      "args": null,
      "concreteType": "CourseVideosConnection",
      "kind": "LinkedField",
      "name": "__VideoList_courseVideos_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CourseVideosEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CourseVideo",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CourseVideoList_video"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Course",
  "abstractKey": null
};
})();
(node as any).hash = 'a26deab363ce50079929021a62ff5839';
export default node;
