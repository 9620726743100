import { useCallback, useEffect, useState } from 'react';
import { VideoControls } from './VideoPlayer';
import Plyr from 'plyr';
import 'plyr/dist/plyr';
import Hls from 'hls.js';

declare var EmbedApi: any;
declare var PlayerState: any;

export function parseYoutubeLink(str: string) {
  let match = str.match(/youtu.be\/(?<id>[^?&]+)/);
  if (!match) {
    match = str.match(/youtube.com\/watch\?v=(?<id>[^?&]+)/);
    if (!match) {
      return null;
    }
  }
  const groups = match.groups;
  if (!groups) {
    return null;
  }
  if (groups['id'] === '') {
    return null;
  }
  return groups['id'];
}

type Props = {
  setVideoControls: (vc: VideoControls) => void;
  sourceData: string;
  sourceType: string;
};

export const VideoPlayerYoutube = (props: Props) => {
  const { sourceData } = props;
  const [plyr, setPlyr] = useState(null as Plyr | null);
  // const [videoControls, setVideoControls] = useState(null as VideoControls | null);

  useEffect(() => {
    return () => {
      if (plyr !== null) {
        plyr.destroy();
      }
    };
  });

  const onRefChangeVideo = useCallback((video: HTMLVideoElement | null) => {
    if (video) {
      if (props.sourceType === 'mux') {
        if (video.canPlayType('application/vnd.apple.mpegurl')) {
          video.src = props.sourceData;
        } else if (Hls.isSupported()) {
          let hls = new Hls();
          hls.loadSource(props.sourceData);
          hls.attachMedia(video);
        } else {
          alert('This browser does not support playing this video. Please update your browser.');
          return;
        }
      } else {
        video.src = props.sourceData;
      }

      const player = new Plyr(video);
      const controls = {
        getPosition: () => player.currentTime,
        setPosition: (to: number) => {
          player.currentTime = to;
        },
        playVideo: () => player.play(),
        pauseVideo: () => player.pause(),
        isPlaying: () => player.playing,
        focusVideo: () => video.focus(),
        getVideoDuration: () => player.duration,
      };
      //I don't know if this event fires before the Hls.Events.MANIFEST_PARSED. Could be a problem if it does. https://codepen.io/noikiy/pen/dVYJER
      player.on('ready', (e) => {
        props.setVideoControls(controls);
      });
    }
  }, []);

  const onRefChangeDiv = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      const player = new Plyr(node);
      const controls = {
        getPosition: () => player.currentTime,
        setPosition: (to: number) => {
          player.currentTime = to;
        },
        playVideo: () => player.play(),
        pauseVideo: () => player.pause(),
        isPlaying: () => player.playing,
        focusVideo: () => node.focus(),
        getVideoDuration: () => player.duration,
      };
      player.on('ready', (e) => {
        props.setVideoControls(controls);
      });
      // TODO: Here memory leaks could happen, I am not cleaning up the player
      // somehow with the following line it stops working
      // setPlyr(player);
    }
  }, []);

  if (props.sourceType === 'youtube') {
    const id = parseYoutubeLink(sourceData)!;
    return (
      <div className="plyr__video-embed" id="player" ref={onRefChangeDiv}>
        <iframe
          // style={{ position: 'static' }}
          src={`https://www.youtube.com/embed/${id}?origin=https://plyr.io&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1`}
          allowFullScreen
          allowTransparency
          allow="autoplay"
        ></iframe>
      </div>
    );
  } else {
    return (
      <video id="player" playsInline controls ref={onRefChangeVideo}>
        {/* <source src={props.sourceData} /> */}
      </video>
    );
  }
};
