import React, { FunctionComponent, ReactComponentElement, ReactNode, useContext } from 'react';
import { Link, NavLink, Route, useParams } from 'react-router-dom';
import {
  VideoCameraIcon,
  BookOpenIcon,
  AdjustmentsIcon,
  UserAddIcon,
  AcademicCapIcon,
  PlayIcon,
  ChatAlt2Icon,
} from '@heroicons/react/outline';
import logo from '../../images/logo.png';
import { UserContext } from '../Pages/MainPage';

interface StaticSideBarProps {
  Icon?: (props: React.ComponentProps<'svg'>) => JSX.Element;
  linkText: string;
  selected?: boolean;
  linkTarget: SiteNames;
}

type SiteNames = '/courses' | '/videos' | '/invitation_codes' | '/courses_edit' | '/conversations';

const StaticSideBarLink = (props: StaticSideBarProps) => {
  const selectedClasses =
    'bg-gray-100 text-gray-900 group rounded-md py-2 px-2 flex items-center text-base font-light';
  const nonSelectedClasses =
    'text-gray-600 hover:bg-gray-50 hover:text-gray-900 group rounded-md py-2 px-2 flex items-center text-base font-light';
  const classes = props.selected ? selectedClasses : nonSelectedClasses;
  const { Icon } = props;

  return (
    <NavLink to={props.linkTarget} className={nonSelectedClasses} activeClassName={selectedClasses}>
      {Icon && <Icon height="30px" />}
      <p className="pl-3">{props.linkText}</p>
    </NavLink>
  );
};

interface StaticSideBarSectionProps {
  label: string;
}

const StaticSideBarSection = (props: StaticSideBarSectionProps) => {
  const classes = 'mt-8 group rounded-md py-2 px-2 flex items-center text-base font-md';
  return (
    <span>
      <span className={classes}>{props.label}</span>
      <hr />
    </span>
  );
};

export const StaticSideBar = () => {
  const role = useContext(UserContext).role;
  let adminLink = null;
  let teacherLink = null;
  let participantLink = null;
  if (role === 'admin') {
    adminLink = (
      <>
        <StaticSideBarSection label="Administratorbereich" />
        <StaticSideBarLink
          Icon={UserAddIcon}
          linkText="Nutzer hinzufügen"
          linkTarget="/invitation_codes"
        />
      </>
    );
  }
  if (role === 'teaching_staff' || role === 'admin') {
    teacherLink = (
      <>
        <StaticSideBarSection label="Lehrerbereich" />
        <StaticSideBarLink
          Icon={AcademicCapIcon}
          linkText="Kurse bearbeiten"
          linkTarget="/courses_edit"
        />
        <StaticSideBarLink Icon={PlayIcon} linkText="Videos hochladen" linkTarget="/videos" />
      </>
    );
  }

  if (adminLink || teacherLink) {
    participantLink = (
      <>
        <StaticSideBarSection label="Schülerbereich" />
        <StaticSideBarLink Icon={BookOpenIcon} linkText="Kurse" linkTarget="/courses" />
        <StaticSideBarLink Icon={ChatAlt2Icon} linkText="Nachrichten" linkTarget="/conversations" />
      </>
    );
  } else {
    participantLink = (
      <>
        <StaticSideBarLink Icon={BookOpenIcon} linkText="Kurse" linkTarget="/courses" />
        <StaticSideBarLink Icon={ChatAlt2Icon} linkText="Nachrichten" linkTarget="/conversations" />
      </>
    );
  }

  return (
    <div className="hidden md:flex md:flex-shrink-0">
      <div className="w-64 flex flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="border-r border-gray-200 pt-5 pb-4 flex flex-col flex-grow overflow-y-auto">
          <div className="flex-shrink-0 px-4 flex items-center">
            {/* <img className="h-8 w-auto" src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg" alt="Workflow" /> */}
            <img className="h-8 ml-auto mr-auto" src={logo} alt="vions" />
          </div>
          <div className="flex-grow mt-5 flex flex-col">
            <nav className="flex-1 bg-white px-2 space-y-1">
              {/* Current: "bg-gray-100 text-gray-900", Default: "text-gray-600 hover:bg-gray-50 hover:text-gray-900" */}
              {adminLink}
              {teacherLink}
              {participantLink}
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};
