import { useCallback, useEffect, useState } from 'react';
import { VideoControls } from './VideoPlayer';
// import { PanoptoVideoState } from "./PanoptoVideoState";

declare var EmbedApi: any;
declare var PlayerState: any;

export type PanoptoVideoConfig = {
  host: string;
  id: string;
  width: number;
  height: number;
};

export function parseIFrameString(str: string) {
  const tester = /^<iframe .{0,}src=\"https:\/\/(?<host>.+)\/Panopto\/.+id=(?<id>[^&\"]+).*\".{1,}height=\"(?<height>[0-9]+)\".{1,}width=\"(?<width>[0-9]+)\".{0,}><\/iframe>$/;
  const match = str.match(tester);
  if (!match) {
    return null;
  }
  const groups = match.groups;
  if (!groups) {
    return null;
  }
  if (
    groups['host'] === '' ||
    groups['id'] === '' ||
    groups['width'] === '' ||
    groups['height'] === ''
  ) {
    return null;
  }
  return {
    host: groups['host'],
    id: groups['id'],
    width: parseInt(groups['width']),
    height: parseInt(groups['height']),
  } as PanoptoVideoConfig;
}

const panoptoIFrameId = 'panopto-player';

export class PanoptoVideo {
  stateInitialized: boolean;
  isVideoMuted: boolean;
  currentTime: number;
  duration: number;
  speed: number;
  isPaused: boolean;
  isFinished: boolean;
  embedApi: any;
  divElement: HTMLDivElement;
  aspectRatio: number;
  host: string;
  id: string;

  getPosition: () => number;
  setPosition: (to: number) => void;
  playVideo: () => void;
  pauseVideo: () => void;
  isPlaying: () => boolean;
  focusVideo: () => void;
  getVideoDuration: () => number;
  setWidth: () => void;

  constructor(
    setVideoControls: (vc: VideoControls) => void,
    divElement: HTMLDivElement,
    config: PanoptoVideoConfig
  ) {
    //TODO: Embed API registers an event listener which may hinder GC
    this.divElement = divElement;
    this.stateInitialized = false;
    this.isVideoMuted = false;
    this.currentTime = -1;
    this.duration = -1;
    this.speed = -1;
    this.isPaused = false;
    this.isFinished = false;
    this.aspectRatio = config.height / config.width;
    this.host = config.host;
    this.id = config.id;
    this.embedApi = new EmbedApi(panoptoIFrameId, {
      //This is the URL of your Panopto site
      serverName: this.host,
      sessionId: this.id,
      videoParams: {
        // Optional parameters
        //interactivity parameter controls if the user sees table of contents, discussions, notes, & in-video search
        interactivity: 'none',
        showtitle: 'false',
      },
      events: {
        onIframeReady: () => this.embedApi.loadVideo(),
        onReady: () => setVideoControls(this),
        // "onStateChange": () => check("State change"),
      },
    });

    this.getPosition = () => {
      return this.embedApi.getCurrentTime();
    };

    this.setPosition = (to: number) => {
      this.embedApi.seekTo(to);
    };
    this.playVideo = () => {
      this.embedApi.playVideo();
    };

    this.pauseVideo = () => {
      this.embedApi.pauseVideo();
    };

    this.focusVideo = () => {
      this.divElement.focus();
    };

    this.isPlaying = () => {
      const state = this.embedApi.getState();
      return state === PlayerState.Paused || state === PlayerState.Ended;
    };

    this.getVideoDuration = () => {
      return this.embedApi.getDuration();
    };

    this.setWidth = () => {
      const divWidth = this.divElement.clientWidth;
      this.embedApi.iframe.width = divWidth;
      this.embedApi.iframe.height = divWidth * this.aspectRatio;
    };

    this.setWidth();
  }
}

type Props = {
  setVideoControls: (vc: VideoControls) => void;
  sourceData: string;
};

export const VideoPlayerPanopto = (props: Props) => {
  const { sourceData } = props;
  const [videoControls, setVideoControls] = useState(null as VideoControls | null);

  const onRefChange = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      const parsed = parseIFrameString(sourceData);
      const controls = new PanoptoVideo(setVideoControls, node, parsed!);
      setVideoControls(controls);
      props.setVideoControls(controls);
    }
  }, []);

  useEffect(() => {
    if (videoControls !== null) {
      (videoControls as PanoptoVideo).setWidth();
    }
  });

  return <div id="panopto-player" className="w-full" ref={onRefChange} />;
};
