import { FC, ReactNode, useState } from 'react';
import { ButtonLink } from '../FormElements/Link';

export type Tab = {
  render: () => ReactNode;
  name: string;
  id: string;
};

type TabsProps = {
  defaultActiveTabId: string;
  tabs: Tab[];
};

export const Tabs: FC<TabsProps> = (props) => {
  const { defaultActiveTabId, tabs } = props;
  const [activeTabId, setActiveTabId] = useState(defaultActiveTabId);
  const activeTab = tabs.find((tab) => tab.id === activeTabId) as Tab;
  return (
    <div>
      <div>
        {/* TODO: Mobile */}
        {/* <div className="sm:hidden">
      <label htmlFor="tabs" className="sr-only">Select a tab</label>
      <select id="tabs" name="tabs" className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
        <option>My Account</option>
        <option>Company</option>
        <option selected>Team Members</option>
        <option>Billing</option>
      </select>
    </div> */}
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => {
                if (tab.id === activeTabId) {
                  return (
                    <ButtonLink
                      onClick={() => setActiveTabId(tab.id)}
                      className="border-indigo-500 text-indigo-600 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                      aria-current="page"
                      key={tab.id}
                    >
                      {tab.name}
                    </ButtonLink>
                  );
                } else {
                  return (
                    <ButtonLink
                      onClick={() => setActiveTabId(tab.id)}
                      className="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                      key={tab.id}
                    >
                      {tab.name}
                    </ButtonLink>
                  );
                }
              })}
            </nav>
          </div>
        </div>
      </div>
      <div>{activeTab.render()}</div>
    </div>
  );
};
