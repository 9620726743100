import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormSubmitButton } from '../FormElements/Buttons';
import { Form, FormRow } from '../FormElements/Form';
import { FormHeading } from '../FormElements/FormHeading';
import { LabelledTextField } from '../FormElements/TextInput';
import * as Yup from 'yup';
import { apiUrl } from '../../App';
import { FormState } from '../Types';
import { RouteComponentProps } from 'react-router-dom';

interface PasswordResetProps extends RouteComponentProps<{ token: string }> {}

export const PasswordResetPage: FC<PasswordResetProps> = (props) => {
  const token = props.match.params.token;
  return (
    <>
      <div className="h-screen bg-white overflow-hidden flex">
        <div className="flex-1 max-w-4xl mx-auto w-0 flex flex-col md:px-8 xl:px-0">
          <main className="flex-1 relative overflow-y-auto focus:outline-none" tabIndex={0}>
            <div className="py-6">
              <div className="px-4 sm:px-6 md:px-0">
                <PasswordReset token={token} />
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

type PasswordResetInput = {
  password: string;
  password_repeated: string;
};

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Das Passwort muss mindestens ${min} Zeichen lang sein.')
    .max(63, 'Das Passwort kann maximal ${max} Zeichen lang sein')
    .required('Dieses Feld kann nicht leer bleiben.'),
  password_repeated: Yup.string()
    .oneOf([Yup.ref('password')], 'Die Passwörter stimmen nicht überein.')
    .required('Dieses Feld kann nicht leer bleiben.'),
});

type Props = {
  token: string;
};

const PasswordReset: FC<Props> = (props) => {
  const token = props.token;
  const [formMessage, setFormMessage] = useState('');
  const [formState, setFormState] = useState(FormState.Idle);
  const { register, handleSubmit, errors } = useForm<PasswordResetInput>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: PasswordResetInput) => {
    if (formState === FormState.Submitting) {
      return;
    }
    setFormState(FormState.Submitting);
    const senddata = {
      password: data.password,
    };
    axios({
      method: 'put',
      url: apiUrl + `/api/users/reset_password/${token}`,
      data: senddata,
      withCredentials: true,
    })
      .then((res) => {
        if (!res.data.successful) {
          setFormState(FormState.Failure);
          if (res.data.message) setFormMessage(res.data.message);
        } else {
          setFormState(FormState.Success);
          setFormMessage('Ihre Passwortänderung wurde gespeichert. Sie können sich nun einloggen.');
        }
      })
      .catch((e) => {
        setFormState(FormState.Failure);
        console.log('Error happened while submitting form', e);
      });
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormHeading formState={formState} formMessage={formMessage} heading="Passwort ändern" />
      <LabelledTextField
        errors={errors.password?.message}
        label="Neues Passwort"
        name="password"
        type="password"
        inputRef={register()}
      />
      <LabelledTextField
        errors={errors.password_repeated?.message}
        label="Neues Passwort wiederholen"
        name="password_repeated"
        type="password"
        inputRef={register()}
      />
      <FormRow>
        <FormSubmitButton
          isSubmitting={formState === FormState.Submitting}
          buttonLabel="Passwort ändern"
        />
      </FormRow>
    </Form>
  );
};
