/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type InvitationCodeCreateInput = {
    maximalAccounts: number;
    name?: string | null | undefined;
    role: string;
};
export type InvitationCodesCreateMutationVariables = {
    input: InvitationCodeCreateInput;
};
export type InvitationCodesCreateMutationResponse = {
    readonly invitationCodeCreate: {
        readonly result: {
            readonly id: string;
            readonly name: string | null;
            readonly isActive: boolean;
            readonly role: string;
            readonly invitationCode: string;
            readonly creator: {
                readonly id: string;
            } | null;
        } | null;
        readonly successful: boolean;
        readonly messages: ReadonlyArray<{
            readonly code: string;
            readonly field: string | null;
            readonly message: string | null;
        } | null> | null;
    } | null;
};
export type InvitationCodesCreateMutation = {
    readonly response: InvitationCodesCreateMutationResponse;
    readonly variables: InvitationCodesCreateMutationVariables;
};



/*
mutation InvitationCodesCreateMutation(
  $input: InvitationCodeCreateInput!
) {
  invitationCodeCreate(input: $input) {
    result {
      id
      name
      isActive
      role
      invitationCode
      creator {
        id
      }
    }
    successful
    messages {
      code
      field
      message
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "InvitationCodeCreatePayload",
    "kind": "LinkedField",
    "name": "invitationCodeCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "InvitationCode",
        "kind": "LinkedField",
        "name": "result",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isActive",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "invitationCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PublicUser",
            "kind": "LinkedField",
            "name": "creator",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "successful",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationMessage",
        "kind": "LinkedField",
        "name": "messages",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InvitationCodesCreateMutation",
    "selections": (v2/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InvitationCodesCreateMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "0c6842b6e771469996423f851181b464",
    "id": null,
    "metadata": {},
    "name": "InvitationCodesCreateMutation",
    "operationKind": "mutation",
    "text": "mutation InvitationCodesCreateMutation(\n  $input: InvitationCodeCreateInput!\n) {\n  invitationCodeCreate(input: $input) {\n    result {\n      id\n      name\n      isActive\n      role\n      invitationCode\n      creator {\n        id\n      }\n    }\n    successful\n    messages {\n      code\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '4e0ad787b9135c6cdbd6fa72b0319c74';
export default node;
