import { FC, useContext, useState } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import {
  AbsintheValidationMessages,
  asyncValidateFormValues,
} from '../FormElements/ValidationRules';
import {
  VideoDeleteInput,
  VideoDeleteMutation,
  VideoDeleteMutationResponse,
} from '../../__generated__/VideoDeleteMutation.graphql';
import { useMutation, useRelayEnvironment } from 'react-relay/hooks';
import { ConnectionHandler, PayloadError } from 'relay-runtime';
import { Button } from '../FormElements/Buttons';
import { useHistory } from 'react-router-dom';
import { FormState } from '../Types';
import { AppContext } from '../../App';

type VideoDeleteProps = {
  videoId: string;
  videoDeleted: () => void;
  setError: (err: string) => void;
  setGlobalFormError: (err: string) => void;
  className: string;
  formState: FormState;
  setFormState: (fs: FormState) => void;
};

export const VideoDelete: FC<VideoDeleteProps> = (props) => {
  // const environment = useRelayEnvironment().getStore();

  const [submittingState, setSubmittingState] = useState('idle');

  const { formState, setFormState, videoId, setError, setGlobalFormError } = props;

  const [commit] = useMutation<VideoDeleteMutation>(graphql`
    mutation VideoDeleteMutation($input: VideoDeleteInput!) {
      videoDelete(input: $input) {
        result {
          idDeleted
        }
        successful
        messages {
          code
          field
          message
        }
      }
    }
  `);

  const { userId } = useContext(AppContext);

  const deleteVideo = () => {
    if (formState === FormState.Submitting) {
      return;
    }
    setFormState(FormState.Submitting);
    // eslint-disable-next-line no-restricted-globals
    const r = confirm(
      'Wollen Sie das Video wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.'
    );
    if (!r) {
      setFormState(FormState.Idle);
      return;
    }
    commit({
      variables: { input: { id: videoId } },
      onCompleted: (response: VideoDeleteMutationResponse, errors: PayloadError[] | null) => {
        const ret = response.videoDelete;
        if (ret && !ret.successful && ret.messages) {
          const formValidationMessages = ret.messages as AbsintheValidationMessages<VideoDeleteInput>;
          asyncValidateFormValues(formValidationMessages, setError, setGlobalFormError);
          setFormState(FormState.Failure);
        } else {
          setFormState(FormState.Success);
          props.videoDeleted();
        }
      },
      updater: (store) => {
        const parentRecord = store.get(userId);
        const connection =
          parentRecord && ConnectionHandler.getConnection(parentRecord, 'VideoList_videos');
        connection && ConnectionHandler.deleteNode(connection, videoId);
      },
    });
  };

  const classes = props.className + ' hover:bg-red-500';

  return (
    <Button type={'button'} className={classes} onClick={deleteVideo}>
      Video löschen
    </Button>
  );
};
