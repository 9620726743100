import React, { FC, useState, Suspense, useContext } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay/hooks';
import { RouteComponentProps } from 'react-router-dom';
import { VideoViewQuery, VideoState } from '../../__generated__/VideoViewQuery.graphql';
import { PageDescription, PageHeading } from '../StructuringElements';
import { AppContext } from '../../App';
import { Button } from '../FormElements/Buttons';
import { VideoControls, VideoPlayer } from '../VideoPlayers/VideoPlayer';

// courseId is for now only needed to make the breadcrumbs work and have a link to the course ready
interface VideoViewProps extends RouteComponentProps<{ videoId: string }> {}

export const VideoView: FC<VideoViewProps> = (props) => {
  const videoId = props.match.params.videoId;
  const [videoControls, setVideoControls] = useState(null as VideoControls | null);

  const data = useLazyLoadQuery<VideoViewQuery>(
    graphql`
      query VideoViewQuery($id: ID!) {
        videoById(id: $id) {
          name
          description
          sourceType
          sourceData
          state
          stateMessage
        }
      }
    `,
    { id: videoId },
    { fetchPolicy: 'store-or-network' }
  );

  const video = data.videoById;

  if (!video) {
    return (
      <>
        <div className="flex flex-col space-y-10">
          <span>
            <PageHeading>Nicht gefunden</PageHeading>
            <PageDescription>Wir konnten das Video nicht finden</PageDescription>
          </span>
        </div>
      </>
    );
  }

  let videoElem = <></>;
  if (video.state == 'READY' && video.sourceData) {
    videoElem = (
      <VideoPlayer
        sourceData={video.sourceData}
        sourceType={video.sourceType}
        setVideoControls={setVideoControls}
      />
    );
  } else if (video.state == 'ERRORED') {
    videoElem = (
      <>Bei der Bereitstellung des Videos ist ein Fehler aufgetreten: "{video.stateMessage}"</>
    );
  } else if (video.state == 'INITIAL') {
    videoElem = <>Das Video wird noch hochgeladen.</>;
  } else if (video.state == 'CREATED') {
    videoElem = (
      <>
        Das Video wird momentan auf unseren Servern optimiert. Bitte warten Sie noch einige Minuten.
      </>
    );
  } else {
    videoElem = <>Hier ist etwas schief gelaufen.</>;
  }

  return (
    <>
      <div className="flex flex-col space-y-10">
        <span>
          <PageHeading>{video.name}</PageHeading>
          <PageDescription>{video.description}</PageDescription>
        </span>
        <div>{videoElem}</div>
      </div>
    </>
  );
};
