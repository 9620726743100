type LoadingProps = {
  text?: string;
};

export const Loading = (props: LoadingProps) => {
  if (props.text) {
    return <>{props.text}</>;
  } else {
    return <>{'Loading...'}</>;
  }
};
