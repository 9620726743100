import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormSubmitButton } from '../FormElements/Buttons';
import { Form, FormRow } from '../FormElements/Form';
import { FormHeading } from '../FormElements/FormHeading';
import { LabelledTextField } from '../FormElements/TextInput';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';
import { LabelledSwitchField } from '../FormElements/Switch';
import { FormState } from '../Types';
import { ButtonLink } from '../FormElements/Link';
import { useHistory } from 'react-router-dom';
import { apiUrl } from '../../App';

export const ForgotPasswordPage: FC = (props) => {
  return (
    <>
      <div className="h-screen bg-white overflow-hidden flex">
        <div className="flex-1 max-w-4xl mx-auto w-0 flex flex-col md:px-8 xl:px-0">
          <main className="flex-1 relative overflow-y-auto focus:outline-none" tabIndex={0}>
            <div className="py-6">
              <div className="px-4 sm:px-6 md:px-0">
                <ForgotPassword />
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Ungültige E-Mail Adresse.').required('Required'),
});

type ForgotPasswordInput = {
  email: string;
};

// interface ForgotPasswordProps {};

export const ForgotPassword: FC<{}> = () => {
  const [formMessage, setFormMessage] = useState('');
  const [formState, setFormState] = useState(FormState.Idle);
  const { register, handleSubmit, errors, control } = useForm<ForgotPasswordInput>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: ForgotPasswordInput) => {
    if (formState === FormState.Submitting) {
      return;
    }
    setFormState(FormState.Submitting);
    axios({
      method: 'post',
      url: apiUrl + '/api/users/reset_password',
      data: data,
      withCredentials: true,
    })
      .then((res) => {
        if (!res.data.successful) {
          setFormState(FormState.Failure);
          if (res.data.message) setFormMessage(res.data.message);
        } else {
          setFormState(FormState.Success);
          setFormMessage(
            'Falls wir die angegebene in unseren Systemen gespeichert haben, bekommen Sie bald eine E-Mail mit Anweisungen um Ihr Passwort zurückzusetzen.'
          );
        }
      })
      .catch((r) => {
        console.log('Error occured while submitting the form: ', r);
        setFormState(FormState.Failure);
        setFormMessage(r.response.data.message);
      });
  };
  //Somehow it is sometimes buggy without this console.log...
  // console.log(errors);
  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="pt-20">
      <FormHeading
        formState={formState}
        formMessage={formMessage}
        heading="Passwort zurücksetzen"
      />
      <LabelledTextField
        errors={errors.email?.message}
        label="E-Mail"
        name="email"
        inputRef={register({})}
      />
      <FormRow>
        <FormSubmitButton
          isSubmitting={formState === FormState.Submitting}
          buttonLabel="Zurücksetzen"
        />
      </FormRow>
    </Form>
  );
};
