import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery, useMutation } from 'react-relay/hooks';
import { RouteComponentProps } from 'react-router-dom';
import { ConnectionHandler, PayloadError } from 'relay-runtime';
import {
  AbsintheValidationMessages,
  asyncValidateFormValues,
  ValidationMessage,
} from '../FormElements/ValidationRules';
import { FormSubmitButton } from '../FormElements/Buttons';
import { Form, FormRow } from '../FormElements/Form';
import { FormHeading } from '../FormElements/FormHeading';
import { LabelledTextArea, LabelledTextField } from '../FormElements/TextInput';
import { VideoEditQuery } from '../../__generated__/VideoEditQuery.graphql';
import {
  VideoEditMutation,
  VideoEditMutationResponse,
  VideoUpdateInput,
} from '../../__generated__/VideoEditMutation.graphql';
import { FormState } from '../Types';
import { LabelledListBoxField } from '../FormElements/ListBox';
import {
  CourseUserEditMutation,
  CourseUserEditMutationResponse,
  CourseUserUpdateInput,
} from '../../__generated__/CourseUserEditMutation.graphql';

interface CourseUserEdit
  extends RouteComponentProps<{ courseId: string; userId: string; role: string }> {}

export const CourseUserEdit: FC<CourseUserEdit> = (props) => {
  const [formState, setFormState] = useState(FormState.Idle);
  const [formMessage, setFormMessage] = useState('');

  const { courseId, userId, role } = props.match.params;
  const [commit] = useMutation<CourseUserEditMutation>(
    graphql`
      mutation CourseUserEditMutation($input: CourseUserUpdateInput!) {
        courseUserUpdate(input: $input) {
          successful
          result {
            role
          }
          messages {
            message
          }
        }
      }
    `
  );

  const { register, handleSubmit, setError, errors, control } = useForm<CourseUserUpdateInput>({
    defaultValues: props.match.params,
  });

  const onSubmit = (formvalues: CourseUserUpdateInput) => {
    if (formState === FormState.Submitting) {
      return;
    }
    setFormState(FormState.Submitting);
    commit({
      variables: { input: formvalues },
      onCompleted: (response: CourseUserEditMutationResponse, errors: PayloadError[] | null) => {
        const ret = response.courseUserUpdate;
        if (ret && !ret.successful && ret.messages) {
          const formValidationMessages = ret.messages as AbsintheValidationMessages<CourseUserUpdateInput>;
          asyncValidateFormValues(formValidationMessages, setError, setFormMessage);
          setFormState(FormState.Failure);
        } else {
          setFormState(FormState.Success);
          setFormMessage('Die Änderungen wurden gespeichert');
        }
      },
      updater: (store) => {
        const role = store
          .getRootField('courseUserUpdate')
          .getLinkedRecord('result')
          .getValue('role');
        const course = store.get(courseId);
        const edges =
          course &&
          ConnectionHandler.getConnection(course, 'CourseEdit_courseUsers')?.getLinkedRecords(
            'edges'
          );
        if (edges) {
          edges.map((edge) => {
            if (edge.getLinkedRecord('node')?.getDataID() === userId) {
              edge.setValue(role, 'role');
            }
          });
        }
      },
    });
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormHeading
          formMessage={formMessage}
          heading="Kursmitgliedschaft bearbeiten"
          formState={formState}
        />
        <input name="course_id" type="hidden" ref={register()} value={courseId} />
        <input name="user_id" type="hidden" ref={register()} value={userId} />
        <LabelledListBoxField
          errors={errors.role?.message}
          name="role"
          control={control}
          options={[
            { value: 'admin', label: 'Gruppenadministrator' },
            { value: 'participant', label: 'Mitglied' },
          ]}
          defaultValue="participant"
          label="Gruppenrolle"
          description="Gruppenadministratoren können Videos erstellen, bearbeiten und die Kommentare der Mitglieder einsehen."
        />
        <FormRow className="flex space-between content-between">
          {/* <VideoDelete videoId={id} courseId={courseId} setError={setError as any} setGlobalFormError={setGlobalFormError} /> */}
          <div className="ml-auto">
            <FormSubmitButton
              isSubmitting={formState === FormState.Submitting}
              buttonLabel="Speichern"
            />
          </div>
        </FormRow>
      </Form>
    </>
  );
};
