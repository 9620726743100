/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CourseVideoRemoveInput = {
    id: string;
};
export type CourseVideoDeleteMutationVariables = {
    input: CourseVideoRemoveInput;
};
export type CourseVideoDeleteMutationResponse = {
    readonly courseVideoRemove: {
        readonly result: string | null;
        readonly successful: boolean;
        readonly messages: ReadonlyArray<{
            readonly code: string;
            readonly field: string | null;
            readonly message: string | null;
        } | null> | null;
    } | null;
};
export type CourseVideoDeleteMutation = {
    readonly response: CourseVideoDeleteMutationResponse;
    readonly variables: CourseVideoDeleteMutationVariables;
};



/*
mutation CourseVideoDeleteMutation(
  $input: CourseVideoRemoveInput!
) {
  courseVideoRemove(input: $input) {
    result
    successful
    messages {
      code
      field
      message
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CourseVideoRemovePayload",
    "kind": "LinkedField",
    "name": "courseVideoRemove",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "successful",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationMessage",
        "kind": "LinkedField",
        "name": "messages",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CourseVideoDeleteMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CourseVideoDeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fdcc534b2d5ec412ac5d0322b665ecda",
    "id": null,
    "metadata": {},
    "name": "CourseVideoDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation CourseVideoDeleteMutation(\n  $input: CourseVideoRemoveInput!\n) {\n  courseVideoRemove(input: $input) {\n    result\n    successful\n    messages {\n      code\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '173ca89c396e78697c705a07415c6539';
export default node;
