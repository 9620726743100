export enum MutationState {
  Idle = 'Idle',
  Processing = 'Processing',
  Success = 'Success',
  Failure = 'Failure',
}

export enum FormState {
  Submitting = 'SUBMITTING',
  Idle = 'IDLE',
  Success = 'SUCCESS',
  Failure = 'FAILURE',
}

export type Conversation = {
  readonly id: string;
  readonly timeInSeconds: number;
  readonly conversationMessages: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly text: string | null;
        readonly source: string | null;
        readonly user: {
          readonly name: string;
          readonly id: string;
        };
      };
    }>;
  };
};
