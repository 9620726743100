import { MicrophoneIcon } from '@heroicons/react/solid';
import React, { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import * as Recorder from 'opus-recorder';
import { graphql } from 'babel-plugin-relay/macro';

/* eslint import/no-webpack-loader-syntax: off */
// @ts-ignore
import encoderPath from '!!file-loader!opus-recorder/dist/encoderWorker.min.js';
import { useFragment } from 'react-relay/hooks';
import axios from 'axios';
import { AppContext } from '../../App';
import { Spinner } from '../Spinner';
import { VoiceMessageComposer_UploadVoiceCommentForm$key } from '../../__generated__/VoiceMessageComposer_UploadVoiceCommentForm.graphql';
import { VideoControls } from '../VideoPlayers/VideoPlayer';
import { VoiceMessageComposerConversation_UploadVoiceCommentForm$key } from '../../__generated__/VoiceMessageComposerConversation_UploadVoiceCommentForm.graphql';

type MessageCreateProps = {
  lineHeight: number;
  newVoiceMessage: (messageUrl: string) => void;
  conversation: VoiceMessageComposerConversation_UploadVoiceCommentForm$key;
};

enum Action {
  UserInitiatesRecording = 'USER INITIATES RECORDING',
  RecordingStarted = 'RECORDING STARTED',
  UserStopsRecording = 'USER STOPS RECORDING',
  RecordingStopped = 'RECORDING STOPPED',
  DataAvailable = 'DATA AVAILABLE',
}
const recorder = new Recorder({ encoderPath });

export const VoiceMessageComposerConversation = (props: MessageCreateProps) => {
  const userId = useContext(AppContext).userId;
  useEffect(() => {
    if (!Recorder.isRecordingSupported()) {
      alert(
        'Ihr Browser unterstützt keine Spracheingabe. Wenn Sie die Spracheingabefunktion nutzen wollen, installieren Sie bitte eine aktuelle Version eines Webbrowsers, zum Beispiel Firefox.'
      );
    }
  }, []);
  const { lineHeight, newVoiceMessage, conversation } = props;

  const data = useFragment<VoiceMessageComposerConversation_UploadVoiceCommentForm$key>(
    graphql`
      fragment VoiceMessageComposerConversation_UploadVoiceCommentForm on Conversation {
        temporaryUploadForm {
          base64
        }
      }
    `,
    conversation
  );

  const [userInitiatedRecording, setUserInitiatedRecording] = useState(false);
  const [userStoppedRecording, setUserStoppedRecording] = useState(false);
  const [recorderStarted, setRecorderStarted] = useState(false);
  const [recorderStopped, setRecorderStopped] = useState(false);
  const [dataReceived, setDataReceived] = useState(false);
  const [saveData, setSaveData] = useState(true);
  const [isUploading, setIsUploading] = useState(false);

  const setIdleState = () => {
    setUserInitiatedRecording(false);
    setUserStoppedRecording(false);
    setRecorderStarted(false);
    setRecorderStopped(false);
    setDataReceived(false);
    setSaveData(true);
    setIsUploading(false);
  };

  const applyAction = (a: Action) => {
    // console.log("Applying action ", a);
    if (a === Action.UserInitiatesRecording) {
      if (!userInitiatedRecording) {
        setUserInitiatedRecording(true);
        recorder.start().then(
          () => {
            applyAction(Action.RecordingStarted);
          },
          (e: any) => {
            console.log('Error while starting recording ', e);
          }
        );
      }
    } else if (a === Action.RecordingStarted) {
      if (userStoppedRecording) {
        setSaveData(false);
        setRecorderStarted(true);
        //just in case this was not called by action.userstopsrecording
        recorder.stop().then(
          () => {
            applyAction(Action.RecordingStopped);
          },
          (e: any) => {
            console.log('Error while stopping recording ', e);
          }
        );
      } else {
        setRecorderStarted(true);
      }
    } else if (a === Action.UserStopsRecording) {
      if (userInitiatedRecording) {
        setUserStoppedRecording(true);
        setTimeout(() => {
          recorder.stop().then(() => {
            applyAction(Action.RecordingStopped);
          });
        }, 350);
      }
    } else if (a === Action.RecordingStopped) {
      setIdleState();
    }
  };

  const stopRecording = (e: MouseEvent) => {
    applyAction(Action.UserStopsRecording);
  };

  const buttonDefaultSize = lineHeight;

  useEffect(() => {
    document.addEventListener('mouseup', stopRecording);

    return function cleanup() {
      document.removeEventListener('mouseup', stopRecording);
    };
  });

  if (!data.temporaryUploadForm) {
    console.log(data);
    return <>Keine Erlaubnis zum hochladen bekommen.</>;
  }
  async function uploadVoiceMessage(blob: any) {
    let form = JSON.parse(atob(data.temporaryUploadForm?.base64 as any));

    const formData = new FormData();
    //actually not important, just need the filename to be unique
    //userId_datetime_randomnumber.ogg
    const fileName = `${userId}_${Date.now()}_${Math.floor(Math.random() * 9999999)}.ogg`;
    const file = new File([blob], fileName);
    // formData.append("ke1y", fileName);
    for (const formfield in form['form_body']) {
      formData.append(formfield, form['form_body'][formfield]);
    }
    formData.append('file', file);

    await axios({
      method: 'post',
      url: form.form_action,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });
    return fileName;
  }

  async function dataAvailable(typedArray: any) {
    setIsUploading(true);
    const dataBlob = new Blob([typedArray], { type: 'audio/ogg' });
    const fileName = await uploadVoiceMessage(dataBlob);
    setIsUploading(false);
    newVoiceMessage(fileName);
  }

  recorder.ondataavailable = dataAvailable;

  const pulsating = {
    animate: { scale: [0.8, 1.6] },
    transition: { duration: 1, repeatType: 'reverse', repeat: Infinity },
  };
  const defaultButtonState = {
    animate: { scale: 1 },
    transition: { duration: 1 },
  };

  let recorderButtonState = {} as any;

  if (userInitiatedRecording) {
    if (userStoppedRecording) {
      recorderButtonState = defaultButtonState;
    } else {
      recorderButtonState = pulsating;
    }
  } else {
    recorderButtonState = defaultButtonState;
  }
  if (isUploading) {
    return <Spinner className="ml-4 h-6 w-6" />;
  } else {
    return (
      // <span className="absolute inset-y-0 flex items-center">
      <motion.div
        onMouseDown={() => applyAction(Action.UserInitiatesRecording)}
        id="voiceRecorderButton"
        animate={recorderButtonState.animate}
        transition={recorderButtonState.transition}
      >
        <button
          type="button"
          className="inline-flex items-center justify-center rounded-full h-12 w-12 transition duration-500 ease-in-out text-black hover:bg-green-400 focus:outline-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z"
            />
          </svg>
        </button>
      </motion.div>

      // </span>
    );
  }
};

{
  /* <span className="absolute inset-y-0 flex items-center">
<button type="button" className="inline-flex items-center justify-center rounded-full h-12 w-12 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none">
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6 text-gray-600">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
  </svg>
</button>
</span> */
}
