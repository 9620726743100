/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type VideoState = "CREATED" | "ERRORED" | "INITIAL" | "READY" | "%future added value";
export type VideoViewQueryVariables = {
    id: string;
};
export type VideoViewQueryResponse = {
    readonly videoById: {
        readonly name: string;
        readonly description: string | null;
        readonly sourceType: string;
        readonly sourceData: string | null;
        readonly state: VideoState;
        readonly stateMessage: string | null;
    } | null;
};
export type VideoViewQuery = {
    readonly response: VideoViewQueryResponse;
    readonly variables: VideoViewQueryVariables;
};



/*
query VideoViewQuery(
  $id: ID!
) {
  videoById(id: $id) {
    name
    description
    sourceType
    sourceData
    state
    stateMessage
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sourceType",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sourceData",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stateMessage",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VideoViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Video",
        "kind": "LinkedField",
        "name": "videoById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VideoViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Video",
        "kind": "LinkedField",
        "name": "videoById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "71de21b96e431477edd986349c9c7fe5",
    "id": null,
    "metadata": {},
    "name": "VideoViewQuery",
    "operationKind": "query",
    "text": "query VideoViewQuery(\n  $id: ID!\n) {\n  videoById(id: $id) {\n    name\n    description\n    sourceType\n    sourceData\n    state\n    stateMessage\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '69ddaab9b42734886b5afb7b74f2e1dd';
export default node;
