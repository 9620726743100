/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type VideoState = "CREATED" | "ERRORED" | "INITIAL" | "READY" | "%future added value";
export type VideoCreateMuxInput = {
    description?: string | null | undefined;
    name: string;
    public: boolean;
};
export type VideoCreateMuxMutationVariables = {
    input: VideoCreateMuxInput;
};
export type VideoCreateMuxMutationResponse = {
    readonly videoCreateMux: {
        readonly result: {
            readonly video: {
                readonly id: string;
                readonly name: string;
                readonly description: string | null;
                readonly state: VideoState;
            };
            readonly uploadUrl: string;
        } | null;
        readonly successful: boolean;
        readonly messages: ReadonlyArray<{
            readonly code: string;
            readonly field: string | null;
            readonly message: string | null;
        } | null> | null;
    } | null;
};
export type VideoCreateMuxMutation = {
    readonly response: VideoCreateMuxMutationResponse;
    readonly variables: VideoCreateMuxMutationVariables;
};



/*
mutation VideoCreateMuxMutation(
  $input: VideoCreateMuxInput!
) {
  videoCreateMux(input: $input) {
    result {
      video {
        id
        name
        description
        state
      }
      uploadUrl
    }
    successful
    messages {
      code
      field
      message
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "VideoCreateMuxPayload",
    "kind": "LinkedField",
    "name": "videoCreateMux",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VideoCreateMuxResult",
        "kind": "LinkedField",
        "name": "result",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Video",
            "kind": "LinkedField",
            "name": "video",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uploadUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "successful",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationMessage",
        "kind": "LinkedField",
        "name": "messages",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VideoCreateMuxMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VideoCreateMuxMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3f74d89577b89ba907b4d4b36f2e2ee4",
    "id": null,
    "metadata": {},
    "name": "VideoCreateMuxMutation",
    "operationKind": "mutation",
    "text": "mutation VideoCreateMuxMutation(\n  $input: VideoCreateMuxInput!\n) {\n  videoCreateMux(input: $input) {\n    result {\n      video {\n        id\n        name\n        description\n        state\n      }\n      uploadUrl\n    }\n    successful\n    messages {\n      code\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '79f4c447f01a0b8219e13c732fafce08';
export default node;
