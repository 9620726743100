type SpinnerProps = {
  className?: string;
};

export const Spinner = (props: SpinnerProps) => {
  let extraClasses = props.className ? props.className : '';
  let className =
    extraClasses +
    ' loader inline-block ease-linear rounded-full border-2 border-t-2 border-gray-200';
  return <div className={className}></div>;
};
