import React, { FunctionComponent, Suspense, useContext } from 'react';
import { useLazyLoadQuery } from 'react-relay/hooks';
import { Route, Switch, useLocation } from 'react-router-dom';
import { MainPage_meQuery } from '../../__generated__/MainPage_meQuery.graphql';
import { StaticSideBar } from '../Navigation/StaticSidebar';
import { SearchBarHeader } from '../SearchBarHeader';
import { CourseEditPage, CoursePage } from './CoursePage';
import { InvitationCodeEdit } from './InvitationCodeEdit';
import { InvitationCodesPage } from './InvitationCodes';
import { graphql } from 'babel-plugin-relay/macro';
import { VideoEdit } from '../CourseVideos/VideoEdit';
import { CourseVideoView } from '../CourseVideos/CourseVideoView';
import { CourseEdit } from '../Courses/CourseEdit';
import { CourseView } from '../Courses/CourseView';
import { CourseVideoAdd } from '../CourseVideos/CourseVideoAdd';
import { CourseJoin } from '../Courses/CourseJoin';
import { UserSettingsPage } from '../UserSettings/UserSettingsPage';
import { ConfirmEmail } from '../UserSettings/ConfirmEmail';
import { ConfirmUser } from '../UserSettings/ConfirmUser';
import { NotFoundPage } from './NotFoundPage';
import { HomePage } from './HomePage';
import { CourseUserEdit } from '../Courses/CourseUserEdit';
import { AppContext } from '../../App';
import { VideoList } from '../Videos/VideoList';
import { VideoView } from '../Videos/VideoView';
import { VideoCreate } from '../Videos/VideoCreate';
import { CourseVideoEdit } from '../CourseVideos/CourseVideoEdit';
import { DisplayConversation } from '../Conversations/DisplayConversation';
import { UserConversationList } from '../Conversations/UserConversationList';

export const UserContext = React.createContext({
  role: 'participant',
  email: '',
  name: '',
  publicUserId: '',
});

// export const WidthContext = React.createContext({
//   width: null as null | number,
// });

export const ViewMode = React.createContext({
  mode: 'default' as 'default' | 'theater',
});

export const MainPage: FunctionComponent = () => {
  const data = useLazyLoadQuery<MainPage_meQuery>(
    graphql`
      query MainPage_meQuery {
        me {
          id
          name
          email
          role
          publicUser {
            id
          }
        }
      }
    `,
    {},
    { fetchPolicy: 'store-or-network' }
  );

  let divClasses = 'max-w-4xl';
  const isInTheatreMode = useContext(AppContext).isInTheatreMode;
  const path = useLocation().pathname;
  //if we are vewing a vieo and theatre mode is set, set the classes such that theatre mode is used
  if (/^\/course_videos\//.test(path) && isInTheatreMode) {
    divClasses = 'px-5';
  }
  return (
    <>
      <UserContext.Provider
        value={{
          role: data.me.role,
          email: data.me.email,
          name: data.me.name,
          publicUserId: data.me.publicUser.id,
        }}
      >
        <div className="h-screen bg-white overflow-hidden flex">
          {/* <OffCanvasMenu /> */}
          <StaticSideBar />
          <div className={'flex-1 mx-auto w-0 flex flex-col ' + divClasses}>
            <SearchBarHeader username={data.me.name} />
            <main
              className="flex flex-col flex-1 overflow-y-auto relative focus:outline-none"
              tabIndex={0}
            >
              {/* <div className="pt-6">
              <div className="px-4 sm:px-6 md:px-0"> */}
              <Suspense fallback="Lädt...">
                <Switch>
                  <Route
                    path="/invitation_codes"
                    render={(props) => <InvitationCodesPage {...props} />}
                    exact
                  />
                  <Route path="/invitation_codes/edit/:id" component={InvitationCodeEdit} exact />

                  <Route path="/courses" render={(props) => <CoursePage {...props} />} exact />
                  <Route path="/courses/:id" component={CourseView} exact />
                  <Route path="/courses/join/:accessCode" component={CourseJoin} exact />

                  <Route
                    path="/courses_edit"
                    render={(props) => <CourseEditPage {...props} />}
                    exact
                  />
                  <Route path="/courses_edit/:id" component={CourseEdit} exact />
                  <Route
                    path="/courses_edit/video_add/:courseId"
                    component={CourseVideoAdd}
                    exact
                  />
                  <Route
                    path="/courses_edit/video_edit/:courseId/:videoId"
                    component={CourseVideoEdit}
                    exact
                  />
                  <Route
                    path="/courses_edit/user_edit/:courseId/:userId/:role"
                    component={CourseUserEdit}
                    exact
                  />
                  <Route path="/confirm_email/:token" component={ConfirmEmail} exact />
                  <Route path="/confirm_user/:token" component={ConfirmUser} exact />
                  <Route
                    path="/course_videos/:courseId/:courseVideoId"
                    component={CourseVideoView}
                    exact
                  />

                  <Route path="/settings" component={UserSettingsPage} exact />

                  <Route path="/videos" component={VideoList} exact />
                  <Route path="/videos/view/:videoId" component={VideoView} exact />
                  <Route path="/videos/create" component={VideoCreate} exact />
                  <Route path="/videos/edit/:videoId" component={VideoEdit} exact />

                  <Route path="/conversations" component={UserConversationList} exact />
                  <Route path="/conversations/:id" component={DisplayConversation} />

                  <Route path="/" component={HomePage} exact />

                  <NotFoundPage />
                </Switch>
              </Suspense>
              {/* </div>
            </div> */}
            </main>
          </div>
        </div>
      </UserContext.Provider>
    </>
  );
};
