import { PaperAirplaneIcon } from '@heroicons/react/outline';
import { ReactNode, useContext, useRef } from 'react';
import { ConnectionHandler, useMutation } from 'react-relay';
import { MessageComposerMutation } from '../../__generated__/MessageComposerMutation.graphql';
import { Button } from '../FormElements/Buttons';
import { TextField } from '../FormElements/TextInput';
import { graphql } from 'babel-plugin-relay/macro';
import { AppContext } from '../../App';
import { UserContext } from '../Pages/MainPage';
import { VoiceMessageComposerConversation } from './VoiceMessageComposerConversation';
import { VoiceMessageComposerConversation_UploadVoiceCommentForm$key } from '../../__generated__/VoiceMessageComposerConversation_UploadVoiceCommentForm.graphql';

type MessageComposerProps = {
  conversationId: string;
  scrollToBottom: () => void;
  conversation: VoiceMessageComposerConversation_UploadVoiceCommentForm$key;
  // newVoiceMessage: (filename: string, time: number)=>void,
  // newTextMessage: (text: string, time: number)=>void,
};

enum NewMessageType {
  Text,
  Audio,
}

export const MessageComposer = (props: MessageComposerProps) => {
  const { conversationId, scrollToBottom, conversation } = props;
  const { userId } = useContext(AppContext);
  const { name } = useContext(UserContext);
  const [commit] = useMutation<MessageComposerMutation>(
    graphql`
      mutation MessageComposerMutation($input: ConversationMessageCreateInput!) {
        conversationMessageCreate(input: $input) {
          result {
            text
            source
            user {
              id
              name
            }
          }
          successful
          messages {
            code
            field
            message
          }
        }
      }
    `
  );

  const newMessage = (type: NewMessageType, content: string) => {
    let input = {
      conversationId: conversationId,
    } as {
      conversationId: string;
      filename?: string;
      text?: string;
    };
    switch (type) {
      case NewMessageType.Audio:
        input.filename = content;
        break;
      case NewMessageType.Text:
        input.text = content;
        break;
    }

    commit({
      variables: { input: input },
      // IF I want to use an updater here I need to always check if the message already exists here and in the subscription
      // since there will then be two ways to get a message printed (otherwise its printed twice for the sender)
      // updater: (store) => {
      //   const newEntry = store.getRootField('conversationMessageCreate').getLinkedRecord('result');
      //   const parentRecord = store.get(conversationId);
      //   const connection = parentRecord && ConnectionHandler.getConnection(parentRecord, 'DisplayConversation_conversationMessages');
      //   const edge =
      //     parentRecord &&
      //     connection &&
      //     ConnectionHandler.createEdge(store, connection, newEntry, "ConversationMessagesEdge");
      //   if (connection && edge) {
      //     ConnectionHandler.insertEdgeAfter(connection, edge);
      //   }
      // },
      onCompleted: (response, errors) => {
        console.log('Message creation returned with ', response, errors);
      },
      // optimisticResponse: {
      //     conversationMessageCreate: {
      //         result: {
      //             id: "optimisticGuess",
      //             source: input.filename ? input.filename : null,
      //             text: input.text ? input.text : null,
      //             user: {
      //                 id: userId,
      //                 name: name
      //             }
      //         },
      //         successful: true,
      //         messages: []
      //     }
      // }
    });
  };

  const voiceMessageComposer = (
    <VoiceMessageComposerConversation
      lineHeight={15}
      newVoiceMessage={(m: string) => newMessage(NewMessageType.Audio, m)}
      conversation={conversation}
    />
  );

  return (
    <div className="flex gap-3 content-evenly">
      <TextMessageComposer
        newTextMessage={(m: string) => newMessage(NewMessageType.Text, m)}
        voiceMessageComposer={voiceMessageComposer}
      />
    </div>
  );
};

type TextMessageComposerNewConversationProps = {
  newTextMessage: (message: string) => void;
  voiceMessageComposer: ReactNode;
};

const TextMessageComposer = (props: TextMessageComposerNewConversationProps) => {
  const textBoxRef = useRef<HTMLTextAreaElement>(null);
  const sendMessage = () => {
    if (!textBoxRef.current) return;
    const text = textBoxRef.current.value;
    textBoxRef.current.value = '';
    props.newTextMessage(text);
  };

  function keyPress(e: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (e.key === 'Enter') {
      if (!e.shiftKey) {
        e.preventDefault();
        sendMessage();
      }
    }
  }

  return (
    <div className="w-full">
      <div className="relative flex px-1">
        <textarea
          rows={1}
          ref={textBoxRef}
          onKeyDown={keyPress}
          placeholder="Antworten"
          className="focus:ring-black focus:ring-opacity-20 rounded-md border-0 w-full text-gray-600 pl-4 pr-24 bg-gray-200 py-3"
        />
        <div className="absolute right-0 items-center inset-y-0 hidden sm:flex">
          {/* <button type="button" className="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6 text-gray-600">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
              </svg>
            </button>
            <button type="button" className="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6 text-gray-600">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
            </button> */}
          {/* <button type="button" className="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6 text-gray-600">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </button> */}
          {props.voiceMessageComposer}
          <button
            onClick={sendMessage}
            type="button"
            className="inline-flex items-center justify-center rounded-full h-12 w-12 transition duration-500 ease-in-out text-black hover:bg-green-400 focus:outline-none"
          >
            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-6 w-6 transform rotate-90">
                <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
              </svg> */}
            <svg
              transform="rotate(45)"
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};
