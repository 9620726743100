/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DisplayConversationQueryVariables = {
    id: string;
};
export type DisplayConversationQueryResponse = {
    readonly conversationById: {
        readonly id: string;
        readonly timeInSeconds: number;
        readonly courseVideo: {
            readonly name: string;
            readonly id: string;
            readonly course: {
                readonly id: string;
            };
        };
        readonly conversationMessages: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly " $fragmentRefs": FragmentRefs<"DisplayConversation_message">;
                };
            }>;
        } | null;
        readonly " $fragmentRefs": FragmentRefs<"VoiceMessageComposerConversation_UploadVoiceCommentForm">;
    } | null;
};
export type DisplayConversationQuery = {
    readonly response: DisplayConversationQueryResponse;
    readonly variables: DisplayConversationQueryVariables;
};



/*
query DisplayConversationQuery(
  $id: ID!
) {
  conversationById(id: $id) {
    id
    timeInSeconds
    courseVideo {
      name
      id
      course {
        id
      }
    }
    ...VoiceMessageComposerConversation_UploadVoiceCommentForm
    conversationMessages(first: 999) {
      edges {
        node {
          id
          ...DisplayConversation_message
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
}

fragment DisplayConversation_message on ConversationMessage {
  text
  source
  user {
    id
    name
  }
}

fragment VoiceMessageComposerConversation_UploadVoiceCommentForm on Conversation {
  temporaryUploadForm {
    base64
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeInSeconds",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "CourseVideo",
  "kind": "LinkedField",
  "name": "courseVideo",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Course",
      "kind": "LinkedField",
      "name": "course",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 999
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DisplayConversationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Conversation",
        "kind": "LinkedField",
        "name": "conversationById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/),
          {
            "alias": "conversationMessages",
            "args": null,
            "concreteType": "ConversationMessagesConnection",
            "kind": "LinkedField",
            "name": "__DisplayConversation_conversationMessages_connection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ConversationMessagesEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ConversationMessage",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v6/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "DisplayConversation_message"
                      }
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "VoiceMessageComposerConversation_UploadVoiceCommentForm"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DisplayConversationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Conversation",
        "kind": "LinkedField",
        "name": "conversationById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TemporaryUploadForm",
            "kind": "LinkedField",
            "name": "temporaryUploadForm",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "base64",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v9/*: any*/),
            "concreteType": "ConversationMessagesConnection",
            "kind": "LinkedField",
            "name": "conversationMessages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ConversationMessagesEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ConversationMessage",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "text",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "source",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PublicUser",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": "conversationMessages(first:999)"
          },
          {
            "alias": null,
            "args": (v9/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "DisplayConversation_conversationMessages",
            "kind": "LinkedHandle",
            "name": "conversationMessages"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "35467ce919ddafb3bb7a1dff5c0f16e7",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "conversationById",
            "conversationMessages"
          ]
        }
      ]
    },
    "name": "DisplayConversationQuery",
    "operationKind": "query",
    "text": "query DisplayConversationQuery(\n  $id: ID!\n) {\n  conversationById(id: $id) {\n    id\n    timeInSeconds\n    courseVideo {\n      name\n      id\n      course {\n        id\n      }\n    }\n    ...VoiceMessageComposerConversation_UploadVoiceCommentForm\n    conversationMessages(first: 999) {\n      edges {\n        node {\n          id\n          ...DisplayConversation_message\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n\nfragment DisplayConversation_message on ConversationMessage {\n  text\n  source\n  user {\n    id\n    name\n  }\n}\n\nfragment VoiceMessageComposerConversation_UploadVoiceCommentForm on Conversation {\n  temporaryUploadForm {\n    base64\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e57a16bdb7ea67f0723ccea79d9abed1';
export default node;
