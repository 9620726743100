/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type VideoState = "CREATED" | "ERRORED" | "INITIAL" | "READY" | "%future added value";
export type CourseVideoViewQueryVariables = {
    id: string;
};
export type CourseVideoViewQueryResponse = {
    readonly courseVideoById: {
        readonly id: string;
        readonly name: string;
        readonly description: string | null;
        readonly video: {
            readonly sourceType: string;
            readonly sourceData: string | null;
            readonly state: VideoState;
            readonly stateMessage: string | null;
        };
        readonly " $fragmentRefs": FragmentRefs<"VideoComments_VideoComments">;
    } | null;
};
export type CourseVideoViewQuery = {
    readonly response: CourseVideoViewQueryResponse;
    readonly variables: CourseVideoViewQueryVariables;
};



/*
query CourseVideoViewQuery(
  $id: ID!
) {
  courseVideoById(id: $id) {
    id
    name
    description
    ...VideoComments_VideoComments
    video {
      sourceType
      sourceData
      state
      stateMessage
      id
    }
  }
}

fragment VideoComments_VideoComments on CourseVideo {
  ...VoiceMessageComposer_UploadVoiceCommentForm
  courseVideoConversations(first: 9999) {
    edges {
      node {
        id
        timeInSeconds
        conversationMessages(first: 1) {
          edges {
            node {
              text
              source
              user {
                id
                name
              }
              id
            }
          }
        }
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment VoiceMessageComposer_UploadVoiceCommentForm on CourseVideo {
  temporaryUploadForm {
    base64
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sourceType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sourceData",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stateMessage",
  "storageKey": null
},
v9 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 9999
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CourseVideoViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CourseVideo",
        "kind": "LinkedField",
        "name": "courseVideoById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Video",
            "kind": "LinkedField",
            "name": "video",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "VideoComments_VideoComments"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CourseVideoViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CourseVideo",
        "kind": "LinkedField",
        "name": "courseVideoById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TemporaryUploadForm",
            "kind": "LinkedField",
            "name": "temporaryUploadForm",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "base64",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v9/*: any*/),
            "concreteType": "CourseVideoConversationsConnection",
            "kind": "LinkedField",
            "name": "courseVideoConversations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CourseVideoConversationsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Conversation",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "timeInSeconds",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "first",
                            "value": 1
                          }
                        ],
                        "concreteType": "ConversationMessagesConnection",
                        "kind": "LinkedField",
                        "name": "conversationMessages",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ConversationMessagesEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ConversationMessage",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "text",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "source",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PublicUser",
                                    "kind": "LinkedField",
                                    "name": "user",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      (v3/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "conversationMessages(first:1)"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "courseVideoConversations(first:9999)"
          },
          {
            "alias": null,
            "args": (v9/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "videoComments_courseVideoConversations",
            "kind": "LinkedHandle",
            "name": "courseVideoConversations"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Video",
            "kind": "LinkedField",
            "name": "video",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "94437bc655744f1667f537054643bac2",
    "id": null,
    "metadata": {},
    "name": "CourseVideoViewQuery",
    "operationKind": "query",
    "text": "query CourseVideoViewQuery(\n  $id: ID!\n) {\n  courseVideoById(id: $id) {\n    id\n    name\n    description\n    ...VideoComments_VideoComments\n    video {\n      sourceType\n      sourceData\n      state\n      stateMessage\n      id\n    }\n  }\n}\n\nfragment VideoComments_VideoComments on CourseVideo {\n  ...VoiceMessageComposer_UploadVoiceCommentForm\n  courseVideoConversations(first: 9999) {\n    edges {\n      node {\n        id\n        timeInSeconds\n        conversationMessages(first: 1) {\n          edges {\n            node {\n              text\n              source\n              user {\n                id\n                name\n              }\n              id\n            }\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment VoiceMessageComposer_UploadVoiceCommentForm on CourseVideo {\n  temporaryUploadForm {\n    base64\n  }\n}\n"
  }
};
})();
(node as any).hash = '27f95055a383ba2f4b44311a238df112';
export default node;
