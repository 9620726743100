import React, { FC, useState, useContext, useEffect } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay/hooks';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { PayloadError, ConnectionHandler } from 'relay-runtime';
import { AppContext } from '../../App';
import {
  CourseJoinCourseUserAddMutation,
  CourseJoinCourseUserAddMutationResponse,
} from '../../__generated__/CourseJoinCourseUserAddMutation.graphql';
import { AbsintheValidationMessages } from '../FormElements/ValidationRules';
import { Loading } from '../Loading';

interface CourseJoinProps extends RouteComponentProps<{ accessCode: string }> {}

export const CourseJoin: FC<CourseJoinProps> = (props) => {
  const [state, setState] = useState('loading');
  const [serverResponse, setServerResponse] = useState(null as string | null);
  const userId = useContext(AppContext).userId;

  const accessCode = props.match.params.accessCode;
  const [commit] = useMutation<CourseJoinCourseUserAddMutation>(graphql`
    mutation CourseJoinCourseUserAddMutation($input: CourseUserAddInput!) {
      courseUserAdd(input: $input) {
        result {
          name
          id
        }
        successful
        messages {
          code
          field
          message
        }
      }
    }
  `);

  useEffect(() => {
    commit({
      variables: { input: { accessCode: accessCode } },
      onCompleted: (
        response: CourseJoinCourseUserAddMutationResponse,
        errors: PayloadError[] | null
      ) => {
        const ret = response.courseUserAdd;
        if (ret && !ret.successful && ret.messages) {
          const formValidationMessages = ret.messages as AbsintheValidationMessages<{}>;
          let errors = '';
          formValidationMessages.forEach((message) => {
            errors += message.message + ' ';
          });
          setState('error');
          setServerResponse(errors);
        } else {
          setState('success');
        }
      },
      updater: (store) => {
        const newCourse = store.getRootField('courseUserAdd').getLinkedRecord('result');
        const me = store.get(userId);
        const myCourses = me && ConnectionHandler.getConnection(me, 'CoursePage_courses');
        const edge =
          myCourses &&
          me &&
          ConnectionHandler.createEdge(store, myCourses, newCourse, 'CoursesEdge');
        if (myCourses && edge) {
          edge.setValue(false, 'admin');
          ConnectionHandler.insertEdgeAfter(myCourses, edge);
        }
      },
    });
  }, []);

  if (state === 'loading') return <Loading text="Trete dem Kurs bei" />;

  if (state === 'success') return <Redirect to="/courses" />;

  //otherwise the state are the errors returned by the server:
  return <>{serverResponse}</>;
};
