import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { FormSubmitButton } from '../FormElements/Buttons';
import { Form, FormRow } from '../FormElements/Form';
import { FormHeading } from '../FormElements/FormHeading';
import { LabelledTextField } from '../FormElements/TextInput';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';
import { apiUrl } from '../../App';
import { FormState } from '../Types';
import { addChangesetErrorsToForm } from '../FormElements/ValidationRules';

export const OrganisationRegistrationPage: FC = (props) => {
  return (
    <>
      <div className="h-screen bg-white overflow-hidden flex">
        <div className="flex-1 max-w-4xl mx-auto w-0 flex flex-col md:px-8 xl:px-0">
          <main className="flex-1 relative overflow-y-auto focus:outline-none" tabIndex={0}>
            <div className="py-6">
              <div className="px-4 sm:px-6 md:px-0">
                <Register />
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(4, 'Dieses Feld muss mindestens 4 Zeichen lang sein.')
    .max(63, 'Dieses Feld kann maximal ${max} Zeichen lang sein'),
  email: Yup.string().email('Ungültige E-Mail Adresse.').required('Required'),
  registration_code: Yup.string().required('Required')
});

type RegisterInput = {
  name: string;
  email: string;
  registration_code: string;
};

interface RegisterProps {}

export const Register: FC<RegisterProps> = () => {
  const [globalFormError, setGlobalFormError] = useState('');
  const [formState, setFormState] = useState(FormState.Idle);
  const { register, handleSubmit, setError, errors, reset, control } = useForm<RegisterInput>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: RegisterInput) => {
    if (formState === FormState.Submitting) return;
    setFormState(FormState.Submitting);
    axios({
      method: 'post',
      url: apiUrl + '/api/organisations/register',
      data: data,
      withCredentials: true,
    })
      .then((res) => {
        if (!res.data.successful) {
          setGlobalFormError(res.data.message);
          setFormState(FormState.Failure);
        } else {
          setGlobalFormError("Sie haben erfolgreich eine Organisation erstellt. Bitte überprüfen Sie ihr E-Mail Postfach.")
          setFormState(FormState.Success);
        }
      })
      .catch((error) => {
        console.log('Got error', error);
        addChangesetErrorsToForm(error.response.data, setError as any, setGlobalFormError);
        setFormState(FormState.Failure);
      });
  };
  //Somehow it is sometimes buggy without this console.log...
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormHeading
        formState={formState}
        formMessage={globalFormError}
        heading="Registrierung"
        subCaption="Wir behandeln all Ihre Daten vertraulich und geben sie nicht an andere weiter."
      />
      <LabelledTextField
        errors={errors.name?.message}
        label="Organisationsname"
        description="z.B. Name Ihrer Schule oder Ihr eigener"
        name="name"
        placeholder="z.B. Felix-Klein-Schule"
        inputRef={register({
          // required: {
          //   value: true,
          //   message: ValidationMessage('required', true),
          // },
          // minLength: { value: 2, message: ValidationMessage('minLength', 2) },
          // maxLength: { value: 63, message: ValidationMessage('maxLength', 63) },
        })}
      />
      <LabelledTextField
        errors={errors.email?.message}
        label="E-Mail"
        name="email"
        description="Zum Zuschicken Ihrer Login-Informationen."
        placeholder="erika@mail.de"
        inputRef={register({
          // required: "Required",
          // pattern: {
          //   value: /^\S+@\S+\.\S+$/,
          //   message: "Ungültige E-Mail Adresse."
          // }
        })}
      />
      <LabelledTextField
        errors={errors.registration_code?.message}
        label="Registrationscode"
        name="registration_code"
        description="Falls Sie keinen haben, schreiben Sie uns eine Mail."
        type="password"
        placeholder=""
        inputRef={register()}
      />
      <FormRow>
        <FormSubmitButton
          isSubmitting={formState === FormState.Submitting}
          buttonLabel="Registrieren"
        />
      </FormRow>
    </Form>
  );
};
